import * as T from "../../utilities/frontendTypes";

export default function FrenchEnglishToggle({langState, onToggleCallback}: {langState: T.LangState, onToggleCallback: (langState: T.LangState) => void}) {

  return (
    <div className="relative inline">
      <button 
        className="py-1 px-2 border border-solid border-borders rounded-sm text-primary font-semibold"
        onClick={ () => {onToggleCallback(langState === "eng" ? "fr" : "eng")}}
      >
        {langState === "eng" ? "FR" : "ENG"}
      </button>
    </div>
  );
}