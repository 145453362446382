import * as T from "../utilities/frontendTypes";

export type ButtonProp = {
  buttonText: string,
  actionHandler: (obj?: any) => void, // 'any' here is intentional due to a button has no knowledge about the obj that it's passing back to parent
  buttonType?: ButtonType,
}
export type ButtonType = T.ActionTypes | ('primary-inverse' | 'secondary-inverse');

export default function Button(
  {
    buttonType,
    buttonText,
    actionHandler,
    disabled,
    size,
    style,
  }
  :
  {
    buttonText: string,
    actionHandler: () => void,
    buttonType?: ButtonType,
    disabled?: boolean,
    size?: "small" | "large",
    style?: string,
  }
) {

  const buttonTypeClassNames = () => {
    switch(buttonType) {
      case "primary":
        return `${disabled ? "bg-terteiry" : "bg-primary hover:bg-primary/80" } border-primary`;
      case "primary-inverse":
        return `bg-background border-primary text-primary ${disabled ? "" : "hover:bg-primary/10" }`;
      case "secondary":
        return `bg-secondary border-secondary text-secondaryButtonText ${disabled ? "" : "hover:bg-secondary/80" }`;
      case "secondary-inverse":
        return `bg-background border-secondary text-secondary ${disabled ? "" : "hover:bg-secondary/10" }`;
      case "tertiary":
        return `border-0 text-text shadow-none ${disabled ? "" : "hover:shadow-md" }`;
      case "danger":
        return `border-2 border-danger text-danger ${disabled ? buttonDisabledClassNames : "hover:bg-danger hover:text-white"}`;
      default:
        throw new Error("Unknown button type");
    }
  }

  const buttonDisabledClassNames = "cursor-not-allowed opacity-70";
  let sizeStyle = "p-1 px-3 text-md";
  if (size === "small") sizeStyle = "p-[1px] px-[8px]";
  if (size === "large") sizeStyle = "p-1 px-3 text-md w-full";

  return (
    <button
      type="button"
      disabled={disabled}
      className={
        `
          ${buttonTypeClassNames()}
          ${disabled && buttonDisabledClassNames}
          text-buttonText border
          font-semibold rounded-md ${sizeStyle} shadow-md transition-all whitespace-nowrap 
          ${style ? style : ""}
        `
      }
      onClick={ actionHandler }
    >
      {buttonText}
    </button>
  )
}

Button.defaultProps = {
  buttonType: "primary",
};