import { faCircleInfo, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import parseStringToHTML from "html-react-parser";
import { ToolTipPosition } from "../utilities/frontendTypes"

function Tooltip( 
  {
    content,
    type,
    position,
    customWidth
  }
  : 
  {
    content: string,
    type?: "lock",
    position?: ToolTipPosition
    customWidth?: string
  } 
) {

  const [showTooltip, setShowTooltip] = useState(false);

  const handleShowTooltip = () => {
    setShowTooltip(true);
  };

  const handleHideTooltip = () => {
    setShowTooltip(false);
  };

  const isLock = type && type === "lock";
  //Alternatively could just change tooltipposition type to these strings
  const positionStyle = (position: ToolTipPosition) => {
    switch (position) {
      case "top-left":
        return "bottom-[50%] right-[115%]";
      case "bottom-left":
        return "top-[50%] right-[115%]";
      case "top-right":
        return "bottom-[50%] left-[115%]";
      case "bottom-right":
        return "top-[50%] left-[115%]";
    }
  }
  return (
    <button
      tabIndex={0}
      className={`relative ml-3 ${isLock ? "flex items-center" : "inline-block"}`}
      onMouseEnter={handleShowTooltip}
      onMouseLeave={handleHideTooltip}
      onFocus={handleShowTooltip}
      onBlur={handleHideTooltip}
    >
      <FontAwesomeIcon icon={ isLock ? faLock : faCircleInfo} className={`z-20 ${isLock ? "text-text/70" : ""}`}/>
      {showTooltip && (
        <div className={`
          font-semibold [&>a]:underline [&>a]:text-text/70 [&>a]:hover:text-text text-left
          absolute bg-background text-text text-sm p-2 rounded shadow z-30 
          border border-lightGray/50 ${position ? positionStyle(position) : "top-[50%] left-[115%]"}`}
        >
          <p className={customWidth ? customWidth : "" + ` max-w-[200px] break-words w-fit min-w-[150px]`}>{parseStringToHTML(content)}</p>
        </div>
      )}
    </button>
  );
};

export default Tooltip;
