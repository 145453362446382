import { ID } from 'react-accessible-accordion/dist/types/components/ItemContext';
import InProgress from '../Pages/IpExpertPages/InProgress';
import instance from '../middleware/HTTPInterceptors';
import ErrorResponse from '../utilities/ErrorResponse';
import * as T from '../utilities/frontendTypes';
import { getAuthHeader } from '../utilities/helperFunctions';

/*
Membership Services
*/

export async function getMemberHeaders(): Promise<T.MembershipsResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/admins/memberships`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getMemberDetails(id: T.UserId): Promise<T.MemberDetailsResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/admins/memberships/list/${id}`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getMembershipApplicationHeaders(): Promise<T.MembershipApplicationHeadersResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get("/api/admins/membership-applications", config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getMembershipApplicationDetails(id: string): Promise<T.ApplicationDetailsResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/admins/membership-application/${id}`, config);

     if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function updateApplicationStatus(appId: string, {reasonNote, status, reapply}: {reasonNote: string, status: T.ApplicationStatus, reapply: boolean}, applicationType: string): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const data = {
      reasonNote,
      status,
      reapply
    }

    const res = await instance.put(`/api/admins/${applicationType}-application/${appId}/status-update`, 
    data, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

/*
Program Services
*/

export async function getAdminPrograms(): Promise<T.AdminProgramsResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get("/api/admins/programs", config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getProgramDetails(programId: T.ProgramId): Promise<T.ProgramDetailsResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/admins/programs/${programId}`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getMyPrograms(): Promise<T.AdminProgramsResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get("/api/admins/my-programs", config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getProgramsApplicationHeaders(): Promise<T.ProgramApplicationHeadersResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get("/api/admins/program-applications", config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getPendingApplicationHeaders(): Promise<T.PendingProgramApplicationsResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get("/api/admins/pending-applications", config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getProgramApplicationDetails(id: string): Promise<T.ApplicationDetailsResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/admins/program-applications/${id}`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function updateProgram(updatedProgram: T.UpdatedProgram & {_id: string}): Promise<T.UpdatedProgramResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.put(`/api/admins/program-update`, updatedProgram, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function deleteProgram(programId: T.ProgramId): Promise<T.UpdatedProgramResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.delete(`/api/admins/delete-program/${programId}`, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function createNewProgram(newProgram: T.UpdatedProgram): Promise<T.UserProfileResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.post(`/api/admins/create-program`, newProgram, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function createUserProfile(profile: T.NewUserProfile ): Promise<T.HTTPResponse> {
  const newUser = profile
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.post(`/api/admins/create-user`, newUser, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getAllAdmins(): Promise<T.AdminHeadersResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get("/api/admins/partner-admins", config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function assignTier(applicationId: string, tier: number): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.post(`/api/admins/membership-application/assign-tier`, {applicationId, tier}, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function changeTier(memberId: string, tier: number): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.put(`/api/admins/memberships/change-tier`, {memberId, tier}, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

/*

  Service Provider Firm Services

*/
export async function createNewSPFirm(newFirm: T.ServiceProviderFirm): Promise<T.HTTPResponse> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.post(`/api/admins/sp-firms/create`, newFirm, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

// [TODO] refactor the URL to sp-firms/:firmId/update
export async function updateSPFirm(firmUpdateData: T.ServiceProviderFirm & { firmId: T.FirmId }): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.put(`/api/admins/sp-firms/update`, firmUpdateData, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function deleteSPFirm(firmId: T.FirmId): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.delete(`/api/admins/sp-firms/${firmId}/delete`, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getSPFirms(): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/sp-firms`, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getSPFirmDetailsById(firmId: T.FirmId): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/sp-firms/${firmId}`, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

/* 

  Portal Control Services

*/
export async function getNewMemberAcceptanceStatus(): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/new-member-acceptance-status`, config);
    
    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function updateNewMemberAcceptanceStatus(status: "active" | "inactive"): Promise<T.HTTPResponse> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.put(`/api/admins/new-member-acceptance-status`, {status}, config);
    
    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function createExpertProfile(newExpert: T.User): Promise<T.UpdatedProgramResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.post(`/api/admins/create-expert`, newExpert, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function createServiceProviderProfile(newServiceProvider: T.User): Promise<T.UpdatedProgramResponse> {
  const config = {
    headers: await getAuthHeader(),
  } 
  try { 
      const res = await instance.post(`/api/admins/create-service-provider`, newServiceProvider, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}


export async function getRegionFounderDetailsAsync(): Promise<{success: boolean, regionalFounderDetails: {membershipApplications: number, regionalFounders: T.User[], foundersWithNoApplications: T.User[], uninvited: T.User[], dueDiligence: T.User[]}}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/region-founder-details`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}


export async function getRegionExpertStatsAsync(): Promise<{success: boolean, regionalExpertDetails: {uninvited: number, inProgress: number, preMeeting: number, awaitingApproval: number}}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/region-expert-details`, config);

    if (res.status === 200) return res.data;
    
    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getRegionCoachStatsAsync(): Promise<{success: boolean, regionalCoachDetails: {invited: number, inProgress: number, recommendation: number}}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/region-coach-details`, config);

    if (res.status === 200) return res.data;
    
    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getUninvitedDetailsAsync(): Promise<{success: boolean, uninvitedFoundersArray: {companyName: string, personaScore: number, userId: string}[]}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/uninvited-founders`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getPreMeetingDetailsAsync(): Promise<{success: boolean, preMeetingFoundersArray: {companyName: string}[]}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/premeeting-founders`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getCoachPreMeetingDetailsAsync(): Promise<{success: boolean, preMeetingFoundersArray: {companyName: string, id: string, date: Date}[]}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/coach-premeeting-founders`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}


export async function getInProgressDetailsAsync(): Promise<{success: boolean, inProgressFoundersArray: {companyName: string, date: Date}[]}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/in-progress-founders`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getCoachInProgressDetailsAsync(): Promise<{success: boolean, inProgressFoundersArray: {companyName: string, date: dateFns, id: string}[]}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/coach-in-progress-founders`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getRecommendationFounderHeaders(): Promise<{success: boolean, expertRecommendationsArray: {comapanyName: string, date: Date, id: T.UserId}[]}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/expert-recommendations`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getCoachRecommendationFounderHeaders(): Promise<{success: boolean, coachRecommendationsArray: {comapanyName: string, recommendation: string, id: T.UserId}[]}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/coach-recommendations`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}



export async function getRecommendationDetails(userId: T.UserId): Promise<{success: boolean, result: {rec: string, score: {
  totalScore: number,
  generalIpScore: number,
  patentScore: number,
  trademarkScore: number
},
firms: [{value: string, text: string}]}}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/expert-recommendations/${userId}`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function sendMassEmail(userIds: string[], emailType: string, expertName?: string): Promise<T.HTTPResponse> {
  
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.put(`/api/admins/send-expert-invites`, {userIds, emailType, expertName}, config);
    
    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function founderStatusUpdateReject(userId: string, notes: string): Promise<T.HTTPResponse> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.put(`/api/admins/set-founder-status-rejected`, {userId, notes}, config);
    
    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function growthCoachOutcomeUpdate(userId: string, coachStatus: string): Promise<T.HTTPResponse> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.put(`/api/admins/set-growth-coach-outcome`, {userId, coachStatus}, config);
    
    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function createNewSowRequest(sowRequestDetails: {spId: string, tier: string, startupId: string}): Promise<T.HTTPResponse> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.post(`/api/admins/sow-request-create`, sowRequestDetails, config);
    
    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getInDdFoundersAsync(): Promise<{success: boolean, inDdFoundersArray: {companyName: string, id: string}[]}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/in-dd-founders`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getDueDiligenceDetails(userId: T.UserId): Promise<{success: boolean, notes: T.NotesObject[]}> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/dd-details/${userId}`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getCoachPreMeetingDetails(userId: T.UserId): Promise<{success: boolean, coachDetails: {is: string, date: Date, companyName: string}}> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/coach-premeeting-founders/${userId}`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getCoachProgressDetails(userId: T.UserId): Promise<{success: boolean, notes: T.NotesObject[]}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/coach-in-progress-founders/${userId}`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function massStatusUpdate(userIds: string[], status: T.FounderStatus): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.put(`/api/admins/mass-status-update`, {userIds, status}, config);
    
    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getRegionAdminNotes(userId: T.UserId): Promise<{success: boolean, notes: T.NotesObject[]}> {

  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/get-admin-notes/${userId}`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function addNewNote(id: T.UserId, notes: string): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const data = {
      id,
      notes
    }

    const res = await instance.put(`/api/admins/add-new-note`, data, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function getDownloadUrl(docKey: string): Promise<{success: boolean, url: string }> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.get(`/api/admins/get-pdf/${docKey}`, config);
    
    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}

export async function getfounderDocuments(applicationId: string): Promise<{success: boolean, docs: string[]}> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }
    
    const res = await instance.get(`/api/admins/get-docs-by-application/${applicationId}`, config);

    if (res.status === 200) return res.data;

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  }
}


export async function devOnlyDbFixes(): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.put(`/api/admins/devonlydbfixes`, {}, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}

export async function growthCoachByapplication(applicationId: string): Promise<T.HTTPResponse> {
  try {
    const config = {
      headers: await getAuthHeader(),
    }

    const res = await instance.put(`/api/admins/update-growth-coach-by-application`, {applicationId}, config);

    if (res.status === 200) {
      return res.data
    }

    throw new ErrorResponse("Request unsuccessful", 400);

  } catch (err: any) {
    throw new ErrorResponse(err.response.data.message, err.response.status);
  } 
}