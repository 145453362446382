import Form, { FormData } from "../../Components/Form/Form";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { getRegionalMembershipId, getUserProfile, updateUserProfile } from "../../services/userService";
import { AuthContext, AuthContextType, ConditionalRenderContext, ConditionalRenderContextType, LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync} from "../../utilities/customHooks";
import * as T from "../../utilities/frontendTypes";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Button, { ButtonProp } from "../../Components/Button";
import { createFounderApplication, getLatestMembershipApplication } from "../../services/founderService";
import { Address } from "../../Components/Form/AddressFields";
import { convertFounderProfileDBStateToUIState } from "../../utilities/stateConverters";
import ContentPageTopButtons from "../../Components/ContentPageTopButtons";
import { DetailsContentHeader } from "../AdminPages/ProfilePages/NewAdmin";
import { flattenObject, handleFormSaveResponse, returnLangCheckedFormGroupDef } from "../../utilities/helperFunctions";
import Tooltip from "../../Components/Tooltip";
import { contentPageWrapperStyle } from "../AdminPages/ProfilePages/PartnerAdminList";
import ErrorIndicator from "../../Components/ErrorIndicator";
import _ from 'underscore';
import Modal from "../../Components/Modal";
import textData from "../../textData.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import FileUpload from  "../../Components/Form/pdfUpload"
import {profileFormCompanyInfoDefinition, profileFormPersonalInfoDefinition, profileFormProgramingEvaluationDefinition, profileFormiPStrategyDefinition, profileFormipReadinessDefinition} from "../../utilities/profileFormDefitions"


function FounderProfileUpdated() {
  const { langState } = useContext(LangContext) as LangContextType
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const { memberApplicationStatus, setMemberApplicationStatus, userRegion } = useContext(AuthContext) as AuthContextType; 
  const navigate = useNavigate();
  const conditionalRenderContext = useContext(ConditionalRenderContext) as ConditionalRenderContextType;
  const [founderProfile, setFounderProfile] = useState<T.StartupFounderProfile | null>(null);
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const [isEditing, setIsEditing] = useState(false);
  const [isAttestaionChecked, setIsAttestaionChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [applicationTier, setApplicationTier] = useState<number>(1)
  const [isTier3, setIsTier3] = useState(false)
  const [updateTier, setUpdateTier] = useState(false)
  const [progressHTML, setProgressHTML] = useState<React.ReactNode | null>(null);
  const [currentHTML, setCurrentHTML] = useState<FounderProfileSectionKey>("personalInfo")
  const [pdfUploaded, setPdfUploaded] = useState(false);
  const [pdfSaved, setPdfSaved] = useState(false);

  const handlePdfStateChange = () => {
    setPdfUploaded(true);
  };

  useEffect(() => {
    const progWItemWidth = isTier3 ? "w-1/6" : "w-1/5"
    if (founderProfile?.personalInfo) {
        setPersonalInfo(founderProfile.personalInfo)
        setPersonalInfoComplete("complete")
      }
      if ( founderProfile?.personalInfo && founderProfile?.companyInfo && founderProfile.companyInfo.basicInfo !== null && founderProfile.companyInfo.registration !== null && founderProfile.companyInfo.mailingAddress) {
        setCompanyInfo(founderProfile.companyInfo)
        setCompanyInfoComplete("complete")
      }
      if (founderProfile?.documents?.pitchdeck) {
        setPitchDeckComplete("complete")
      }
      if (founderProfile?.personalInfo && founderProfile?.companyInfo && founderProfile.companyInfo.basicInfo && founderProfile?.iPStrategy !== null) {
        setIPStrategy(founderProfile.iPStrategy)
        setIpStrategyComplete("complete")
      }
      if (founderProfile?.ipReadiness) {
        setIPReadiness(founderProfile.ipReadiness)
        setIPReadinessComplete("complete")
        
      }        
      if (founderProfile !== null && founderProfile?.programingEvaluation !== null) {
        setProgramingEvaluation(founderProfile.programingEvaluation)
        setProgramingEvaluationComplete("complete")
      }  
    
    const progressBar = 
      <div className="flex w-full mb-5">
        <div className="flex space-between w-full">
          <div className={`flex flex-col justify-center content-center items-center ` + progWItemWidth}>
            <button 
              onClick={() => setCurrentHTML("personalInfo")} 
              disabled={personalInfoComplete === "complete" ? false : true} 
              className={
                  personalInfoComplete === "complete" ? ` hover:underline` : "" +
                  currentHTML === "personalInfo" ? ` font-bold` : "" + 
                  personalInfoComplete === "incomplete" && currentHTML 
                  !== "personalInfo" ? ` opacity-80 hover:cursor-not-allowed` : ""
              }
            >
              Personal Info
            </button>
            <FontAwesomeIcon icon={personalInfoComplete === "complete" ? faCheckCircle : faCircle} />
          </div>

          <div className={`flex flex-col justify-center content-center items-center ` + progWItemWidth}>
            <button 
              onClick={() => setCurrentHTML("companyInfo")} 
              disabled={companyInfoComplete === "complete" ? false : true} 
              className={
                  companyInfoComplete === "complete" ? ` hover:underline` : "" +
                  currentHTML === "companyInfo" ? ` font-bold` : "" + 
                  companyInfoComplete === "incomplete" && currentHTML 
                  !== "companyInfo" ? ` opacity-80 hover:cursor-not-allowed` : ""
              }
            >
              Company Info
            </button>
            <FontAwesomeIcon icon={companyInfoComplete === "complete" ? faCheckCircle : faCircle} />
          </div>

          <div className={`flex flex-col justify-center content-center items-center ` + progWItemWidth}>
            <button 
              onClick={() => setCurrentHTML("pitchDeck")} 
              disabled={pitchDeckComplete === "complete" ? false : true} 
              className={
                  pitchDeckComplete === "complete" ? ` hover:underline` : "" +
                  currentHTML === "pitchDeck" ? ` font-bold` : "" + 
                  pitchDeckComplete === "incomplete" && currentHTML 
                  !== "pitchDeck" ? ` opacity-80 hover:cursor-not-allowed` : ""
              }
            >
              Supporting Documents
            </button>
            <FontAwesomeIcon icon={pitchDeckComplete === "complete" ? faCheckCircle : faCircle} />
          </div>

          <div className={`flex flex-col justify-center content-center items-center ` + progWItemWidth}>
            <button 
              onClick={() => setCurrentHTML("iPStrategy")} 
              disabled={ipStrategyComplete === "complete" ? false : true} 
              className={
                  ipStrategyComplete === "complete" ? ` hover:underline` : "" +
                  currentHTML === "ipStrategy" ? ` font-bold` : "" + 
                  ipStrategyComplete === "incomplete" && currentHTML 
                  !== "companyInfo" ? ` opacity-80 hover:cursor-not-allowed` : ""
              }
            >
              IP Strategy
            </button>
            <FontAwesomeIcon icon={ipStrategyComplete === "complete" ? faCheckCircle : faCircle} />
          </div>
        {
        isTier3 && 
          <div className={`flex flex-col justify-center content-center items-center ` + progWItemWidth}>
            <button 
              onClick={() => setCurrentHTML("ipReadiness")} 
              // disabled={ipReadinessComplete === "complete" ? false : true} 
              className={
                  ipReadinessComplete === "complete" ? ` hover:underline` : "" +
                  currentHTML === "ipReadiness" ? ` font-bold` : "" + 
                  ipReadinessComplete === "incomplete" && currentHTML 
                  !== "ipReadiness" ? ` opacity-80 hover:cursor-not-allowed` : ""
              }
            >
              IP Readiness
            </button>            
            <FontAwesomeIcon className="far color-white" border={true} icon={ipReadinessComplete === "complete" ? faCheckCircle : faCircle} />
          </div>
        }
          <div className={`flex flex-col justify-center content-center items-center ` + progWItemWidth}>
            <button 
              onClick={() => setCurrentHTML("programingEvaluation")} 
              disabled={programingEvaluationComplete === "complete" ? false : true} 
              className={
                  programingEvaluationComplete === "complete" ? ` hover:underline` : "" +
                  currentHTML === "programingEvaluation" ? ` font-bold` : "" + 
                  programingEvaluationComplete === "incomplete" && currentHTML 
                  !== "programingEvaluation" ? ` opacity-80 hover:cursor-not-allowed` : ""
              }
            >
              Programming Evaluation
            </button>
            <FontAwesomeIcon icon={programingEvaluationComplete === "complete" ? faCheckCircle : faCircle} />
          </div>
        </div>
      </div>
    
    setProgressHTML(progressBar)
  }, [founderProfile, currentHTML, isTier3]);

  type FounderProfileSectionKey = "personalInfo" | "companyInfo" | "pitchDeck" | "iPStrategy" | "ipReadiness" | "programingEvaluation"
  
  const htmlCheck = () => {
    if (personalInfoComplete === "incomplete") {
      setCurrentHTML("personalInfo")
    }
    if (personalInfoComplete === "complete" && companyInfoComplete === "incomplete") {
      setCurrentHTML("companyInfo")
    }
    if (personalInfoComplete === "complete" && companyInfoComplete === "complete" && pitchDeckComplete === "incomplete") {
      setCurrentHTML("pitchDeck")
    } else {
    if (founderProfile?.iPStrategy?.bestMatch?.includes("trademarks") || founderProfile?.iPStrategy?.bestMatch?.includes("patents")) {
      if (founderProfile.iPStrategy.patentsReadyOptions?.includes("search") || founderProfile.iPStrategy.patentsReadyOptions?.includes("family") || founderProfile.iPStrategy.patentsReadyOptions?.includes("written")) {
        if (founderProfile.iPStrategy.trademarkClearanceSearch === true) {
          setCurrentHTML("ipReadiness")
        } else {
          setCurrentHTML("programingEvaluation")
        }
      } else {
        setCurrentHTML("programingEvaluation")
      }
    } else {
      setCurrentHTML("programingEvaluation")
    }
  }
}

  const nextCurrentHtml = (current: FounderProfileSectionKey) => {
    if (current === "personalInfo") {
        setCurrentHTML("companyInfo")
    }
    if (current === "companyInfo") {
        setCurrentHTML("pitchDeck")
    }
    if (current === "pitchDeck") {
        setCurrentHTML("iPStrategy")
    }
    if (current === "iPStrategy" && isTier3) {
      htmlCheck()
    }
    if (current === "ipReadiness" && isTier3) {
      setCurrentHTML("programingEvaluation")
    }
    if (current === "iPStrategy" && isTier3 === false) {
        htmlCheck()
    }
  }


  //FORM STATES
  const [personalInfo, setPersonalInfo] = useState<T.PersonalInfo | null>(null)
  const [companyInfo, setCompanyInfo] = useState<T.CompanyInfo | null>(null)
  const [IPStrategy, setIPStrategy] = useState<T.IPStrategy | null>(null)
  const [ipReadiness, setIPReadiness] = useState<T.IPReadiness | null>(null)
  const [programingEvaluation, setProgramingEvaluation] = useState<T.ProgramingEvaluation | null>(null)
  const [pitchDeck, setPitchDeck] = useState(false)
  //FORM COMPLETENESS
  const [personalInfoComplete, setPersonalInfoComplete] = useState<FormCompletnessState>("incomplete")
  const [companyInfoComplete, setCompanyInfoComplete] = useState<FormCompletnessState>("incomplete")
  const [pitchDeckComplete, setPitchDeckComplete] = useState<FormCompletnessState>("incomplete")
  const [ipStrategyComplete, setIpStrategyComplete] = useState<FormCompletnessState>("incomplete")
  const [ipReadinessComplete, setIPReadinessComplete] = useState<FormCompletnessState>("incomplete")
  const [programingEvaluationComplete, setProgramingEvaluationComplete] = useState<FormCompletnessState>("incomplete")
  const [mailingAddress, setMailingAddress] = useState<any>(null)
  //
  type FormCompletnessState = "complete" | "incomplete" 

  // if (userRegion === "investOttawa") {
  //   setPitchDeckComplete("complete")
  //   setPdfUploaded(true);
  // }
  useEffect(() => {
    try {
      const fetchFounderProfile = async () => {
        showLoading();
        const founderProfileRes =  await getUserProfile("startupFounder");
        hideLoading();
      
        const initialProfile = founderProfileRes.profile as T.StartupFounderProfile | null;
        setMailingAddress(initialProfile?.companyInfo?.mailingAddress)
        setFounderProfile(initialProfile);
        //FORM STATES
        if (initialProfile?.personalInfo) {
          setPersonalInfo(initialProfile.personalInfo)
          setPersonalInfoComplete("complete")
          setCurrentHTML("companyInfo")
        }
        if (initialProfile?.companyInfo && initialProfile.companyInfo.basicInfo !== null && initialProfile.companyInfo.registration !== null && initialProfile.companyInfo.mailingAddress && initialProfile.personalInfo) {
          setCompanyInfo(initialProfile.companyInfo)
          setCompanyInfoComplete("complete")
          setCurrentHTML("pitchDeck")
        }
        if (initialProfile?.documents?.pitchdeck) {
          setPitchDeck(true)
          setPitchDeckComplete("complete")
          setCurrentHTML("iPStrategy")
        }
        if (initialProfile?.iPStrategy && initialProfile.documents && initialProfile.documents.pitchdeck && initialProfile?.companyInfo && initialProfile.companyInfo.basicInfo !== null && initialProfile.companyInfo.registration !== null && initialProfile.companyInfo.mailingAddress && initialProfile.personalInfo) {
          console.log("to Blame")
          setIPStrategy(initialProfile.iPStrategy)
          setIpStrategyComplete("complete")
          if (initialProfile?.iPStrategy.bestMatch?.includes("trademarks") || initialProfile?.iPStrategy.bestMatch?.includes("patents")) {
            console.log("inhere")
            if (initialProfile.iPStrategy.patentsReadyOptions?.includes("search") || initialProfile.iPStrategy.patentsReadyOptions?.includes("family") || initialProfile.iPStrategy.patentsReadyOptions?.includes("written")) {
              if (initialProfile.iPStrategy.trademarkClearanceSearch === true) {
                setIsTier3(true)
                setCurrentHTML("ipReadiness")
              } else {
                htmlCheck()
              }
            } else {
              htmlCheck()
            }
         }
        }
        if (initialProfile?.ipReadiness && initialProfile.iPStrategy && initialProfile.documents && initialProfile.documents.pitchdeck && initialProfile?.companyInfo && initialProfile.companyInfo.basicInfo !== null && initialProfile.companyInfo.registration !== null && initialProfile.companyInfo.mailingAddress && initialProfile.personalInfo) { 
          setIPReadiness(initialProfile.ipReadiness)
          setIPReadinessComplete("complete")
          htmlCheck()
        }        
        if (initialProfile?.programingEvaluation) {
          setProgramingEvaluation(initialProfile?.programingEvaluation)
          setProgramingEvaluationComplete("complete")
          if (memberApplicationStatus !== "unsubmitted") {
            setCurrentHTML("personalInfo")
          }
        }  

        
        // Register conditional render state
        const conditionalRenderInitialState = {
          isPointOfContact: !!initialProfile?.personalInfo?.isPointOfContact,
          isLegalNameSameAsOperatingName: !!initialProfile?.companyInfo?.basicInfo?.isLegalNameSameAsOperatingName,
          isWorkingWithLawFirm: !!initialProfile?.iPStrategy?.isWorkingWithLawFirm,
          isWorkingWithOrganization: !!initialProfile?.iPStrategy?.isWorkingWithOrganization,
          isWorkingWithBai: !!initialProfile?.iPStrategy?.isWorkingWithBai,
          isFromOLMC: !!initialProfile?.programingEvaluation?.isFromOLMC,
          filePatentReady: !!initialProfile?.iPStrategy?.filePatentReady,
          fileTrademarkReady: !!initialProfile?.iPStrategy?.fileTrademarkReady,
          isProductForSaleOnMarket: !!initialProfile?.programingEvaluation?.isProductForSaleOnMarket,
          educationType: !!initialProfile?.ipReadiness?.education,
          specificJurisdiction: !!initialProfile?.ipReadiness?.jurisdiction,
          tradeSecretsList: !!initialProfile?.ipReadiness?.tradeSecrets,
          patentsNumber: !!initialProfile?.ipReadiness?.tradeSecrets,
        }
        conditionalRenderContext.replaceConditionalRenderState(conditionalRenderInitialState);

        // if(personalInfoComplete === "complete" && companyInfoComplete === "incomplete") {
        //   setCurrentHTML("companyInfo")    
        // }
        // if(companyInfoComplete === "complete" && pitchDeckComplete === "incomplete") {
        //     setCurrentHTML("pitchDeck")    
        // }
        // if (pitchDeckComplete === "complete" && ipStrategyComplete === "incomplete") {
        //     setCurrentHTML("iPStrategy")    
        // }
        // if(ipStrategyComplete === "complete" && isTier3 && ipReadinessComplete === "incomplete") {
        //     setCurrentHTML("ipReadiness")    
        // } else if (ipStrategyComplete === "complete" && !isTier3 && programingEvaluationComplete === "incomplete") {
        //     setCurrentHTML("programingEvaluation")    
        // }
        // if (programingEvaluationComplete === "complete" && memberApplicationStatus === "unsubmitted" ) {
        //     setCurrentHTML("programingEvaluation")    
        // }
      }
      
      fetchFounderProfile().catch(console.error);
     
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line 
  }, [updateTier, pdfSaved]);
  
  const latestMembershipApplicationAsync = useAsync(() => getLatestMembershipApplication(), []);
  
  if (latestMembershipApplicationAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (latestMembershipApplicationAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  // db state to UI state
  const founderProfileGroupData = convertFounderProfileDBStateToUIState(founderProfile);
  if (founderProfile?.ipReadiness !== null) {
  }

  // logic to handle FormGroup validation on FounderProfile
  const handleSubmissionReject = (message?: string) => {
    showToast({
      toastType: "info",
      title: textData.Pages.FounderPages.FounderProfile.UnsuccessfulSubmit[langState],
      message: message || textData.Pages.FounderPages.FounderProfile.UnsuccessfulSubmitMessage[langState],
    })
  }

  const handleSubmitMembershipApplication = async () => {

    if (!founderProfile) {
      handleSubmissionReject();
      return;
    }

    const { personalInfo, pointOfContact, companyInfo, iPStrategy, programingEvaluation, ipReadiness } = founderProfile;

    if (!companyInfo) {
      handleSubmissionReject();
      return;
    }

    const { registration, 
            mailingAddress, 
            basicInfo } = companyInfo;

    if (
      personalInfo === null ||
      (!personalInfo.isPointOfContact && pointOfContact === null) || // is not point of contact but PoC data is missing
      basicInfo === null ||
      (registration === null) || 
      mailingAddress === null ||
      iPStrategy === null ||
      programingEvaluation === null ||
      applicationTier === 3 && ipReadiness === null 
    ) {
      handleSubmissionReject();
      return;
    }
    
    showLoading();
    const programId = (await getRegionalMembershipId()).membershipId;
    const response = await createFounderApplication(programId);
    hideLoading();
    if (response.success && !response.autoReject) {
      setMemberApplicationStatus(response.applicationStatus);
      navigate("/dashboard");
    } 
    handleFormSaveResponse (
      response, 
      showToast, 
      {message: textData.Pages.FounderPages.FounderProfile.SuccessfulSubmit[langState]}, 
      {title: textData.Pages.FounderPages.FounderProfile.AttemptUnsuccessfulTitle[langState], message: (response.message ? response.message : textData.Pages.FounderPages.FounderProfile.ServerErrorSubmitMessage[langState]), autoDisappearTimeInSeconds: 8}
    );
  }
  
  async function handleSaveSection(updatedSectionFormData: FormData, sectionKey: string) {

    const emptyProfile = {
      personalInfo: null, 
      pointOfContact: null, 
      companyInfo: null,
      iPStrategy: null, 
      ipReadiness: null,
      pitchDeck: null,
      programingEvaluation: null,
      sLASigningTimestamp: new Date(),
    }

    const updatedFounderProfile: T.StartupFounderProfile = founderProfile === null ? emptyProfile : {...founderProfile};
    switch (sectionKey as FounderProfileSectionKey) {
      case "personalInfo":
        const personal = updatedSectionFormData as T.PersonalInfo
        updatedFounderProfile.personalInfo = {
            firstName: personal.firstName,
            lastName: personal.lastName,
            pronouns: personal.pronouns,
            title: personal.title,
            phoneNumber: personal.phoneNumber,
            isPointOfContact: true
        }
        if (updatedFounderProfile.personalInfo.isPointOfContact) {
          updatedFounderProfile.pointOfContact = null;
        }
        break;
      case "companyInfo":
        const basic = updatedSectionFormData as T.CompanyBasicInfo
        const reg = updatedSectionFormData as T.CompanyRegistrationInfo
        const address = mailingAddress as unknown
        updatedFounderProfile.companyInfo = {
            basicInfo: {
                operatingName: basic.operatingName,
                isLegalNameSameAsOperatingName: basic.isLegalNameSameAsOperatingName,
                legalName: basic.legalName || null,
                description: basic.description,
                industry: basic.industry,
                websiteURL: basic.websiteURL,
            },
            registration: {
                number: reg.number,
                date: reg.date,
                province: reg.province
            },
            mailingAddress: address as Address
        }
        break;
      case "iPStrategy":
        updatedFounderProfile.iPStrategy = updatedSectionFormData as T.IPStrategy;
        if (updatedFounderProfile.iPStrategy.bestMatch?.includes("trademarks") || updatedFounderProfile.iPStrategy.bestMatch?.includes("patents")) {
          if (updatedFounderProfile.iPStrategy.patentsReadyOptions?.includes("search") || updatedFounderProfile.iPStrategy.patentsReadyOptions?.includes("family") || updatedFounderProfile.iPStrategy.patentsReadyOptions?.includes("written")) {
            if (updatedFounderProfile.iPStrategy.trademarkClearanceSearch === true) {
              setIsTier3(true)
              setUpdateTier(true)
              setCurrentHTML("ipReadiness")
            } else {
              setIsTier3(false)
            }
          } else {
            setIsTier3(false)
            }
        } else {
            setIsTier3(false)
        }
        break;
      case "ipReadiness":
        updatedFounderProfile.ipReadiness = updatedSectionFormData as T.IPReadiness;
        break;
      case "programingEvaluation":
        const underRepresentedGroupsArray = updatedSectionFormData.underrepresentedFounderGroups as string[];
        const isOwnedByUnderrepresentedGroups = !underRepresentedGroupsArray.includes("none");
        updatedSectionFormData.isOwnedByUnderrepresentedGroups = isOwnedByUnderrepresentedGroups
        updatedFounderProfile.programingEvaluation = updatedSectionFormData as T.ProgramingEvaluation;
        break;
      default:
        throw new Error(`Unknown sectionKey: ${sectionKey}`);
    }
    //MIGHT HAVE TO GO IN THE SUCCESS, SET THE STATE TO THE RETURN FROM STARTUPFOUNDER PROFILE
    setFounderProfile(updatedFounderProfile);
    
    showLoading();
    const res = await updateUserProfile("startupFounder", updatedFounderProfile);
    hideLoading();
    if (res.success) {
    nextCurrentHtml(currentHTML)
      showToast({
        toastType: "success",
        message: textData.Pages.FounderPages.FounderProfile.SectionToastSuccess[langState]
      })
    } else {
      showToast({
        toastType: "failure",
        message: textData.Pages.FounderPages.FounderProfile.SectionToastFail[langState]
      })
    }
  }

  const attestaionPrompt = () => {
    showToast({
      toastType: "failure",
      message: textData.Pages.FounderPages.FounderProfile.AttestationFailure[langState]
    })
  }

  const attestationTextEng = `I, ${founderProfile?.personalInfo?.firstName} ${founderProfile?.personalInfo?.lastName}, have legal authority to represent ${founderProfile?.companyInfo?.basicInfo?.operatingName} and attest that the support provided by Communitech / Invest Ottawa / North Forge under the ElevateIP program will not be used for any services that are already funded by another government program or grant. Any use of the ElevateIP program funds on services that are already funded by another government program or grant may lead to immediate termination from the ElevateIP program and/or the immediate return of such funding.`
  const attestationTextFr = `Je, ${founderProfile?.personalInfo?.firstName} ${founderProfile?.personalInfo?.lastName}, ai l'autorité légale de représenter ${founderProfile?.companyInfo?.basicInfo?.operatingName} et j'atteste que le soutien fourni par Communitech / Invest Ottawa / North Forge dans le cadre du programme ElevateIP ne sera pas utilisé pour des services qui sont déjà financés par un autre programme gouvernemental ou une subvention.  Toute utilisation des fonds du programme ÉleverlaPI pour des services qui sont déjà financés par un autre programme gouvernemental ou une subvention pourrait entraîner l'annulation immédiate du programme ÉleverlaPI et/ou la demande de restitution immédiate de ces fonds.`

  //WHEN READY TO SUBMIT
  const modalProfileCheck = () => {
    
    if (!founderProfile) {
      handleSubmissionReject();
      return;
    }

    const { personalInfo, pointOfContact, companyInfo, iPStrategy, programingEvaluation, ipReadiness } = founderProfile;

    if (!companyInfo) {
      handleSubmissionReject();
      return;
    }

    if(!pitchDeck) {
        handleSubmissionReject();
        return;
    }

    const { registration, 
            mailingAddress, 
            basicInfo } = companyInfo;

    if (
      personalInfo === null ||
      (!personalInfo.isPointOfContact && pointOfContact === null) || // is not point of contact but PoC data is missing
      basicInfo === null ||
      (basicInfo.isCompanyNotIncorporated && registration === null) || // is NOT incorporated but registration data is missing
      mailingAddress === null ||
      iPStrategy === null ||
      programingEvaluation === null
      || isTier3 && ipReadiness === null
    ) {
      handleSubmissionReject();
      return;
    }
    setIsModalOpen(true)
  }

  const communitechAttestation = langState === "fr" ? attestationTextFr : attestationTextEng

  const modalHTML  = (
    <div className="flex flex-col">
      <div>
          <p className="mb-3">{communitechAttestation}</p>
        <div className='flex w-full items-center ml-5 mb-1'>
          <input 
            type="checkbox" 
            checked={isAttestaionChecked}
            onChange={() => setIsAttestaionChecked(!isAttestaionChecked)}
          />
          <p className="ml-5">{textData.Pages.FounderPages.FounderProfile.Confirm[langState]}</p>
        </div>
      </div>
    </div>
  )

  const pdfSave = () => {
    setPitchDeckComplete("complete")
    setCurrentHTML("iPStrategy")
    setPdfSaved(true)
  }

  const saveSectionInfo = (updateForm: FormData) => {
    handleSaveSection(updateForm, currentHTML)
    
  }

  const saveSectionButtonProp: ButtonProp = {
    buttonText: "Save",
    actionHandler: saveSectionInfo
  }

  const companyInfoToForm = (info: T.CompanyInfo) => {
    return flattenObject({basicInfo: info.basicInfo, registration: info.registration})
  }

  return (
    <div className="overflow-y-auto">
      <ContentPageTopButtons />

      <div className='flex flex-col justify-center align-middle items-center  overflow-y-auto'>
        {(memberApplicationStatus === "unsubmitted") ?
          <div className="flex flex-col w-full">
          <h1 className='text-5xl font-bold text-center mb-5'>{textData.Pages.FounderPages.FounderProfile.Title[langState]}</h1>
          <h2 className="text-xl mb-2 text-center">{textData.Pages.FounderPages.FounderProfile.Subtitle[langState]}</h2>
          <ul className="ml-8 mb-5 list-disc">
              <li className="mb-2">{textData.Pages.FounderPages.FounderProfile.Step2[langState]}</li>
              <li className="mb-2">{textData.Pages.FounderPages.FounderProfile.Step3[langState]}</li>
          </ul>
          </div>
          :
          <div className="flex flex-col w-full justify-between">
            <DetailsContentHeader 
              label={`${founderProfile?.personalInfo ? `${founderProfile.personalInfo.firstName}'s` : textData.Pages.FounderPages.FounderProfile.New[langState] } ${textData.Pages.FounderPages.FounderProfile.Profile[langState]}`}
            />
          </div>
          }
          <div className="w-full flex flex-col">
          {progressHTML}
          {
            currentHTML === "personalInfo" &&
            <div>
                <Form
                    formDefinition={profileFormPersonalInfoDefinition}
                    formData={personalInfo}
                    primaryButtonProp={saveSectionButtonProp}
                />
            </div>
          }
          {
            currentHTML === "companyInfo" &&
            <div>
                <Form
                    formDefinition={profileFormCompanyInfoDefinition}
                    formData={companyInfo === null ? null : companyInfoToForm(companyInfo)}
                    primaryButtonProp={saveSectionButtonProp}
                />
            </div>
          }
          {
            currentHTML === "pitchDeck" && 
            pitchDeckComplete === "incomplete" &&
            <div className="w-full flex-col flex">
                <p className="font-semibold mb-4">Pitch Deck / Company overview deck / Any other supporting documents</p>
                <p>If you have a pitch deck already prepared, please share a copy. If you do not have a pitch deck, please upload supporting documents that provide:</p>
                <ul className="list-disc">
                  <li className="ml-4">A clear description of your company  - (Product or service, business structure, ownership and management team, target market)</li>
                  <li className="ml-4">Current stage of the product - (Ideation, validation, prototyping, development, launch)</li>
                  <li className="ml-4">Milestones or traction achieved to date  - (Revenue, customers or users, partnerships, media coverage, success stories)</li>
                </ul>
                <div className="m-auto w-1/2 flex-col flex mt-4">
                <FileUpload handlePdfStateChange={handlePdfStateChange}/>
                <div className="w-full m-auto flex justify-center mt-5">
                  <Button 
                    buttonText="Save"
                    actionHandler={pdfSave}
                    disabled={!pdfUploaded}
                  />
                </div>
              </div>
            </div>
          }
          {
            currentHTML === "pitchDeck" && 
            pitchDeckComplete === "complete" &&         
            <div className="w-full flex-col flex">
              <div className="m-auto mt-2 mb-5 flex flex-col gap-4">
                <p className="font-bold">Supporting Documents have been received!</p> 
                <button onClick={() => setPitchDeckComplete("incomplete")} className="bold underline">Replace Document</button>
                <Button 
                  buttonText="Next"
                  actionHandler={() => setCurrentHTML("iPStrategy")}
                />
              </div>
            </div>
          }
          {
            currentHTML === "iPStrategy" &&
            <div>
                <Form
                    formDefinition={profileFormiPStrategyDefinition}
                    formData={IPStrategy}
                    primaryButtonProp={saveSectionButtonProp}
                />
            </div>
          }
          {
            currentHTML === "ipReadiness" && isTier3 &&
            <div>
                <Form
                    formDefinition={profileFormipReadinessDefinition}
                    formData={ipReadiness}
                    primaryButtonProp={saveSectionButtonProp}
                />
            </div>
          }
          {
            currentHTML === "programingEvaluation" &&
            <div>
                <Form
                    formDefinition={profileFormProgramingEvaluationDefinition}
                    formData={programingEvaluation}
                    primaryButtonProp={saveSectionButtonProp}
                />
                <div className="flex justify-center mt-5">
                <Button
                  buttonText={textData.Pages.FounderPages.FounderProfile.Submit[langState]}
                  disabled={memberApplicationStatus === "approved" ? true : programingEvaluation === null ? true : false}
                  actionHandler={modalProfileCheck}
                />
                </div>
            </div>
          }
          </div>
      </div>
      

      
      <Modal 
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        modalHeader={textData.Pages.FounderPages.FounderProfile.AttestationHeader[langState]}
        contentLabel={textData.Pages.FounderPages.FounderProfile.AttestatioLabel[langState]}
        primaryButton={{ buttonText: "Confirm", buttonCallback: () => isAttestaionChecked ? handleSubmitMembershipApplication() : attestaionPrompt() }}
        modalHTML={modalHTML}
      />
    </div>
  )
}

export default FounderProfileUpdated;