import { useNavigate } from "react-router-dom";
import { contentPageWrapperStyle } from "../Pages/AdminPages/ProfilePages/PartnerAdminList"
import { useAsync } from "../utilities/customHooks";
import LoadingIndicator from "./LoadingIndicator";
import ErrorIndicator from "./ErrorIndicator";
import { getRegionFounderDetailsAsync } from "../services/adminService";

const FounderStats = () => {
  const navigate = useNavigate();
  const founderDetailsAsync = useAsync(() => getRegionFounderDetailsAsync(), []);

  if (founderDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const { regionalFounders, foundersWithNoApplications, membershipApplications, uninvited, dueDiligence} = founderDetailsAsync.value.regionalFounderDetails;

  return (
    <div className="flex flex-col w-full pb-5">
      <h2 className="font-semibold text-2xl pb-5 w-full text-center">Profile Summary</h2>
      <div className="flex flex-col border-2 border-b-borders p-5 m-2">
        <button className="w-dashboardContentWidth pb-3 text-start hover:underline" onClick={() => navigate('/dashboard/membership-applications')}>You have {membershipApplications} pending application{membershipApplications !== 1 && 's'} for in your region</button>
        <button className="w-dashboardContentWidth pb-3 text-start hover:underline" onClick={() => navigate('/dashboard/membership-applications')}>There are {regionalFounders.length} companies registered with EIP in your region</button>
        <button className="w-dashboardContentWidth pb-3 text-start hover:underline" onClick={() => navigate('/dashboard/founders-no-profile')}>There are {foundersWithNoApplications.length} companies who have created an account but have not submitted a profile</button>          
        <button className="w-dashboardContentWidth pb-3 text-start hover:underline" onClick={() => navigate('/dashboard/admin-expert-preinvite')}>There are {uninvited.length} screened compan{uninvited.length !== 1 ? 'ies' : "y"} awaiting Action</button>
        <button className="w-dashboardContentWidth text-start hover:underline" onClick={() => navigate('/dashboard/admin-dd-application')}>You have {dueDiligence.length} application{dueDiligence.length !== 1 && 's'} flagged for due diligence</button>
        </div>
    </div>
  )
}

export default FounderStats