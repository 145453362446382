import Form, { FormData, FormDefinition } from "../../../Components/Form/Form";
import { useNavigate } from "react-router-dom";
import * as T from "../../../utilities/frontendTypes";
import { newServiceProviderFirmFormDefinition } from "../../../utilities/formDefinitionTemplates";
import { addressToString, getRegionNameFromRegion } from "../../../utilities/helperFunctions";
import { LangContext, LangContextType, useAsync, useRequiredParams } from "../../../utilities/customHooks";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import { basic_text_validator } from "../../../utilities/formFieldValidators";
import { DetailsContentHeader, detailsContentWrapperStyle, formContainerStyle, formWrapperStyle } from "../../AdminPages/ProfilePages/NewAdmin";
import { getSPFirmDetailsById } from "../../../services/founderService";
import textData from "../../../textData.json";
import { useContext } from "react";

function FirmDetails() {
  const { langState } = useContext(LangContext) as LangContextType

  const navigate = useNavigate();

  const firmId = useRequiredParams("firmId");
  const firmDetailsAsync = useAsync(() => getSPFirmDetailsById(firmId), [firmId]);

  if (firmDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (firmDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const firmDetails: T.IServiceProviderFirm & {_id: T.FirmId} = firmDetailsAsync.value.data;

  const { description, name, websiteURL, industriesOfFocus, contact, contactEmail, address, languages } = firmDetails;

  const firmDetailsFormData: FormData = {
    description,
    name, 
    websiteURL,
    industriesOfFocus,
    languages,
    contact,
    contactEmail,
    addressString: addressToString(address),
  }

  const modifiedSPFirmFormDefinition: FormDefinition = {
    ...newServiceProviderFirmFormDefinition,
    addressString: {
      label: textData.Pages.FounderPages.ServiceProviderFirmPages.FirmDetails.Address[langState],
      inputType: "text",
      validator: basic_text_validator,
    },
  };
  delete modifiedSPFirmFormDefinition.isActive;
  delete modifiedSPFirmFormDefinition.address;

  return (
    <div className={detailsContentWrapperStyle}>
      <ContentPageTopButtons 
        hideBackButton
        closeCallback={() => navigate('/dashboard/founder-sp-firms')}
      />
      <DetailsContentHeader label={textData.Pages.FounderPages.ServiceProviderFirmPages.FirmDetails.Label[langState]}/>
      <div className={formContainerStyle}> 
        <div className={formWrapperStyle}>
          <Form
            formData={firmDetailsFormData}
            formDefinition={modifiedSPFirmFormDefinition}
            disabled={true}
          />
        </div>
      </div>
    </div>
  )
}

export default FirmDetails;