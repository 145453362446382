import { useLocation, useNavigate } from "react-router-dom";
import { LangContext, LangContextType, useAsync, useReloadOnOutletSave, useRequiredParams } from "../../../utilities/customHooks";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import { getMemberDetails } from "../../../services/adminService";
import * as T from "../../../utilities/frontendTypes";
import FormGroup from "../../../Components/FormGroup";
import { convertFounderProfileDBStateToUIState } from "../../../utilities/stateConverters";
import { founderProfileEvaluationFormGroupDefinition } from "../../../utilities/formDefinitionTemplates";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { DetailsContentHeader, detailsContentWrapperStyle } from "../ProfilePages/NewAdmin";
import { isFromUrls } from "../../../utilities/helperFunctions";
import UpdateTier from "../../../Components/Features/UpdateTier";
import { useEffect, useState } from "react";
import textData from "../../../textData.json";
import { useContext } from "react";
import CompanyNotes from "../../../Components/AdminNotes";


function MemberDetails () {
  const { langState } = useContext(LangContext) as LangContextType
  const memberId = useRequiredParams("memberId");
  const navigate = useNavigate();
  const location = useLocation();
  const isFromProgramDetails = isFromUrls(["/dashboard/services/", "/dashboard/my-services/"], location);
  const [html, setHtml] = useState(<></>)

  useEffect(() => {
    setHtml(<CompanyNotes id={memberId}/>)
  }, [memberId]);

  return (
    <div className={`${detailsContentWrapperStyle} ${isFromProgramDetails && "!ml-0"}`}>
      <ContentPageTopButtons 
        hideBackButton={!isFromProgramDetails}
        closeCallback={() => navigate('/dashboard/memberships')}
      />
      {html}
    </div>
  )
}

export default MemberDetails