import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as T from "../utilities/frontendTypes";
import { Link } from "react-router-dom"
import Tooltip from "./Tooltip";

export default function SideMenuNavButton({link, text, icon, disabled, tooltipContent}: T.MenuData) {

  return (
    <div 
      className={
        `w-full flex transition-all rounded-sm 
        ${disabled ? "cursor-not-allowed" : "hover:bg-text/10 cursor-pointer"}`
      } 
      key={link}
    >
      <Link
        tabIndex={disabled ? -1 : 0}
        to={link}
        className={`p-iconPadding w-full ${disabled && 'pointer-events-none	opacity-50 cursor-not-allowed'}`}
        aria-disabled={disabled}
      >
        {icon && <FontAwesomeIcon className='w-iconWidth h-iconHeight pr-iconPadding' icon={icon} />}
        {text}
      </Link>
      { 
        disabled && tooltipContent 
        && 
        <Tooltip content={tooltipContent} type="lock"/> 
      }
    </div>
  )
}