import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { getSPFirms } from "../../../services/adminService";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { OutletContextType, useAsync, useAuth } from "../../../utilities/customHooks";
import Button from "../../../Components/Button";
import List, { KeyValuePair } from "../../../Components/List";
import * as T from "../../../utilities/frontendTypes";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { DetailsContentHeader } from "../ProfilePages/NewAdmin";
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList";
import { useState } from "react";
import BasicGoogleMap from "../../../Components/BasicGoogleMap";
import { organizations } from "../../../utilities/organizationData";
import Toggle from "../../../Components/Input/Toggle";
import { getLastPartOfPath } from "../../../utilities/helperFunctions";

function FirmList() {

  const navigate = useNavigate();
  const location = useLocation();

  const [reloadOnChildSave, setReloadOnChildSave] = useState(false);
  const [viewMode, setViewMode] = useState<"list" | "map">('list');
  const {userRegion} = useAuth();

  const regionalFirmsAsync = useAsync(() => getSPFirms(), [location, reloadOnChildSave]);

  if (regionalFirmsAsync.status === "pending" || userRegion === null) {
    return <LoadingIndicator />;
  }

  if (regionalFirmsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const regionalFirmHeaderData: T.SPFirmHeader[] = regionalFirmsAsync.value.data;
  const regionalFirmHeaders = regionalFirmHeaderData.map( header => (
    {
      ...header,
      isActive: header.isActive ? "Active" : "Inactive",
    }
  ));

  const handleViewFirmDetails = (id: T.FirmId) => {
    navigate(`/dashboard/sp-firms/${id}`)
  }

  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label="Service Provider Firms"
          tooltip="List of service provider firms under your region."
        />
        <div className="flex justify-between w-full mb-5">
          <Button 
            buttonType={"primary"} 
            buttonText={"Add New Firm"} 
            actionHandler={() => {navigate('/dashboard/sp-firms/create')}}      
          />
          <Toggle 
            label="List / Map"
            isDefaultChecked={viewMode === "map"}
            toggleActionCallback={() => setViewMode( m => m === "list" ? "map" : "list")}
          />
        </div>
        {
          viewMode === "list" &&
          <List 
            contentArray={regionalFirmHeaders as KeyValuePair[]}
            columnHeaderData={FirmListColumnHeaderData}
            detailsIdName="firmId"
            returnDetailsId={handleViewFirmDetails}
            listHighlightId={getLastPartOfPath(location.pathname)}
          /> 
        }
        {
          viewMode === "map" &&
          <BasicGoogleMap 
            center={organizations.find(org => org.name === userRegion)?.coordinates} // [TODO] ? style
            zoom={8}
            markers={regionalFirmHeaderData}
            returnDetailsId={handleViewFirmDetails}
          />
        }
      </div>
      {/* New/Details Firm Route */}
      <Outlet context={ { reloadOnOutletSave: reloadOnChildSave, setReloadOnOutletSave: setReloadOnChildSave } satisfies OutletContextType} />
    </div>
  )
}

export default FirmList;

export const FirmListColumnHeaderData = [
  {
    header: "Firm Name",
    key: "name",
  },
  {
    header: "Status",
    key: "isActive",
  },
  {
    header: "Date Joined",
    key: "dateCreated",
  }
];