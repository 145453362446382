import { useNavigate } from "react-router-dom";
import { useAsync } from "../utilities/customHooks";
import LoadingIndicator from "./LoadingIndicator";
import ErrorIndicator from "./ErrorIndicator";
import { getRegionCoachStatsAsync } from "../services/adminService";

const GrowthStats = () => {
  const navigate = useNavigate();
  const founderDetailsAsync = useAsync(() => getRegionCoachStatsAsync(), []);

  if (founderDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const { invited, inProgress, recommendation } = founderDetailsAsync.value.regionalCoachDetails;

  return (
    <div className="flex flex-col w-full">
      <h2 className="font-semibold text-2xl pb-5 w-full text-center">Growth Coach Summary</h2>
      <div className="flex flex-col w-full border-2 border-b-borders p-5">
        <button className="w-dashboardContentWidth pb-3 text-start hover:underline" onClick={() => navigate('/dashboard/admin-coach-in-progress')}>There {inProgress !== 1 ? 'are' : "is"} {inProgress} compan{inProgress !== 1 ? 'ies' : "y"} who {inProgress !== 1 ? 'are' : "is"} awaiting a Growth Coach assesment</button>
        {/* <button className="w-dashboardContentWidth pb-3 text-start hover:underline" onClick={() => navigate('/dashboard/admin-coach-premeeting')}>There {invited !== 1 ? 'are' : "is"} {invited} screened compan{invited !== 1 ? 'ies' : "y"} who {invited !== 1 ? 'have' : 'has'} been invited to meet a Growth Coach</button> */}
        <button className="w-dashboardContentWidth text-start hover:underline" onClick={() => navigate('/dashboard/admin-coach-recommendations')}>There {recommendation !== 1 ? 'are' : "is"} {recommendation} recommendation{recommendation !== 1 ? 's' : ""} from a Growth Coach awaiting Action</button>          
        </div>
    </div>
  )
}

export default GrowthStats