import Form, { FormDefinition, FormData } from "../Components/Form/Form";
import { resetPassword } from "../services/publicService";
import { LangContext, LangContextType, LoadingContext, LoadingContextType, useRequiredParams } from "../utilities/customHooks";
import { password_validator } from "../utilities/formFieldValidators"
import { useNavigate } from "react-router-dom";
import { ToastContext, ToastContextType, } from "../utilities/customHooks";
import { useContext } from "react";
import { ButtonProp } from "../Components/Button";
import { nonDashboardPageContainerStyle } from "./NotAuthorizedPage";
import ContentPageTopButtons from "../Components/ContentPageTopButtons";
import textData from "../textData.json";

function PasswordReset() {
  const { langState } = useContext(LangContext) as LangContextType

  const navigate = useNavigate();
  
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const resetToken = useRequiredParams("resetToken");
  async function handleSave(updatedForm: FormData):Promise<void> {
    const password = {password: updatedForm['password'] as string}

    showLoading();
    const response = await resetPassword(resetToken, password);
    hideLoading();

    if (response.success === true) {
      showToast({
        toastType: "success",
        message: textData.Pages.PasswordReset.SuccessToast[langState]
      })
      navigate("/login")
    }
  }

  const newPassword: FormDefinition = {
    password: { 
      label: textData.Pages.PasswordReset.NewPassword[langState],
      inputType: "text",
      validator: password_validator,
    },
    confirmPassword: {
      label: textData.Pages.PasswordReset.Confirm[langState],
      inputType: "text",
      validator: password_validator,
    },
  }

  const primaryButtonProp: ButtonProp = {
    buttonText: textData.Pages.PasswordReset.Save[langState],
    actionHandler: (updatedFormData: FormData) => handleSave(updatedFormData),
  }

  return (
    <div className={nonDashboardPageContainerStyle} >
      <ContentPageTopButtons />
      <Form               
        formData={null}
        formDefinition={newPassword}
        primaryButtonProp={primaryButtonProp}
      />
    </div>
  )
}

export default PasswordReset