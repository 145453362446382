import { useLocation, useNavigate } from "react-router-dom";
import { ConditionalRenderContext, ConditionalRenderContextType, LoadingContext, LoadingContextType, ToastContextType, useReloadOnOutletSave, useRequiredParams } from "../../../utilities/customHooks"
import { useAsync } from "../../../utilities/customHooks";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { deleteProgram, getProgramDetails, updateProgram } from "../../../services/adminService";
import * as T from "../../../utilities/frontendTypes";
import Form, { FormData, FormDefinition } from "../../../Components/Form/Form";
import { useContext, useEffect, useState } from "react";
import Button, { ButtonProp } from "../../../Components/Button";
import { programDetailsFormDefinition } from "../../../utilities/formDefinitionTemplates";
import { RangeData } from "../../../Components/Form/RangeInputFields";
import { flattenObject, handleFormSaveResponse } from "../../../utilities/helperFunctions";
import { ToastContext } from "../../../utilities/customHooks";
import List, { KeyValuePair } from "../../../Components/List";
import { programApplicationColumnHeaderData } from "./ProgramDetails";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { DetailsContentHeader, detailsContentWrapperStyle, formContainerStyle, formWrapperStyle } from "../ProfilePages/NewAdmin";
import { membershipsColumnHeaderData } from "../MembershipPages/MembersList";
import { convertFounderMembersDBStateToUIState } from "../../../utilities/stateConverters";
import Modal from "../../../Components/Modal";

function MyProgramDetails() {

  const {showToast} = (useContext(ToastContext) as ToastContextType);

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const programId = useRequiredParams("programId");
  const navigate = useNavigate();
  const [formDisabledState, setFormDisabledState] = useState(true);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reloadOnSave, setReloadOnSave] = useState(false);
  const { reloadOnOutletSave, setReloadOnOutletSave } = useReloadOnOutletSave();
  const [programDetailsRes, setProgramDetailsRes] = useState<T.ProgramDetailsResponse | null>(null);

  // Gain access conditional render state
  const conditionalRenderContext = useContext(ConditionalRenderContext) as ConditionalRenderContextType;

  // [TODO] refactor this into a conditional render state loader module that can be used across codebase
  // Update/replace conditional render state on load
  useEffect(() => {
    try {
      
      const fetchProgramDetails = async () => {
        showLoading();
        const programDetailsRes = await getProgramDetails(programId);
        hideLoading();
      
        setProgramDetailsRes(programDetailsRes);

        const applicationFormDefinitionString = (programDetailsRes.programDetails as T.ProgramDetails).applicationFormDefinition;

        if (applicationFormDefinitionString !== undefined) {
          
          // Register conditional render state
          const conditionalRenderInitialState = {} as {[x: string]: boolean};
          const applicationFormDefinitionObj = JSON.parse(applicationFormDefinitionString) as FormDefinition;
          
          Object.values(applicationFormDefinitionObj).filter(value => value.conditionalRenderDefinition).forEach(value => {
            const fieldKey = value.conditionalRenderDefinition?.fieldKey;
            if (fieldKey === undefined) throw new Error("fieldKey cannot be undefined when registering conditional render state");
            conditionalRenderInitialState[fieldKey] = false;
          })

          conditionalRenderContext.replaceConditionalRenderState(conditionalRenderInitialState);
        }
      }
      
      fetchProgramDetails().catch(console.error);
     
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line 
  }, []);

  const handleViewApplicationDetails = (id: T.ApplicationId) => {
    navigate(`/dashboard/my-service-applications/${id}`, {state: {from: location.pathname}})
  }
  const handleViewUserDetails = (id: T.UserId) => {
    navigate(`/dashboard/memberships/${id}`, {state: {from: location.pathname}})
  }

  if (programDetailsRes === null) {
    return <LoadingIndicator />;
  }

  const programDetails = programDetailsRes.programDetails;

  const flattenedProgramDetails = flattenObject(programDetails);
  const applicationFormDefinition = programDetails.applicationFormDefinition;
  const formData = {
    ...flattenedProgramDetails,
    // costRange: {
    //   low: programDetails.programData.costRangeLow,
    //   high: programDetails.programData.costRangeHigh,
    // },
    applicationFormDefinition: applicationFormDefinition || "",
  }

  const applicationHeaders = programDetailsRes.programApplicationHeaders;

  // Save updated program details
  const handleSave = async (updatedFormData: FormData) => {

    // const {low, high} = updatedFormData.costRange as RangeData;
    delete updatedFormData.costRange;
    const updatedProgram = {
      _id: programId,
      ...updatedFormData,
      // costRangeLow: low,
      // costRangeHigh: high,
    };

    showLoading();
    const response = await updateProgram(updatedProgram as T.UpdatedProgram & {_id: string});
    hideLoading();

    if (response.success) {
      setFormDisabledState(true);
      setReloadOnSave(!reloadOnSave);
      setReloadOnOutletSave(!reloadOnOutletSave)
    };
    handleFormSaveResponse(
      response, 
      showToast, 
      {message: `Service '${updatedFormData.name}' has been succesfully updated`}, 
      {message: `Service '${updatedFormData.name}' could not be updated, please try again. If you see this message again, please contact support.`}
    );
  }

  async function handleDelete() {
    showLoading();
    const response = await deleteProgram(programId);
    hideLoading();
    if (response.success) {
      navigate("/dashboard/my-services")
    } else {
      setIsModalOpen(false);
    }
    const programName = response.program.name;
    handleFormSaveResponse(
      response, 
      showToast, 
      {message: `Service '${programName}' has been succesfully deleted`}, 
      {message: `Service '${programName}' could not be deleted, please check if it has Enrolled Members or Pending Applications.`}
    );
  }
  
  const memberHeaders = convertFounderMembersDBStateToUIState(programDetailsRes.enrolledUsers);

  const primaryButtonProp: ButtonProp = {
    buttonText: 'Save',
    actionHandler: handleSave,
  }

  return (
    <div className={detailsContentWrapperStyle}>
      <ContentPageTopButtons 
        hideBackButton
        editCallback={() => setFormDisabledState(false)}
        closeCallback={() => navigate('/dashboard/my-services')}
      />
      <DetailsContentHeader label="Service Details"/>
      <div className={`${formContainerStyle} h-[75%]`}>
        <div className={formWrapperStyle}>
          <Form 
            formDefinition={programDetailsFormDefinition}
            formData={formData}
            primaryButtonProp={primaryButtonProp}
            disabled={formDisabledState}
            isViewOnly={formDisabledState}
          />
        </div>

        <h2 className="my-5 font-bold">Enrolled Members</h2>
        {
          memberHeaders.length > 0 
          ?
          <List 
            contentArray={memberHeaders as KeyValuePair[]}
            columnHeaderData={membershipsColumnHeaderData}
            detailsIdName="id" // [TODO] userId?
            returnDetailsId={handleViewUserDetails}
          />
          :
          <p>There are no users enrolled in this service</p>
        }

        {/* <h2 className="my-5 font-bold">Applications</h2>
        <List 
          contentArray={applicationHeaders as KeyValuePair[]}
          columnHeaderData={programApplicationColumnHeaderData}
          detailsIdName="id"
          returnDetailsId={handleViewApplicationDetails}
        /> */}

        <div className="flex justify-center mt-5 mb-3">
          <Button 
            buttonType={"danger"} 
            buttonText={"Delete Service"} 
            actionHandler={() => {setIsModalOpen(true)}}
          /> 
        </div>
        <Modal 
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          modalHeader="Confirm Service Deletion"
          contentLabel="Confirm if you would like to delete this service"
          primaryButton={{ buttonText: "Confirm", buttonCallback: () => handleDelete() }}
          modalHTML={
            <div className="text-text">
              <p>Are you sure you want to delete the following service:</p>
              <p className="font-bold my-5">{programDetails.name}</p>
            </div>
          }
        />
      </div>
    </div>
  )
}

export default MyProgramDetails;