import { useContext } from 'react';
import Header from '../components/Header';
import MainHero from '../components/MainHero';
import MainHeroImage from '../components/MainHeroImage';
import PopUpMessage from '../components/PopUpMessage';
import { AuthContext, AuthContextType } from '../../../utilities/customHooks';

const Home = () => {

  const { isAuthenticated } = useContext(AuthContext) as AuthContextType;

  return (
    <>
      <div className={`bg-background grid gap-y-16 overflow-hidden w-full h-full`}>
        {/* fixed at top */}
        {
          // [TODO] temporary hidden until device/broswer check logic is in place so the popUp message don't have to always display
          // !isAuthenticated &&
          // <PopUpMessage 
          //   content={
          //     `
          //       For best user experience, we recommand to use the latest version of 
          //       <a href="https://www.google.com/intl/en_ca/chrome/" target="_blank">Chrome</a> 
          //       and view on desktop only. More updates coming soon.
          //     `
          //   }
          // />
        }
        <div className={`relative bg-background flex flex-col lg:flex-row`}>
          <div className="max-w-[90rem] mx-auto">
            <div
              className={`relative z-10 pb-2 sm:pb-4 md:pb-6 lg:max-w-4xl lg:w-full lg:pb-28 xl:pb-32`}
            >
              <Header />
              <MainHero />
            </div>
          </div>
          <MainHeroImage />
        </div>
      </div>
    </>
  );
};

export default Home;
