import { useState, ChangeEvent, useContext } from 'react';
import { LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync } from '../../utilities/customHooks';
import { returnUserId } from '../../services/founderService';
import { inputClasses } from './InputField';
import Button from '../Button';
import instance from "../../middleware/HTTPInterceptors";
import LoadingIndicator from '../LoadingIndicator';
import ErrorIndicator from '../ErrorIndicator';

interface ChildComponentProps {
  handlePdfStateChange: () => void;
}

const FileUpload: React.FC<ChildComponentProps>  = ( {handlePdfStateChange} ) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const getId = useAsync(() => returnUserId(), []);
  const [uploaded, setUploaded] = useState(false)

  if (getId.status === "pending") {
    return <LoadingIndicator />;
  }

  if (getId.status === "rejected") {
    return <ErrorIndicator />;
  }

  const id = getId.value.founderId;

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.type === 'application/pdf') {
        setSelectedFile(file);
      } else {
        alert('Please select a PDF file.');
        setSelectedFile(null);
      }
    }
  };



  const handleFileUpload = async () => {

    if (!selectedFile) {
      alert('Please select a file first.');
      return;
    }

    const file = new FormData();

    if (selectedFile) {
      file.append('file', selectedFile);
      file.append('userId', id); // Add userId to form data

    showLoading(); 
    const response = await instance.post("/api/public/upload", file, {
        headers: {
        'Content-Type': 'multipart/form-data'}
    });
    hideLoading();
    if (response.status === 200) {
      handlePdfStateChange()
      showToast(
        {
          toastType: "success",
          message: "Pitch Deck Successfully Uploaded!"
        }
      )
    } else {
      showToast(
        {
          toastType: "failure",
          message: "Unable to Upload, please try again.  If the error persists please contact support."
        }
      )
    }
  }
}

  return (
    <div className='flex flex-col'>
      <input 
        className={inputClasses + " mb-2"} 
        type="file" 
        accept="application/pdf" 
        onChange={handleFileChange}
      />
      <div className='m-auto'>
        <Button 
          buttonText='Upload'
          actionHandler={handleFileUpload} 
          
          />
      </div>
    </div>
  );
};

export default FileUpload;
