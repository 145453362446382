import { useContext } from "react";
import ContentPageTopButtons from "../Components/ContentPageTopButtons";
import Toggle from "../Components/Input/Toggle";
import { DetailsContentHeader } from "./AdminPages/ProfilePages/NewAdmin";
import { contentPageContainerStyle, contentPageWrapperStyle } from "./AdminPages/ProfilePages/PartnerAdminList";
import { AuthContext, AuthContextType, LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType } from "../utilities/customHooks";
import { setCommonSettings } from "../services/userService";
import * as T from '../utilities/frontendTypes';
import textData from "../textData.json";
import { getIsOSDarkMode, getUserLanguage } from "../utilities/helperFunctions";

export default function Settings() {

  const { settings, setSettings, userRole } = useContext(AuthContext) as AuthContextType;
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const { langState } = useContext(LangContext) as LangContextType
  

  // const { langState, setLang } = useLang() as LangContextType; // [NOTE] This casting is intentional
  
  const handleThemeToggle = async (isChecked: boolean) => {

    if (settings === null) {
      const tempLang = getUserLanguage() === "Fr-fr" ? "fr" : "eng";
      const tempTheme = getIsOSDarkMode() ? "dark" : "light";
      setSettings({
        themeMode: tempTheme,
        lang: tempLang
    })
    }

    const themeMode = isChecked ? "dark" : "light";

    const updatedSettings = { ...settings, themeMode } as T.Settings;
  
    setSettings(updatedSettings)

    showLoading();
    const isReqSuccess = await setCommonSettings(updatedSettings);
    hideLoading();

    if (isReqSuccess) {
      showToast({
        toastType: "success",
        message: textData.Pages.Settings.SuccessToast[langState],
      });
    } else {
      showToast({
        toastType: "failure",
        message: textData.Pages.Settings.FailToast[langState],
      });
    }
  }

  const handleLangToggle = async (isChecked: boolean) => {
    if (settings === null) {
      const tempLang = getUserLanguage() === "Fr-fr" ? "fr" : "eng";
      const tempTheme = getIsOSDarkMode() ? "dark" : "light";
      setSettings({
        themeMode: tempTheme,
        lang: tempLang
    })
    }

    const lang = isChecked ? "fr" : "eng";

    const updatedSettings = { ...settings, lang } as T.Settings;
    setSettings(updatedSettings)

    showLoading();
    const isReqSuccess = await setCommonSettings(updatedSettings);
    hideLoading();

    if (isReqSuccess) {
      showToast({
        toastType: "success",
        message: textData.Pages.Settings.SuccessToast[langState],
      });
    } else {
      showToast({
        toastType: "failure",
        message: textData.Pages.Settings.FailToast[langState],
      });
    }
  }

  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader
          label={textData.Pages.Settings.Settings[langState]}
        />
        <div className="self-baseline mb-5">
          <Toggle 
            label={textData.Pages.Settings.DarkTheme[langState]}
            isDefaultChecked={settings?.themeMode === "dark"}
            toggleActionCallback={handleThemeToggle} 
          />
        </div>
        {userRole === "startupFounder" &&
        <div className="self-baseline">
          <Toggle 
            label={"French"}
            isDefaultChecked={settings?.lang === "fr"}
            toggleActionCallback={handleLangToggle} 
          />
        </div>
        }
      </div>
    </div>
  )
}