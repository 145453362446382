import { Outlet } from "react-router-dom";
import SideMenu from "../Components/SideMenu";
import { AuthContext, AuthContextType, LangContext, LangContextType } from "../utilities/customHooks";
import { useContext } from "react";
import LoadingIndicator from "../Components/LoadingIndicator";
import Breadcrumb from "../Components/Breadcrumb";
import textData from "../textData.json";

function Dashboard() {
  const { langState } = useContext(LangContext) as LangContextType
  const { isAuthenticated, userRole, userRegion } = useContext(AuthContext) as AuthContextType; // [NOTE] This casting is intentional

  // Make sure Dashboard and all child components only renders after auth hook completes
  if (isAuthenticated === false || userRole === null || userRegion === null) {
    return <LoadingIndicator />
  }

  const breadcrumbRoutes = [
    { path: '/', text: textData.Pages.Dashboard.Home[langState] },
    { path: '/dashboard', text: textData.Pages.Dashboard.Dashboard[langState] },
    { path: '/dashboard/profile', text: textData.Pages.Dashboard.Profile[langState] },
    { path: '/dashboard/settings', text: textData.Pages.Dashboard.Settings[langState] },

    /* Founder */
    { path: '/dashboard/founder-services', text: textData.Pages.Dashboard.Services[langState] },
    { path: '/dashboard/founder-services/:programId', text: textData.Pages.Dashboard.ServiceDetails[langState] },
    { path: '/dashboard/enrolled-services', text: textData.Pages.Dashboard.MyServices[langState] },
    { path: '/dashboard/enrolled-services/:programId', text: textData.Pages.Dashboard.MyServiceDetails[langState] },
    { path: '/dashboard/founder-applications', text: textData.Pages.Dashboard.MyApplications[langState] },
    { path: '/dashboard/help', text: textData.Pages.Dashboard.Help[langState] },
    { path: '/dashboard/faq', text: textData.Pages.Dashboard.Faq[langState] },
    { path: '/dashboard/resources', text: textData.Pages.Dashboard.Resources[langState] },


    // Service Provider Firm
    { path: '/dashboard/founder-sp-firms', text: textData.Pages.Dashboard.IPServicesFirm[langState] },
    { path: '/dashboard/founder-sp-firms/:firmId', text: textData.Pages.Dashboard.FirmDetails[langState] },
    /* Admin */
    { path: '/dashboard/founders-no-profile', text: 'Companies With No Profile' },
    { path: '/dashboard/founders-no-application', text: 'Companies With No Service Applications' },
    { path: '/dashboard/memberships', text: 'Companies' },
    { path: '/dashboard/memberships/:memberId', text: 'Companies Details' },
    { path: '/dashboard/membership-applications', text: 'Applications' },
    { path: '/dashboard/membership-applications/:applicationId', text: 'Application Details' },
    { path: '/dashboard/services', text: 'Services' },
    { path: '/dashboard/services/:programId', text: 'Service Details' },
    { path: '/dashboard/my-services', text: 'My Services' },
    { path: '/dashboard/my-services/:programId', text: 'Service Details' },
    { path: '/dashboard/my-services/new-service', text: 'New Service' },
    { path: '/dashboard/admin-expert-preinvite', text: 'Screened Companies' },
    { path: '/dashboard/admin-expert-premeeting', text: 'Pre Meeting' },
    { path: '/dashboard/admin-expert-in-progress', text: 'Meetings In Progress' },
    { path: '/dashboard/admin-expert-recommendations', text: 'IP Expert Recommendations' },
    { path: '/dashboard/admin-expert-recommendations/:memberId', text: 'Recommendation Details' },
    { path: '/dashboard/admin-dd-application', text: 'In Due Dilligence Assessments'},
    { path: '/dashboard/admin-dd-application/:memberid', text: 'Details'},
    { path: '/dashboard/admin-coach-in-progress', text: 'Awaiting Assessment'},
    
    // { path: '/dashboard/my-service-applications', text: 'My Service Applications' },
    // { path: '/dashboard/my-service-applications/:applicationId', text: 'Application Details' },
    // Super Admin
    { path: '/dashboard/admin-list', text: 'Admin List' },
    { path: '/dashboard/admin-list/new-admin', text: 'New Admin' },
    { path: '/dashboard/new-user', text: 'New User' },

    // Temp 
    { path: '/dashboard/admin-verification', text: 'Verification' },
    { path: '/dashboard/expert-verification', text: 'Verification' },
    { path: '/dashboard/provider-verification', text: 'Verification' },
    // Service Prvider Firm
    { path: '/dashboard/sp-firms', text: 'Service Provider Firms' },
    { path: '/dashboard/sp-firms/create', text: 'New Firm' },
    { path: '/dashboard/sp-firms/:firmId', text: 'Firm Details' },
    //Expert
    { path: '/dashboard/expert-assesments', text: 'Companies Awaiting Assessment'},
    { path: '/dashboard/expert-assesments/:id', text: 'Assessment Details'},
    { path: '/dashboard/in-progress-assesments', text: 'In Progress Assessments'},
    { path: '/dashboard/in-progress-assesments/:id', text: 'Assessments Details'},
    { path: '/dashboard/submitted-assesments', text: 'Submitted Assessments'},
    { path: '/dashboard/submitted-assesments/:id', text: 'Assessments Details'},
    { path: '/dashboard/in-dd-application', text: 'In Due Dilligence Assessments'},
    { path: '/dashboard/in-dd-assesments', text: 'In Due Dilligence Assessments'},
    { path: '/dashboard/admin-coach-recommendations', text: 'Growth Coach Recommendations'},
    { path: '/dashboard/admin-coach-recommendations/:memberId', text: 'Recommendation Details'},

    
    

  ]

  return (
    <div className="flex w-full h-full">
      <SideMenu />
      <div className="flex flex-col grow m-5">
        <Breadcrumb breadcrumbRoutes={breadcrumbRoutes} />
        <Outlet />
      </div>
    </div>
  )
}

export default Dashboard;