import { Fragment, useContext } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Link } from 'react-scroll';
import config from '../data/index.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';
import FrenchEnglishToggle from '../../../Components/Features/FrenchEnglishToggle';
import { AuthContext, AuthContextType, LangContext, LangContextType } from '../../../utilities/customHooks';
import Logo from './Logo';
import textData from "../../../textData.json";
import * as T from "../../../utilities/frontendTypes";
import { NavLink, useNavigate } from 'react-router-dom';
import Button from '../../../Components/Button';

const Menu = () => {
  const { authedNavigation, nonAuthedNavigation, callToAction } = config;
  const { langState, setLang } = useContext(LangContext) as LangContextType
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext) as AuthContextType;

  const navigation = isAuthenticated ? authedNavigation : nonAuthedNavigation; // [NOTE] Setup this structure in case we need to render different navigation based on auth state.

  const handleLangToggle = (langState: T.LangState) => {
    setLang(langState);
  }
  const navigate = useNavigate();

  return (
    <>
      <Popover>
        <div className="relative pt-6 px-4 sm:px-6 lg:px-10">
          <nav
            className="relative flex items-center justify-between sm:h-10 lg:justify-start"
            aria-label="Global"
          >
            <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <Logo />
                <div className="-mr-2 flex items-center md:hidden">
                  <FrenchEnglishToggle
                    langState={langState}
                    onToggleCallback={handleLangToggle}                  
                  />
                  {/* <Popover.Button
                    className={`bg-background rounded-md ml-5 p-2 inline-flex items-center justify-center text-text hover:text-text/80 hover:bg-text/10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary`}
                  >
                    <span className="sr-only">{textData.Pages.LandingPage.components.Header.Open[langState]}</span>
                    <FontAwesomeIcon className="h-6 w-6" icon={faBars} />
                  </Popover.Button> */}
                </div>
              </div>
            </div>
            <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
              <NavLink
                to={"/contact-us"}
                className={`font-semibold text-primary hover:underline`}
              >
                {textData.Pages.LandingPage.components.Header.callToAction[langState]}
              </NavLink>
              <FrenchEnglishToggle 
                langState={langState}
                onToggleCallback={handleLangToggle}                  
              />
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div
              className={`rounded-lg shadow-md bg-background ring-1 ring-black ring-opacity-5 overflow-hidden`}
            >
              <div className="px-5 pt-4 flex items-center justify-between">
                <Logo />
                <div className="-mr-2">
                  <Popover.Button
                    className={`bg-background rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary`}
                  >
                    <span className="sr-only">{textData.Pages.LandingPage.components.Header.Close[langState]}</span>
                    <FontAwesomeIcon icon={faX} className='h-6 w-6'/>
                  </Popover.Button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Link
                    spy={true}
                    active="active"
                    smooth={true}
                    duration={1000}
                    key={item.name}
                    to={item.href}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <a
                href={callToAction.href}
                className={`block w-full px-5 py-3 text-center font-medium text-primaryDark bg-secondaryLight/20 hover:bg-secondaryLight`}
              >
                {callToAction.text}
              </a>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
};

export default Menu;
