import { useNavigate } from "react-router-dom";
import { LangContext, LangContextType, useRequiredParams } from "../../../utilities/customHooks"
import { useAsync } from "../../../utilities/customHooks";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { getMembershipApplicationDetails, getfounderDocuments } from "../../../services/adminService";
import ApproveReject from "../../../Components/Features/ApproveReject"
import * as T from "../../../utilities/frontendTypes";
import FormGroup from "../../../Components/FormGroup";
import { founderProfileEvaluationFormGroupDefinition, updatedFounderProfileEvaluationFormGroupDefinition, updatedFounderProfileReadinessEvaluationFormGroupDefinition } from "../../../utilities/formDefinitionTemplates";
import { convertFounderProfileDBStateToUIState } from "../../../utilities/stateConverters";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { DetailsContentHeader, detailsContentWrapperStyle } from "../ProfilePages/NewAdmin";
import textData from "../../../textData.json";
import { useContext, useState } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, AccordionItemState } from "react-accessible-accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import CompanyNotes from "../../../Components/AdminNotes";
import PdfDownloader from '../../../Components/Form/pdfDisplay';


function MemberApplicationDetail() {
  // const { langState } = useContext(LangContext) as LangContextType
  const langState = "eng"
  const [childClickedAccordionItemIndex, setChildClickedAccordionItemIndex] = useState(0);
  const applicationId = useRequiredParams("applicationId");
  const navigate = useNavigate();

  const membershipApplicationDetailsAsync = useAsync(() => getMembershipApplicationDetails(applicationId), [applicationId]);
  const founderDocumentsAsync = useAsync(() => getfounderDocuments(applicationId), [applicationId]);


  if (membershipApplicationDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (membershipApplicationDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }


  if (founderDocumentsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderDocumentsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const docs = founderDocumentsAsync.value.docs

  const applicationDetails: T.ApplicationDetails = membershipApplicationDetailsAsync.value.applicationDetails;
  const founderProfileGroupData = convertFounderProfileDBStateToUIState(applicationDetails.profileSnapshot);
  if (founderProfileGroupData === null) {
    throw new Error ("Founder Profile Does not exist")
  }

  // Check if underrepresentedFounderGroups exists and includes specific values
  const groups = founderProfileGroupData.programingEvaluation.underrepresentedFounderGroups as string[];
  founderProfileGroupData.programingEvaluation.coPayEligible = false;  
  if (groups && (groups.includes("women") || groups.includes("indigenous"))) {
    founderProfileGroupData.programingEvaluation.coPayEligible = true;  
  }

  return (
    <div className={detailsContentWrapperStyle}>
      <ContentPageTopButtons 
        hideBackButton
        closeCallback={() => navigate('/dashboard/membership-applications')}
      />
      <DetailsContentHeader 
        label={textData.Pages.AdminPages.MembershipPages.MemberApplicationDetail.ApplicationDetails[langState]}
        tooltip={textData.Pages.AdminPages.MembershipPages.MemberApplicationDetail.ApplicationDetailsTooltip[langState]}
      />
        {
        applicationDetails.flaggedNote && 
          <div className="flex flex-col text-text w-full mb-3">      
            <p>{applicationDetails.flaggedNote}</p>
          </div>
        }
      <Accordion
        allowZeroExpanded={true}
        preExpanded={[childClickedAccordionItemIndex]}
        className="w-full overflow-y-auto pr-2"
      >
        <AccordionItem
          uuid={0}
          className="mb-10 pr-1"
          key={`company-notes`}
          onClick={() => setChildClickedAccordionItemIndex(0)}
        >
          <AccordionItemHeading>
            <AccordionItemButton
              className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
            >
              <DetailsContentHeader
                label="Company Notes"
                level="h2"
              />
              <AccordionItemState>
                {({ expanded }) => (expanded ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />)}
              </AccordionItemState>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
          <div className="flex flex-col text-text w-full mb-3">
              <CompanyNotes id={applicationDetails.userId} />
          </div>
          </AccordionItemPanel>
        </AccordionItem>
        {/* { 
        docs.length > 0 && */}
        <AccordionItem
          uuid={5}
          className="mb-10 pr-1"
          key={`Documents`}
          onClick={() => setChildClickedAccordionItemIndex(5)}
        >
          <AccordionItemHeading>
            <AccordionItemButton
              className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
            >
              <DetailsContentHeader
              label="Documents"
              level="h2"
              />
              <AccordionItemState>
                {({ expanded }) => (expanded ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />)}
              </AccordionItemState>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {
              docs[0] 
              ? 
              <PdfDownloader docKey={docs[0]}/>
              :
              <p>No Documents associated with this company</p>
            }          
            </AccordionItemPanel>
        </AccordionItem>
        {/* } */}
        {applicationDetails.applicationScore && 
        <AccordionItem
          uuid={1}
          className="mb-10 pr-1"
          key={`application-score`}
          onClick={() => setChildClickedAccordionItemIndex(0)}
        >
          <AccordionItemHeading>
            <AccordionItemButton
              className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
            >
              <DetailsContentHeader
                label="Scoring"
                // tooltip="Additional data collected specific to this service."
                level="h2"
              />
              <AccordionItemState>
                {({ expanded }) => (expanded ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />)}
              </AccordionItemState>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
          <div className="flex flex-col text-text w-full mb-3">
          {applicationDetails.ipPreScore &&
              <h2 className="text-3xl font-bold mb-3">Total Score: {applicationDetails.ipPreScore?.totalScore + applicationDetails.applicationScore?.totalScore}</h2>
          }
            <div className="flex flex-col text-text w-full mb-3">
              <p className="text-xl font-bold">Persona Score: {applicationDetails.applicationScore?.totalScore}</p>
              <ul className="ml-1">
                <li>Classification: {applicationDetails.applicationScore?.classification}</li>
                <li>Age Score: {applicationDetails.applicationScore?.age}</li>
                <li>Revenue Score: {applicationDetails.applicationScore?.revenue}</li>
                <li>Employee Score: {applicationDetails.applicationScore?.fte}</li>
              </ul>
            </div>
            {
            applicationDetails.ipPreScore &&
            <div className="flex flex-col text-text w-full mb-3">
              <p className="text-xl font-bold">IP PreScore: {applicationDetails.ipPreScore?.totalScore}</p>
              <ul className="ml-1">
                <li>IP Readiness - Patents: {applicationDetails.ipPreScore?.ipReadinessPatents}</li>
                <li>IP Readiness - General: {applicationDetails.ipPreScore?.ipReadinessGeneral}</li>
              </ul>
            </div>
            }
          </div>
          </AccordionItemPanel>
        </AccordionItem>
        }
        <AccordionItem
          uuid={applicationDetails.applicationScore && applicationDetails.reasonNote ? 3 : applicationDetails.applicationScore && !applicationDetails.reasonNote ? 2 : !applicationDetails.applicationScore && applicationDetails.reasonNote ? 2 : 1}
          className="mb-10 pr-1"
          key={`Proile`}
          onClick={() => setChildClickedAccordionItemIndex(applicationDetails.applicationScore && applicationDetails.reasonNote ? 3 : applicationDetails.applicationScore && !applicationDetails.reasonNote ? 2 : !applicationDetails.applicationScore && applicationDetails.reasonNote ? 2 : 1)}
        >
          <AccordionItemHeading>
            <AccordionItemButton
              className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
            >
              <DetailsContentHeader
              label="Profile Details"
              level="h2"
              />
              <AccordionItemState>
                {({ expanded }) => (expanded ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />)}
              </AccordionItemState>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
          <FormGroup 
            formGroupData={founderProfileGroupData} 
            formGroupDefinition={founderProfileGroupData.ipReadiness ? updatedFounderProfileReadinessEvaluationFormGroupDefinition : founderProfileGroupData.iPStrategy.seekingEducation !== undefined ? founderProfileEvaluationFormGroupDefinition : updatedFounderProfileEvaluationFormGroupDefinition}
            readOnly={true}
          />
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      {
        applicationDetails.status === "pending" && 
        <div className="mt-5">
          <ApproveReject 
            applicationId={applicationId}
            applicationType="membership"
            navigateUrl="/dashboard/membership-applications"
            // requireTierAssignment={true}
            
          />
        </div>
      }       
    </div>
  )
}

export default MemberApplicationDetail