import { useContext, useEffect, useState } from "react";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons"
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { getUninvitedDetailsAsync, sendMassEmail, massStatusUpdate } from "../../../services/adminService";
import { LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync } from "../../../utilities/customHooks";
import { DetailsContentHeader } from "../ProfilePages/NewAdmin"
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList"
import Button from "../../../Components/Button";
import Modal from "../../../Components/Modal";
import { useNavigate } from "react-router-dom";
import List from "../../../Components/List";
import { dropdownArrowClasses } from "../../../Components/Form/DropdownSingleSelect";
import { inputClasses } from "../../../Components/Form/InputField";


const PreInvite = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [personaMin, setPersonaMin] = useState<number>(2)
  const [confirmUsers, setConfirmUsers] = useState(false);
  const [selectExpert, setSelectExpert] = useState(false);
  const [expertName, setExpertName] = useState("")

  const uninvitedFoundersAsync = useAsync(() => getUninvitedDetailsAsync(), []);
  if (uninvitedFoundersAsync.status === "pending") {
    return <LoadingIndicator />;
  }
  
  if (uninvitedFoundersAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const uninvitedFoundersArray = (uninvitedFoundersAsync.value.uninvitedFoundersArray)
  console.log(uninvitedFoundersArray)

  const columnHeaderData = [
    {
      header: "Company",
      key: "companyName",
    },
    {
      header: "Persona Score",
      key: "personaScore",
    }
  ];

  const emailArrayChange = (array: string[]) => {
    let ids: string[] = []
    array.forEach(string => {
      uninvitedFoundersArray.forEach(obj => {
        if (obj.companyName === string) {
          ids.push(obj.userId);
        }
      });
    });
    setSelectedUsers(ids)
  }

  const handleConfirmation = async (emailType: string, expertName?: string) => {
    showLoading();
    const res = expertName ? await sendMassEmail(selectedUsers, emailType, expertName) : await sendMassEmail(selectedUsers, emailType);
    hideLoading();
    if (res.success) {
      showToast({
        toastType: "success",
        message: "Invitation Successfully Sent!",
      });
      navigate("/dashboard");
    } else {
      showToast({
        toastType: "failure",
        message: "Unable to send invitation, please try again.  If Error persists please conteact support",
      });
    }
  }
  
  const handleDropdownChange = (e: any) => {
    setExpertName(e.target.value);
  };

  const handleMassUpdate = async () => {
    showLoading();
    const res = await massStatusUpdate(selectedUsers, "growthCoachInProgress")
    hideLoading();
    if (res.success) {
      showToast({
        toastType: "success",
        message: "Statuses Updated",
      });
      navigate("/dashboard");
    } else {
      showToast({
        toastType: "failure",
        message: "Unable to update Statuses, lease try again.  If Error persists please conteact support",
      });
    }
  }


  const modalHTML = 
    selectExpert ?
    <div className="flex flex-col justify-center items-center">
      <div className="relative mb-5 mt-5 w-full">
        <select 
          id="dropdown" 
          value={expertName} 
          onChange={handleDropdownChange}
          className={inputClasses}
        >
          <option value="" disabled>Select an IP Expert:</option>
          <option value="alexis">Alexis Conrad</option>
          <option value="jivitesh">Jivitesh Chawla</option>
        </select>
        <span className={dropdownArrowClasses}></span>
      </div>
      <Button
        buttonText="IP Expert Invite"
        actionHandler={() => handleConfirmation("expert", expertName)}
        disabled={expertName === "" ? true : false}
      />
    </div>
    :
    <div className="flex flex-col justify-center items-center">
      <div className="mb-4">
        <Button
          buttonText="IP Expert Invite"
          actionHandler={() => setSelectExpert(true)}
        />
      </div>
      <div className="mb-4">
        <Button
          buttonText="Growth Coach Review"
          actionHandler={() => handleMassUpdate()}
        />
      </div>
      {/* <div>
        <Button
          buttonText="Growth Coach Meeting Invite"
          actionHandler={() => handleConfirmation("coach")}
        />
      </div> */}
    </div>
    

// LIST OF REMOVALABLE USERS TO CONFIRM
// confirmUsers ?
//   <div className="flex flex-col justify-center items-center">
//   {selectedUsers.map(userId => {
//     const matchingObject = uninvitedFoundersArray.find(obj => obj.userId === userId);
//     if (matchingObject) {
//       let removedUser = [...selectedUsers]
//       removedUser.filter(user => user !== matchingObject.userId)
//     return (
//       <li key={matchingObject.userId}>
//         <button onClick={() => {setSelectedUsers(removedUser)}}
//           className="text-danger pl-2">Remove</button>
//         {matchingObject.companyName}
//       </li>
//       );
//     }
// })}
//   <Button
//     buttonText="Confirm"
//     actionHandler={() => setConfirmUsers(true)}
//   />      
// </div>
  const modalClose = () => {
    setIsModalOpen(false)
    setSelectExpert(false)
  }

  const handleChange = (event: { target: { value: any; }; }) => {
    const inputValue = event.target.value;
    setPersonaMin(inputValue)
  };

  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label={"Screened Companies"}
          tooltip={"This is a list of all the companies whos application has been screened but have not been invited to meet an IP Expert or growth coach"}
        />
        <div className="w-full mb-5 flex">
          <div className="flex justify-center">
            <Button 
              buttonType="primary"
              buttonText="Email or Update Selected"
              disabled={!(selectedUsers.length > 0)}
              actionHandler={ () => {
                setIsModalOpen(true);
              }}
            />
          </div>
          <div className="flex justify-center align-cente ml-5">
            <p className="pt-2">Minimum Persona Score: </p>
            <select className="border border-terteiry rounded-md p-2 ml-2 text-text bg-background" defaultValue={personaMin} onChange={handleChange}>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1.5">1.5</option>
              <option value="2">2</option>
              <option value="2.5">2.5</option>
              <option value="3">3</option>
              <option value="3.5">3.5</option>
              <option value="4">4</option>
            </select>
          </div>
        </div>
        <div className="w-full flex flex-col overflow-y-auto">
          <List
            contentArray={uninvitedFoundersArray}
            columnHeaderData={columnHeaderData}
            emailArrayChange={emailArrayChange}
            personaMin={personaMin}
            checkbox={true}
          />
        </div>        
        <Modal 
          isOpen={isModalOpen}
          closeModal={modalClose}
          modalHeader={selectExpert ? "Which IP Expert are you inviting selected companies to meet?" : "Select Email/Status Update"}
          contentLabel=""
          modalHTML={modalHTML}
        />
      </div>
    </div>  
  )
}

export default PreInvite