import { useNavigate } from "react-router-dom"
import Button from "../../../Components/Button"
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons"
import { LangContext, LangContextType, useAsync } from "../../../utilities/customHooks";
import { getLatestMembershipApplication } from "../../../services/founderService";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import * as T from "../../../utilities/frontendTypes";
import textData from "../../../textData.json";
import { useContext } from "react";

function RejectedFounderDashboard() {
  const { langState } = useContext(LangContext) as LangContextType

  const navigate = useNavigate();

  const latestMembershipApplicationAsync = useAsync(() => getLatestMembershipApplication(), []);
  
  if (latestMembershipApplicationAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (latestMembershipApplicationAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const latestMembershipApplication = latestMembershipApplicationAsync.value.data as T.ApplicationDetails;

  const firstName = latestMembershipApplication.profileSnapshot.personalInfo?.firstName;

  return (
    <div className="flex w-full justify-center h-full">
      <div className="relative flex flex-col h-full w-dashboardContentWidth max-w-maxDashboardContentWidth">
        <div className="text-text flex flex-col items-center">
          <h1 className="text-3xl font-bold mb-4">{textData.Pages.Dashboards.FounderDashboards.RejectedFounderDashboard.Welcome[langState]} {firstName}!</h1>
          <div className="flex flex-col w-[85%] max-w-[850px]">
            <p className="mb-3 font-semibold">{textData.Pages.Dashboards.FounderDashboards.RejectedFounderDashboard.Unable[langState]}</p>
            <div className="ml-8 mb-3">
              <span className="font-bold">"{latestMembershipApplication.reasonNote}"</span>
            </div>
            <p className="mb-4">{textData.Pages.Dashboards.FounderDashboards.RejectedFounderDashboard.Help[langState]} <span className="font-semibold">elevateip@communitech.ca</span>.</p>
            {/* <Button 
              buttonType={"primary"} 
              buttonText={textData.Pages.Dashboards.FounderDashboards.RejectedFounderDashboard.ReApply[langState]}
              actionHandler={() => navigate('profile')}
              style="w-fit self-center"
            /> */}
          </div>
        </div>
          <div className="w-full flex flex-col border border-border p-4 mt-4">
            <p className=" text-center font-semibold text-lg mb-2">Empower Your IP Journey - NEW ElevateIP Learning Hub Launching Soon!</p>
            <p className="text-center">The ElevateIP Learning Hub will be your central hub for all things intellectual property (IP). Build a strong foundation with a structured knowledge module, access a wealth of resources, and stay current with on-demand webinars. Also, connect and collaborate with fellow learners in a thriving community.</p>
            <p className="font-semibold mt-2 text-center">This platform is your springboard to confidently navigate the world of IP.</p>
          </div>
      </div>
    </div>
  )
}

export default RejectedFounderDashboard