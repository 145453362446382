import Cookies from "universal-cookie"
import * as T from "../utilities/frontendTypes";
import { FieldErrors, FieldValues } from "react-hook-form";
import { Address } from "../Components/Form/AddressFields";
import { ListRowContentValue } from "../Components/List";
import { Location } from "react-router-dom";
import { ToastInfo, ToastOption } from "./customHooks";
import uniqid from 'uniqid';
import { FormDefinition, selectionData } from "../Components/Form/Form";
import { FormGroupDefinition } from "../Components/FormGroup";

export async function getAuthHeader() {

  const cookie = new Cookies();

  const token = await cookie.get("authToken");

  const authHeader = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`
  }

  return authHeader;
}

export async function getUploadAuthHeader() {

  const cookie = new Cookies();

  const token = await cookie.get("authToken");

  const authHeader = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`
  }

  return authHeader;
}

export const getRouteNameFromUserRole = (userRole: T.UserRole) => {
  switch (userRole) {
    case "partnerAdmin":
    case "superAdmin":
      return "admins";
    case "tempAdmin":
      // throw new Error("Currently temp admin should not be called to find the route name, please double check and make sure");
      return "temp-admins";
    case "serviceProvider":
      return "providers";
    case "startupFounder":
      return "founders";
    case "ipExpert":
      return "ipExpert";
    case "tempExpert":
      return "temp-experts";
    case "tempProvider":
      return "temp-providers";
    default:
      throw new Error(`Unknown user role: ${userRole}`);
  }
}

/*
  Function: isFormInvalid

  Purpose: check if form is valid or not

  Return: boolean
*/
export const isFormInvalid = (errors: FieldErrors<FieldValues>) => {
  return Object.keys(errors).length > 0;
}

/*
  Function: checkInputErrorByKey

  Purpose: check if the error object contains errors that match to a given field name (fieldKey)

  Return: A filtered error object
*/
export const checkInputErrorByKey = (errors: FieldErrors<FieldValues>, fieldKey: string) => {
  const filteredErrors: FieldErrors<FieldValues> = Object.keys(errors) 
    .filter(key => key.includes(fieldKey)) // filter out errors that belong to the fieldKey
    .reduce((curr, key) => {
      return Object.assign(curr, { error: errors[key]}) // map errors
    }, {});

  return filteredErrors;
}

/*
  Function: flattenObject

  Purpose: Flatten a multidimensional object
 
  For example:
    flattenObject{ a: 1, b: { c: 2 } }
  Returns:
    { a: 1, c: 2}
 */

export const flattenObject = <T>(obj: Record<string, any>) => {
  const flattened: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value as Record<string, any>));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};

/*
  Function: findNestedValueByKey

  Purpose: find object in a nested object (including arrays) by given key-value pair

  Return: result object
*/
export const findNestedValueByKey = (obj: Object, key: string) => {
  try {
    JSON.stringify(obj, (_, nestedValue) => {
      if (nestedValue && nestedValue[key]) throw nestedValue[key]
      return nestedValue;
    })
  } catch (result) {
    return result;
  }
}

// [TODO] add doc
export const addressToString = (address: Address): string => {
  
  const { streetNumber, streetName, unitNumber, city, province, country, postalCode} = address;

  const addressString = streetName !== "" ? `${streetNumber} ${streetName}${(unitNumber && unitNumber !== "") ? ` #${unitNumber}` : ""}, ${city}, ${province}, ${country}, ${postalCode}` : "";

  return addressString;
}

// [TODO] add doc
export type KeyValuePair = {
  [key: string]: ListRowContentValue;
}

// export const sortObjectsByKey = (
//   contentArray: KeyValuePair[], 
//   key: string, 
//   ascending?: boolean, 
//   options?: { sortOptions: ListRowContentValue[], currentOptionIndex: number }
// )  => {
//   if (options) {
//     const keyValue = options.sortOptions[options.currentOptionIndex];
//     return contentArray
//     .slice()
//     .sort((a: T.listComparison, b: T.listComparison) => {
//       if (a[key] === keyValue && b[key] !== keyValue) {
//         return -1;
//       } else if (a[key]!== keyValue && b[key] === keyValue) {
//         return 1;
//       } 
//       return 0;
//     });
//   } else {
//     return contentArray
//     .slice()
//     .sort((a: T.listComparison, b: T.listComparison) => {
//       const valueA = a[key];
//       const valueB = b[key];
//         if (valueA < valueB) {
//           return ascending ? -1 : 1;
//         } else if (valueA > valueB) {
//           return ascending ? 1 : -1;
//         }
//       return 0;
//     });
//   }
// };

export const sortObjectsByKey = (
  contentArray: KeyValuePair[], 
  key: string, 
  ascending?: boolean, 
  options?: { sortOptions: ListRowContentValue[], currentOptionIndex: number }
)  => {
  if (options) {
    const keyValue = options.sortOptions[options.currentOptionIndex];
    return contentArray
    .slice()
    .sort((a: KeyValuePair, b: KeyValuePair) => {
      if (a[key] === keyValue && b[key] !== keyValue) {
        return -1;
      } else if (a[key] !== keyValue && b[key] === keyValue) {
        return 1;
      } 
      return 0;
    });
  } else {
    return contentArray
    .slice()
    .sort((a: KeyValuePair, b: KeyValuePair) => {
      const valueA = a[key].toString().toLowerCase();
      const valueB = b[key].toString().toLowerCase();
      return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    });
  }
};

export const isStringNumeric = (str: string) => {
  return !isNaN(+str)
}

export const isObjectEmpty = (obj: Object) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

export const isFromUrls = (specificUrls: string[], location: Location) => {
  const from = location.state?.from || "/";
  return specificUrls.some(url => from.includes(url));
}


/*
  Function: handleFormSaveResponse

  Purpose:

  Return: 
*/
export const handleFormSaveResponse = (res: any, showToast: (toastOption: ToastOption) => void, successToastInfo?: ToastInfo, failureToastInfo?: ToastInfo ) => { // the 'any' here is intentional and safe

  if (typeof res.success !== "boolean") {
    throw new Error (`unknown res type: ${res}`);
  }
  if (res.success === true) {
    showToast({
      toastType: "success",
      ...successToastInfo,
    });
  } else {
    showToast({
      toastType: "failure",
      ...failureToastInfo,
    });
  }
}

export function generateId() {
  return uniqid();
};

/*
  Function: getRegionNameFromRegion

  Purpose:

  Return: 
*/
export const getRegionNameFromRegion = (region: T.UserRegion) => {

  switch (region) {
    case "communitech":
      return "Communitech";
    case "investOttawa":
      return "Invest Ottawa";
    case "northForge":
      return "North Forge";
    default:
      throw new Error(`Unknown region: ${region}`);
  }
}

/*
  Function: getIsOSDarkMode

  Purpose: detect whether the operation system is in dark mode

  Return: Boolean, true if dark mode
*/
export const getIsOSDarkMode = () => {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}


export const getUserLanguage = () => {
  return navigator.language;
}

/*
  Function: getLastPartOfPath

  Purpose: return last part of a path of a given path string

  Return: string
*/
export const getLastPartOfPath = (path: string) => {
  return path.slice(path.lastIndexOf("/") + 1, path.length);
}

/*
  Function: checkIsOnline

  Purpose: return true/false value indicating online status

  Return: boolean
*/
export const checkIsOnline = async (): Promise<boolean> => {
  try {
    if (!self.navigator.onLine) return false; // false check is reliable
    const request = new URL(self.location.origin); // same origin request to avoid CROS error
    request.searchParams.set('rand', Date.now().toString()); // random value to prevent cached responses
    const response = await fetch(request.toString(), { method: 'HEAD' });
    return response.ok;
  } catch {
    return false;
  }
}

export const returnLangCheckedFormDef = (formDefinition: FormDefinition, lang: string): FormDefinition => {
  if (lang === "fr") {
    const result: FormDefinition = {};
    for (const key in formDefinition) {
      if (formDefinition.hasOwnProperty(key)) {
        const { label, frLabel, frTooltip, tooltip, additionalData, ...otherProperties } = formDefinition[key];
  
        result[key] = {
          label: frLabel as string,
          frLabel: frLabel,
          ...otherProperties,
        };
  
        // Check if there is a corresponding frTooltip and update content if needed
        if (tooltip && frTooltip) {
          result[key].tooltip = {
            content: frTooltip.content,
            position: frTooltip.position
          }
        }
        if (additionalData) {
          const frenchData = additionalData.selectionData.map((item) => ({
            value: item.value,
            text: item.frText || item.text, // Set frText if available, otherwise use text
          }));
          result[key].additionalData = {
            selectionData: frenchData 
          }
        }
      }
    }
    return result;
  }
  return formDefinition
}

export const returnLangCheckedFormGroupDef = (formGroupDefinition: FormGroupDefinition, lang: string): FormGroupDefinition => {
  if (lang === "fr") {
    const result: FormGroupDefinition = {}
    for (const key in formGroupDefinition) {
      if (formGroupDefinition.hasOwnProperty(key)) {
        const { sectionLabel, frSectionLabel, formDefinition, ...otherProperties } = formGroupDefinition[key];
        const langCheckedFormDef = returnLangCheckedFormDef(formDefinition, lang);
        result[key] = {
          sectionLabel: frSectionLabel as string,
          frSectionLabel: frSectionLabel,
          formDefinition: langCheckedFormDef,
          ...otherProperties,
        };
      }
    }
    return result
    }
    return formGroupDefinition
  }  