import { useNavigate } from "react-router-dom";
import ContentPageTopButtons from "../../Components/ContentPageTopButtons";
import ErrorIndicator from "../../Components/ErrorIndicator";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync } from "../../utilities/customHooks";
import { getNewMemberAcceptanceStatus, getPendingApplicationHeaders, updateNewMemberAcceptanceStatus } from "../../services/adminService";
import Toggle from "../../Components/Input/Toggle";
import { useContext } from "react";
import FounderStats from "../../Components/FounderStats";
import ExpertStats from "../../Components/ExpertStats";
import GrowthStats from "../../Components/GrowthStats";

function AdminDashboard() {

  return (
    <div className="flex w-full justify-center h-full">
      <div className="relative flex flex-col h-full w-dashboardContentWidth max-w-maxDashboardContentWidth text-tex overflow-y-auto">
        <ContentPageTopButtons />
          <FounderStats />
        <div className="flex w-full">
          <div className="flex w-1/2 m-2">
            <ExpertStats />
          </div>
          <div className="flex w-1/2 m-2">
            <GrowthStats />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard