import Form, { FieldValidator, FieldValue } from "./Form";
import { addressToString, returnLangCheckedFormDef } from "../../utilities/helperFunctions";
import { useFormContext } from "react-hook-form";
import { FormEvent, useContext, useEffect, useState } from "react";
import { getAutocompleteByInput, getPlaceById } from "../../services/publicService";
import { LangContext, LangContextType, LoadingContext, LoadingContextType } from "../../utilities/customHooks";
import { disabledInputClasses, inputClasses } from "./InputField";
import Modal from "../Modal";
import { addressFormDefinition } from "../../utilities/formDefinitionTemplates";
import textData from "../../textData.json";


export type Address = {
  streetNumber: string,
  streetName: string,
  unitNumber: string | null, // null indicates no unitNumber
  city: string,
  province: string,
  postalCode: string,
  country: string,
}

export const initialAddress = {
  city: "",
  province: "",
  postalCode: "",
  unitNumber: "",
  streetName: "",
  streetNumber: "",
  country: "",
};

export type Coordinates = {
  lat: number,
  lng: number,
}

function AddressFields(
  {
    placeholder,
    name,
    value,
    setValue,
    disabled,
    readOnly,
    validator,
  }
  :
  {
    placeholder?: string,
    name: string,
    value: Address | null,
    setValue: (value: (Address & Coordinates) | null) => void,
    disabled?: boolean,
    readOnly?: boolean,
    validator?: FieldValidator,
  }
) {
  const { langState } = useContext(LangContext) as LangContextType

  useEffect(() => {
    if (!isValueLoaded && value !== null) {
      setInput(addressToString(value));
      setIsValueLoaded(true);
    }
  }, [value])

  const [isValueLoaded, setIsValueLoaded] = useState(false);

  const [input, setInput] = useState("");
  const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const { register, clearErrors, setError } = useFormContext();

  const [isModalOpen, setIsModalOpen] = useState(false);


  const updateAddressBasedOnPlace = (place: any ) => { // [TODO] Type check

    if (place.geometry === undefined || place.geometry.location === undefined) {
      throw new Error("place.geometry cannot be undefined when setting AddressFields");
    }
    
    const { lat, lng } = place.geometry.location;

    const newAddress = {
      ...initialAddress,
      lat,
      lng,
    }

    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          newAddress.streetNumber = component.long_name;
          break;
        }
        case "route": {
          newAddress.streetName = component.short_name;
          break;
        }
        case "subpremise": {
          newAddress.unitNumber = component.long_name;
          break;
        }
        case "postal_code": {
          newAddress.postalCode = component.long_name;
          break;
        }
        case "locality":
          newAddress.city = component.long_name;
          break;
        case "administrative_area_level_1": {
          newAddress.province = component.short_name;
          break;
        }
        case "country":
          newAddress.country = component.short_name;
          break;
        // [TODO] exaust the componentType and throw a default check
      }
    }

    setInput(addressToString(newAddress)) // update UI state
    setValue(newAddress); // pass to parent

    const isAddressComponentMissing = Object.entries(newAddress).some(entry => {
      return entry[0] !== "unitNumber" && entry[1] === "";
    });

    if (isAddressComponentMissing) {
      setError(name, { type: "custom", message: textData.Components.Form.AddressFields.AutoCompleteError[langState]});
      setIsModalOpen(true);
    } else {
      clearErrors(name);
    }
  }

  const handleAddressComponentsCompletion = (addressFormState: {[x: string]: FieldValue}) => {

    if (value === null) throw new Error("Address value cannot be null during address component information completion.");

    // [NOTE] using this pattern over the for...in loop pattern to avoid the "No index signature with a parameter of type 'string' was found on type 'Address'.ts(7053)" error.
    const completedAddress = Object.fromEntries(Object.entries(value).map(entry => {
      const key = entry[0];
      const value = entry[1];

      if (value === "") {
        return [key, addressFormState[key]];
      }

      return entry;
    })) as Address & Coordinates;

    setInput(addressToString(completedAddress)) // update UI state
    setValue(completedAddress); // pass to parent
    setIsModalOpen(false);
    clearErrors(name);
  }

  type PredictionData = {
    status: string,
    predictions: google.maps.places.AutocompletePrediction[],
  }

  const handleInputChange = async (e: FormEvent<HTMLInputElement>) => {

    const input = e.currentTarget.value;
    setInput(input);

    setValue(null)

    try {
      if (input !== "") {
        showLoading();
        const {status, predictions} = (await getAutocompleteByInput(input)).data as PredictionData;
        hideLoading();
        setPredictions(status === "OK" ? predictions : []);
      } else {
        setPredictions([]);
      }

    } catch (err) {
      console.log(err);
    }
  }

  const handlePredictionSelection = async (prediction: google.maps.places.AutocompletePrediction) => {
    try {
      showLoading();
      // [TODO] add type check
      const place = (await getPlaceById(prediction.place_id)).data.result as google.maps.places.PlaceResult;
      hideLoading();

      updateAddressBasedOnPlace(place);
      setPredictions([]);

    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="relative">
      {/* Address autocomplete field */}
      <input
        className={`border border-gray-300 rounded-md py-2 px-3 ${inputClasses} ${(disabled || readOnly) ? disabledInputClasses : "" }`}
        value={input}
        {
          ...register(name, {
            ...validator,
            validate: () => {

              if (value === null) {
                return textData.Components.Form.AddressFields.AddressError[langState];
              }

              const { streetNumber, streetName, postalCode, city, province, country } = value;

              // [TODO] postalCode not handled properly
              if (streetNumber === "" || streetName === "" || city === "" || province === "" || country === "") {
                return textData.Components.Form.AddressFields.CompleteAddressError[langState];
              }

              return true;
            }
          })
        }
        onChange={handleInputChange}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
      />
      {/* Address autocomplete dropdown selections */}
      {
        predictions.length > 0 &&
        // [NOTE] the hard-coded text-black is safe for phase 1 as it is always going to be black on white background.
        <div className="absolute z-10 mt-1 w-full bg-white rounded-md shadow-lg text-black">
          {predictions.map((prediction) => (
            <button 
              key={prediction.place_id} 
              onClick={() => handlePredictionSelection(prediction)}
              className="block w-full py-2 px-4 text-left hover:bg-gray-100 focus:bg-gray-100"
            >
              {prediction.description}
            </button>
          ))}
        </div>
      }
      <Modal 
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        modalHeader={textData.Components.Form.AddressFields.IncompleteInfo[langState]}
        contentLabel={textData.Components.Form.AddressFields.IncompleteInfo[langState]}
        modalHTML={
          <Form 
            formDefinition={returnLangCheckedFormDef(addressFormDefinition, langState)} 
            formData={value}   
            primaryButtonProp={{
              buttonText: textData.Components.Form.AddressFields.Complete[langState],
              actionHandler: handleAddressComponentsCompletion
            }}   
          />
        }        
      />
    </div>
  )
}

export default AddressFields;