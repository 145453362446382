import { useNavigate } from "react-router-dom";
import ContentPageTopButtons from "../../Components/ContentPageTopButtons";
import Form, { FormDefinition } from "../../Components/Form/Form";
import { LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync, useReloadOnOutletSave, useRequiredParams } from "../../utilities/customHooks";
import { DetailsContentHeader, detailsContentWrapperStyle, formContainerStyle, formWrapperStyle } from "../AdminPages/ProfilePages/NewAdmin";
import { expertAssessmentFormDefition } from "../../utilities/formDefinitionTemplates";
import Button, { ButtonProp } from "../../Components/Button";
import { handleFormSaveResponse } from "../../utilities/helperFunctions";
import { useContext, useState } from "react";
import { getInProgressAssessmentAsync, saveFounderAssessment, submitFounderAssessment, addDDFlag } from "../../services/ipExpertService";
import * as T from "../../utilities/frontendTypes";
import LoadingIndicator from "../../Components/LoadingIndicator";
import ErrorIndicator from "../../Components/ErrorIndicator";
import Modal from "../../Components/Modal";
import { basic_text_validator } from "../../utilities/formFieldValidators";


const FounderAssesmentDetails = () => {

  const navigate = useNavigate();
  const founderId = useRequiredParams("id")
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const [reload, setReload] = useState(false);
  const { reloadOnOutletSave, setReloadOnOutletSave } = useReloadOnOutletSave();
  const [tempUpdatedForm, setTempUpdatedForm] = useState<FormData | null>(null);
  const [tempUpdatedDDForm, setTempUpdatedDDForm] = useState<FormData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [duration, setDuration] = useState("");
  const [isDDflag, setIsDDFlag] = useState(false);

  const confirmModal = (updatedForm: FormData) => {
    setIsModalOpen(true)
    setTempUpdatedForm(updatedForm)
  } 

  const inProgressAssessment = useAsync(() => getInProgressAssessmentAsync(founderId), [founderId]);

  if (inProgressAssessment.status === "pending") {
    return <LoadingIndicator />;
  }

  if (inProgressAssessment.status === "rejected") {
    return <ErrorIndicator />;
  }
  const founder = inProgressAssessment.value.founder

  async function handleSave(updatedFormData: FormData) {
    const assesmentData = {
      ...updatedFormData
    } as unknown
    const assessment = assesmentData as T.ScoreBreakdown
    const meetingDuration = duration ? parseFloat(duration) : 0
    showLoading();
    const response = await submitFounderAssessment(founderId, assessment, meetingDuration)
    hideLoading();

    if (response.success) {
    handleFormSaveResponse(response, showToast, {message: "Company Assessment Submitted!"});
    setReload(!reload)
    setReloadOnOutletSave(!reloadOnOutletSave);
    navigate(founder.founderAdditionalData?.serviceData?.expertAssessment?.scoreBreakdown ? '/dashboard/in-progress-assesments' : '/dashboard/expert-assesments')
    }
  }

  async function handleTempSave(updatedFormData: FormData) {
    const assesmentData = {
      ...updatedFormData
    } as unknown
    const meetingDuration = duration ? parseFloat(duration) : 0
    const assessment = assesmentData as T.ScoreBreakdown

    showLoading();
    const response = await saveFounderAssessment(founderId, assessment, meetingDuration)
    hideLoading();

    if (response.success) {
    handleFormSaveResponse(response, showToast, {message: "Company Assessment Saved!"});
    setReload(!reload)
    setReloadOnOutletSave(!reloadOnOutletSave);
    navigate(founder.founderAdditionalData?.serviceData?.expertAssessment?.scoreBreakdown ? '/dashboard/in-progress-assesments' : '/dashboard/expert-assesments')
    }
  }

  const primaryButtonProp: ButtonProp = {
    buttonText: "Save",
    actionHandler: confirmModal,
  }

  const handleDurationChange = (event: { target: { value: any; }; }) => {
    const inputValue = event.target.value;

    // Use regular expression to remove non-numeric characters
    const newValue = inputValue.replace(/\D/g, '');

    setDuration(newValue);
  };

   const ddFlagForm: FormDefinition = {
    notes: {
      label: "Why are you flagging?",
      inputType: "textarea",
      validator: basic_text_validator,
    }
  }


  const handleAddDDFlag = async (updatedFormData: FormData) => {
    const tempNotes = updatedFormData as any
    const notes = tempNotes.notes
    showLoading();
    const response = await addDDFlag(founderId, notes)
    hideLoading();

    if (response.success) {
    handleFormSaveResponse(response, showToast, {message: "Company Flagged for Due Diligence"});
    setReload(!reload)
    setReloadOnOutletSave(!reloadOnOutletSave);
    navigate('/dashboard/in-progress-assesments')
    }
  }


  const primaryButtonPropDD: ButtonProp = {
    buttonText: "Save",
    actionHandler: handleAddDDFlag,
  }

  const modalHTML = (
    isDDflag 
    ? 
    <div className='flex flex-col text-text'>
      <Form
        formDefinition={ddFlagForm}
        formData={null}
        primaryButtonProp={primaryButtonPropDD}
      />
    </div>
    :
    <div className='flex flex-col text-text'>
      <p>Enter Meeting Duration in minutes:</p>
      <div className="mt-5 mb-5">
      <input
        className="border border-terteiry rounded-md p-2 text-text bg-background"
        type="number"
        value={duration}
        onChange={handleDurationChange}
      />
      </div>
      <p>Would you like to submit your Recommendation or save your progress?</p>
      <div className="flex w-full mt-5 justify-around">
          <Button
            actionHandler={() => handleTempSave(tempUpdatedForm as FormData)}
            buttonText="Save Progress"
            buttonType="secondary"
            disabled={duration ? false : true}
          />
          <Button
            actionHandler={() => handleSave(tempUpdatedForm as FormData)}
            buttonText="Submit Reccomendation"
            buttonType="secondary"
            disabled={duration ? false : true}
          />  
      </div>
    </div>
  )

  return (
    <div className={detailsContentWrapperStyle}>
    <ContentPageTopButtons 
      hideBackButton
      closeCallback={() => navigate('/dashboard/expert-assesments')}   
    />
    <DetailsContentHeader 
      label="Company Assessment"
    />
    <div className="mb-5 w-full flex">
      <Button
        buttonText="Flag for Further Due Diligence"
        actionHandler={() => {setIsModalOpen(true), setIsDDFlag(true)}}
      />
    </div>
    <div className={`${formContainerStyle} h-[75%]`}>
      <div className={formWrapperStyle}>
        <Form 
        formDefinition={expertAssessmentFormDefition}
        formData={founder.founderAdditionalData?.serviceData?.expertAssessment?.scoreBreakdown ? founder.founderAdditionalData?.serviceData?.expertAssessment?.scoreBreakdown : null}
        primaryButtonProp={primaryButtonProp}
        />       
      </div>
      <Modal 
        isOpen={isModalOpen} 
        closeModal={() => {setIsModalOpen(false), setIsDDFlag(false)}}
        modalHTML={modalHTML} 
        contentLabel={"Would you like to save your progress or submit your Recommendation?"}
      />
    </div>
  </div>
  )
}

export default FounderAssesmentDetails