import Form, { FormData, FormDefinition } from '../Components/Form/Form';
import { RegisterInfo } from '../services/userService';
import { AuthContext, AuthContextType, LangContext, LangContextType, LoadingContext, LoadingContextType } from '../utilities/customHooks';
import * as T from '../utilities/frontendTypes';
import { Navigate, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { termsHTMLeng, termsHTMLfr } from '../assets/dummySLA';
import { registerFormDefinition, screeningFormDefinition } from '../utilities/formDefinitionTemplates';
import { Address, Coordinates } from '../Components/Form/AddressFields';
import { addressToString, returnLangCheckedFormDef } from '../utilities/helperFunctions';
import { basic_text_validator } from '../utilities/formFieldValidators';
import LoadingIndicator from '../Components/LoadingIndicator';
import Modal from '../Components/Modal';
import { ToastContext, ToastContextType } from "../utilities/customHooks";
import { getRegionByLocation } from '../services/publicService';
import textData from "../textData.json";

// [TODO] The modal approach on needs further refactor, 
//        or consider treating Register as a special case with multiple modals 
//        if the abstraction level is too high; since the current Modal logic might have a low readability

function Register() {
  const { langState } = useContext(LangContext) as LangContextType
  const { settings, setSettings } = useContext(AuthContext) as AuthContextType;
  const navigate = useNavigate();
  const { isAuthenticated, register} = useContext(AuthContext) as AuthContextType; // [NOTE] This casting is intentional
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;
  // [NOTE] might be worth it to refactor and combine some of the states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSLAChecked, setIsSLAChecked] = useState(false);
  const [sLASigningTimestamp, setSLASigningTimestamp] = useState(new Date());
  const [modalStepIndex, setModalStepIndex] = useState(0);
  const [tempUpdatedForm, setTempUpdatedForm] = useState<FormData | null>(null);
  const [under500, setUnder500] = useState<Boolean>(true)
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const [pageStepIndex, setPageStepIndex] = useState(0);

  if (isAuthenticated) {
    return <Navigate to={'/dashboard'} />;
  }

  const handleScreening = async (screeningInfo: FormData) => {
    setUnder500(screeningInfo.under500 as boolean)
    const isCanadianControlledPrivateCorporation = screeningInfo.isCCPC;
    let tempUpdatedForm: FormData = {
      isCCPC: isCanadianControlledPrivateCorporation,
    };

    const address = screeningInfo.address as Address & Coordinates;
    const {lat, lng, province} = address;

    showLoading();
    const userRegion = await getRegionByLocation({lat, lng, province});
    hideLoading();

    tempUpdatedForm = {
      ...tempUpdatedForm,
      userRegion,
      address,
      addressString: addressToString(address), // for confirmation preview
    }

    setTempUpdatedForm(s => ({...s, ...tempUpdatedForm}));

    if (!isCanadianControlledPrivateCorporation || userRegion === "notSupported" || !screeningInfo.under500) {
      setIsModalOpen(true);
    } else {
      setPageStepIndex(c => c + 1);
    } 
  }

  const handleTempRegister = (updatedForm: FormData) => {
    setTempUpdatedForm(s => ({...s, ...updatedForm}));
    setIsModalOpen(true);
  }

  const handleRegister = async () => {

    if (tempUpdatedForm === null) throw new Error("Cannot register while tempUpdatedForm is null");
    const registerForm = {...tempUpdatedForm};
    const email = registerForm.uniqueEmail
    delete registerForm.uniqueEmail
    delete registerForm.addressString;
    delete registerForm.confirmPassword;
    delete registerForm.isCCPC;

    const registerInfo = {
      ...registerForm,
      email: (email as string),
      userRole: "startupFounder" as T.UserRole,
      settings: {
        lang: langState
      },
      sLASigningTimestamp,
    }
    showLoading();
    const response = await register(registerInfo as RegisterInfo);
    hideLoading();

    if (!response) {
      handleCloseModal(); //TODO this does not reset modal component page index
      showToast({
        toastType: "failure",
        message: textData.Pages.Register.InUse[langState],
        autoDisappearTimeInSeconds: 8,
      })
    } else {
      navigate("/dashboard");
    }
  }

  const handleSLACheck = (v: boolean) => {
    setIsSLAChecked(v)
    if (v) {
      setSLASigningTimestamp(new Date());
    }
  }

  const handleCloseModal = () => {
    setIsSLAChecked(false);
    setIsModalOpen(false);
    setModalStepIndex(0);
  }

  const langCheckedRegisterFormDefinition: FormDefinition = returnLangCheckedFormDef(registerFormDefinition, langState)

  const registerConfirmationFormDefinition: FormDefinition = {
    username: langCheckedRegisterFormDefinition['username'],
    uniqueEmail: langCheckedRegisterFormDefinition['uniqueEmail'],
    password: langCheckedRegisterFormDefinition['password'],
    addressString: {
      label: textData.Pages.Register.Address[langState],
      inputType: "text",
      validator: basic_text_validator,
    },
  }

  const isCCPC = tempUpdatedForm?.isCCPC === true;
  const isNotSupportedRegion = tempUpdatedForm?.userRegion === "notSupported";
  const isScreeningPass = isCCPC === true && isNotSupportedRegion === false && under500;

  const screeningModalHTML = (
    <div className="container mx-auto p-5 text-text">
      <section className="mb-5">
        {
          !isCCPC &&
          (
            <p className="mb-2">{textData.Pages.Register.NotCCPC1[langState]} 
            (<span className='hover:underline italic font-semibold'><a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/corporations/type-corporation.html#ccpc'>CCPC</a></span>)
            {textData.Pages.Register.NotCCPC2[langState]} 
            <br /><br />{textData.Pages.Register.NotCCPC3[langState]} </p>
          )
        }
        {
          isNotSupportedRegion &&
          (
            <>
              <h2 className="mb-2">{textData.Pages.Register.NotRegion[langState]}</h2>
              <ul className="mb-4 font-semibold">
                <li className='hover:underline'><a target='_blank' href='https://www.newventuresbc.com/accelerateip/'>New Ventures BC</a></li>
                <li className='hover:underline'><a target='_blank' href='https://innovatecalgary.com/'>Innovate Calgary</a></li>
                <li className='hover:underline'><a target='_blank' href='https://mainqc.com/intellectual-property-ip-initiatives/'>mouvement des accelerateurs d'innovation du quebec (MAIN)</a></li>
                <li className='hover:underline'><a target='_blank' href='https://www.springboardatlantic.ca/our-stories/springboard-atlantic-to-launch-atlantic-ip-initiative-as-part-of-the-government-of-canadas-elevate-ip-program/'>Springboard Atlantic</a></li>
              </ul>
            </>
          )
        }
        {
          !under500 && 
          (
            <>
              <p className="mb-2">{textData.Pages.Register.over500[langState]}</p>
            </>
          )
        }
      </section>
    </div>
  )

  const registerModalHTML = [
    <div className='flex flex-col'>
      <div className='flex flex-col justify-center align-middle mb-2'>
        <h1 className='text-4xl font-bold text-center'>{textData.Pages.Register.Title[langState]}</h1>
        <span className='text-center text-xl mt-2'>{textData.Pages.Register.Subtitle3[langState]}</span>
      </div>
      <div className='overflow-y-auto mb-4 outline' style={{ maxHeight: '22rem' }}>
      {langState === "fr" ? termsHTMLfr : termsHTMLeng }
      </div>
      <div className='w-full items-center mb-1'>
        <a className='hover:underline text-sm' href='https://cdn.communitech.ca/eip/ElevateIP+Terms+of+Service+Final.pdf' target='_blank' download>
          {textData.Pages.Register.Download[langState]}
        </a>
      </div>
      <div className='flex w-full items-center mb-1'>
        <input 
          type="checkbox" 
          checked={isSLAChecked}
          onChange={() => handleSLACheck(!isSLAChecked)}
        />
        <p className="ml-5">{textData.Pages.Register.Agree[langState]}</p>
      </div>
    </div>
    ,
    <div className='flex flex-col'>
      <div className='flex flex-col justify-center align-middle mb-5'>
        <h1 className='text-4xl font-bold text-center'>{textData.Pages.Register.Title[langState]}</h1>
        <span className='text-center text-xl mt-2'>{textData.Pages.Register.Subtitle4[langState]}</span>
      </div>
      <Form
        disabled //TODO is this intentional Bill
        formDefinition={registerConfirmationFormDefinition} 
        formData={tempUpdatedForm}
      />
    </div>
  ]

  const pageHTML = [
    <div>
    <div className='flex flex-col justify-center align-middle mb-10'>
      <h1 className='text-4xl font-bold text-center'>{textData.Pages.Register.Title[langState]}</h1>
      <span className='text-center text-xl mt-2'>{textData.Pages.Register.Subtitle[langState]}</span>
    </div>
    <Form 
      formDefinition={returnLangCheckedFormDef(screeningFormDefinition, langState)} 
      formData={null}   
      primaryButtonProp={{
        buttonText: textData.Pages.Register.Next[langState],
        actionHandler: handleScreening,
        buttonType: "primary"
      }}   
    />
    </div>
    ,
    <div className='w-full mb-10'>
      <div className='flex flex-col justify-center align-middle mb-10'>
        <h1 className='text-4xl font-bold text-center'>{textData.Pages.Register.Title[langState]}</h1>
        <span className='text-center text-xl mt-2'>{textData.Pages.Register.Subtitle2[langState]}</span>
      </div>
      <Form 
        formDefinition={langCheckedRegisterFormDefinition} 
        formData={null}  
        primaryButtonProp={{
          buttonText: textData.Pages.Register.Preview[langState],
          actionHandler: handleTempRegister,
        }}
        secondaryButtonProp={{
          buttonText: textData.Pages.Register.Login[langState],
          actionHandler: () => {navigate('/login')},
          buttonType: "secondary-inverse"
        }}     
      />
    </div>
  ]

  return (
    <div className='flex w-[600px] grow'>
      {pageHTML[pageStepIndex]}
      <Modal 
        isOpen={isModalOpen}
        closeModal={handleCloseModal}
        modalHeader={isScreeningPass ? undefined : under500 ? `${textData.Pages.Register.over500Header[langState]} ${!isCCPC ? "Canada" : "ON/MB/SK"}` : `${textData.Pages.Register.Inelligble[langState]} ${!isCCPC ? "Canada" : "ON/MB/SK"}`}
        modalHTML={isScreeningPass ? ( registerModalHTML) : screeningModalHTML}
        contentLabel={isScreeningPass ? "" : ""} // [TODO]
        primaryButton={isScreeningPass && (modalStepIndex === 1) ? {buttonText: textData.Pages.Register.Confirm[langState], buttonCallback: handleRegister} : undefined }
        nextDisabled={!isSLAChecked}
        onNextClickCallback={() => {if (modalStepIndex < registerModalHTML.length - 1 ) setModalStepIndex(i => i + 1)}}
        onPrevClickCallback={() => {if (modalStepIndex > 0) setModalStepIndex(i => i - 1)}}
      />
    </div>
  )
}

export default Register;