import { Outlet, useLocation, useNavigate } from "react-router-dom"
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { LangContext, LangContextType, useAsync, useAuth } from "../../../utilities/customHooks";
import List, { KeyValuePair } from "../../../Components/List";
import * as T from "../../../utilities/frontendTypes";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { useContext, useState } from "react";
import BasicGoogleMap from "../../../Components/BasicGoogleMap";
import { organizations } from "../../../utilities/organizationData";
import Toggle from "../../../Components/Input/Toggle";
import { contentPageContainerStyle, contentPageWrapperStyle } from "../../AdminPages/ProfilePages/PartnerAdminList";
import { DetailsContentHeader } from "../../AdminPages/ProfilePages/NewAdmin";
import { getSPFirms } from "../../../services/founderService";
import { FirmListColumnHeaderData } from "../../AdminPages/ServiceProviderPages/FirmList";
import Tooltip from "../../../Components/Tooltip";
import { getLastPartOfPath } from "../../../utilities/helperFunctions";
import textData from "../../../textData.json";

function FirmList() {
  const { langState } = useContext(LangContext) as LangContextType
  const navigate = useNavigate();
  const location = useLocation();

  const [viewMode, setViewMode] = useState<"list" | "map">('map');
  const {userRegion} = useAuth();

  const regionalFirmsAsync = useAsync(() => getSPFirms(), [location]);

  if (regionalFirmsAsync.status === "pending" || userRegion === null) {
    return <LoadingIndicator />;
  }

  if (regionalFirmsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const regionalFirmHeaderData: T.SPFirmHeader[] = regionalFirmsAsync.value.data;

  const regionalFirmHeaders = regionalFirmHeaderData.map( header => (
    {
      ...header,
      isActive: header.isActive ? "Active" : "Inactive",
    }
  ));

  const handleViewFirmDetails = (id: T.FirmId) => {
    navigate(`/dashboard/founder-sp-firms/${id}`)
  };
  
  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label={textData.Pages.FounderPages.ServiceProviderFirmPages.FirmList.Header[langState]}
          tooltip={textData.Pages.FounderPages.ServiceProviderFirmPages.FirmList.Tooltip[langState]}
        />
        <div className="flex self-end w-full mb-5">
          <Toggle
            label={textData.Pages.FounderPages.ServiceProviderFirmPages.FirmList.ListMap[langState]}
            isDefaultChecked={viewMode === "map"}
            toggleActionCallback={() => setViewMode( m => m === "list" ? "map" : "list")}
          />
          {viewMode === "map" && <Tooltip content={textData.Pages.FounderPages.ServiceProviderFirmPages.FirmList.Instruction[langState]} />}
        </div>
        {
          viewMode === "list" &&
          <List 
            contentArray={regionalFirmHeaders as KeyValuePair[]}
            columnHeaderData={FirmListColumnHeaderData}
            detailsIdName="firmId"
            returnDetailsId={handleViewFirmDetails}
            listHighlightId={getLastPartOfPath(location.pathname)}
          /> 
        }
        {
          viewMode === "map" &&
          <BasicGoogleMap 
            center={organizations.find(org => org.name === userRegion)?.coordinates} // [TODO] review and fix '?.' style
            zoom={8}
            markers={regionalFirmHeaderData}
            returnDetailsId={handleViewFirmDetails}
          />
        }
        <div className={`text-text ${viewMode === "list" && "mt-5"}`}
        >{textData.Pages.FounderPages.ServiceProviderFirmPages.FirmList.Disclaimer[langState]}</div>
      </div>
      {/* Details Firm Route */}
      <Outlet />
    </div>
  )
}

export default FirmList;