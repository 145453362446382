import { NavLink } from "react-router-dom";
import { AuthContext, AuthContextType, LangContext, LangContextType } from "../utilities/customHooks";
import { useContext } from "react";
import Logo from "../Pages/LandingPage/components/Logo";
import textData from "../textData.json";

function Header() {
  const { langState } = useContext(LangContext) as LangContextType
  const { isAuthenticated, logout } = useContext(AuthContext) as AuthContextType;

  // [TODO] The code below is an alternative approach with different style and responsiveness, keep just in-case till header style is finalized
  // const [menuToggle, setMenuToggle] = useState(false);
  // const [accountMenuToggle, setAccountMenuToggle] = useState(false);

  // const { pathname } = useLocation();
  
  return (
    <div className={`flex w-full border-b-[1px] border-b-borders justify-center mb-10 bg-primaryDark/5`}>
      <div className={`h-header capitalize text-headerFooterText font-semibold flex justify-between items-center w-contentWidth max-w-maxContentWidth`}> 
        <Logo />
        {
          isAuthenticated && <button className='hover:underline' onClick={logout}>{textData.Components.Header.Logout[langState]}</button>  
        }
        {
          !isAuthenticated &&
          <div className='flex text-text'>
            <NavLink to={'/'} className='hover:underline' >{textData.Components.Header.Home[langState]}</NavLink>
            <NavLink to={'/login'} className='ml-5 hover:underline'>{textData.Components.Header.Login[langState]}</NavLink>
            <NavLink to={'/register'} className='ml-5 hover:underline'>{textData.Components.Header.Register[langState]}</NavLink>
          </div>
        }
      </div>
    </div>
  )
}

export default Header;