import Form, { FormData, FormDefinition } from "../../../Components/Form/Form";
import { createNewSPFirm } from "../../../services/adminService";
import { useNavigate } from "react-router-dom";
import * as T from "../../../utilities/frontendTypes";
import { newServiceProviderFirmFormDefinition } from "../../../utilities/formDefinitionTemplates";
import { useContext, useState } from "react";
import { addressToString, handleFormSaveResponse } from "../../../utilities/helperFunctions";
import { LoadingContext, LoadingContextType, ToastContext, ToastContextType } from "../../../utilities/customHooks";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { DetailsContentHeader, detailsContentWrapperStyle, formContainerStyle, formWrapperStyle } from "../ProfilePages/NewAdmin";
import Modal from "../../../Components/Modal";
import { Address, Coordinates } from "../../../Components/Form/AddressFields";
import { basic_text_validator } from "../../../utilities/formFieldValidators";
import { ButtonProp } from "../../../Components/Button";


function NewServiceProviderFirm() {
  
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempUpdatedForm, setTempUpdatedForm] = useState<FormData | null>(null);

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const handleConfirmModal = (updatedForm: FormData) => {
    setIsModalOpen(true);
    setTempUpdatedForm({...updatedForm, addressString: addressToString(updatedForm.address as Address & Coordinates)}); // for confirmation preview
  } 
  
  // [TODO] add UI <=> DB state converters after Address component is figured out and upgraded with auto complete
  //        once done, combine the two saving functions on FirmDetails and NewFirm
  const handleSave = async () => {
    if (tempUpdatedForm === null) throw new Error("Cannot create service provider firm with null tempUpdatedForm data");

    const {lat, lng} = tempUpdatedForm.address as Address & Coordinates

    const newFirm = {
      ...tempUpdatedForm,
      coordinates: {
        lat,
        lng,
      }
    }
    
    showLoading();
    const response = await createNewSPFirm(newFirm as T.ServiceProviderFirm);
    hideLoading();

    if (response.success) navigate('/dashboard/sp-firms');
    handleFormSaveResponse(
      response, 
      showToast, 
      {message: `Firm '${tempUpdatedForm.name}' has been succesfully created`}, 
      {message: `Firm '${tempUpdatedForm.name}' could not be created, please try again. If you see this message again, please contact support.`}
    );
  }
  
  const previewFormDefinition: FormDefinition = {
    name: newServiceProviderFirmFormDefinition.name,
    region: newServiceProviderFirmFormDefinition.region,
    description: newServiceProviderFirmFormDefinition.description,
    websiteURL: newServiceProviderFirmFormDefinition.websiteURL,
    industriesOfFocus: newServiceProviderFirmFormDefinition.industriesOfFocus,
    languages: newServiceProviderFirmFormDefinition.languages,
    contact: newServiceProviderFirmFormDefinition.contact,
    contactEmail: newServiceProviderFirmFormDefinition.contactEmail,
    addressString: {
      label: "Service Provider Firm Address",
      inputType: "text",
      validator: basic_text_validator,
    },
    isActive: newServiceProviderFirmFormDefinition.isActive,
  }

  const modalHTML = (
    <div className='flex flex-col text-text'>
      <h1 className='text-center text-xl font-bold'>New Service Provider Firm Preview</h1>
      <Form
        isViewOnly
        formDefinition={previewFormDefinition}
        formData={tempUpdatedForm}
      />
    </div>
  )

  const primaryButtonProp: ButtonProp = {
    buttonText: "Preview & Create",
    actionHandler: handleConfirmModal,
  }

  return (
    <div className={detailsContentWrapperStyle}>
      <ContentPageTopButtons 
        hideBackButton
        closeCallback={() => navigate('/dashboard/sp-firms')}
      />
      <DetailsContentHeader label="Add New Firm"/>
      <div className={formContainerStyle}> 
        <div className={formWrapperStyle}>
          <Form 
            formData={null}
            formDefinition={newServiceProviderFirmFormDefinition}
            primaryButtonProp={primaryButtonProp}
            disabled={false}
          />
          <Modal 
            isOpen={isModalOpen} 
            closeModal={() => setIsModalOpen(false)}
            modalHTML={modalHTML}
            contentLabel={"New Service Provider Firm confirmation preview modal"}
            primaryButton={{buttonText: "Confirm", buttonCallback: () => handleSave()}}
          />
        </div>
      </div>
    </div>
  )
}

export default NewServiceProviderFirm;