import Form, { FormData, FormDefinition } from "../../../Components/Form/Form";
import { deleteSPFirm, getSPFirmDetailsById, updateSPFirm } from "../../../services/adminService";
import { useNavigate } from "react-router-dom";
import * as T from "../../../utilities/frontendTypes";
import { newServiceProviderFirmFormDefinition } from "../../../utilities/formDefinitionTemplates";
import { useContext, useState } from "react";
import { flattenObject, handleFormSaveResponse } from "../../../utilities/helperFunctions";
import { LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync, useReloadOnOutletSave, useRequiredParams } from "../../../utilities/customHooks";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { DetailsContentHeader, detailsContentWrapperStyle, formContainerStyle, formWrapperStyle } from "../ProfilePages/NewAdmin";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import { Address } from "../../../Components/Form/AddressFields";
import { basic_number_validator, basic_text_validator, boolean_selection_validator, dash_number_validator } from "../../../utilities/formFieldValidators";
import Button, { ButtonProp } from "../../../Components/Button";
import Modal from "../../../Components/Modal";

function FirmDetails() {
  
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const navigate = useNavigate();
  const [formDisabledState, setFormDisabledState] = useState(true);
  const [reloadOnSave, setReloadOnSave] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { reloadOnOutletSave, setReloadOnOutletSave } = useReloadOnOutletSave();

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const firmId = useRequiredParams("firmId");
  const firmDetailsAsync = useAsync(() => getSPFirmDetailsById(firmId), [firmId, reloadOnSave]);

  if (firmDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (firmDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const firmDetails: T.IServiceProviderFirm & {_id: T.FirmId} = firmDetailsAsync.value.data;

  const { createdAt, description, websiteURL, name, industriesOfFocus, languages, contact, contactEmail, isActive, address, _id, coordinates, region } = firmDetails;
  const { lng, lat } = coordinates;
  const flattenedAddress = flattenObject(address);

  delete flattenedAddress._id;

  const firmDetailsFormData: FormData = {
    description,
    region,
    name,
    websiteURL,
    industriesOfFocus,
    languages,
    contact,
    contactEmail,
    isActive,
    dateCreated: createdAt,
    lng,
    lat,
    ...flattenedAddress,
  }
  
  // [TODO] add UI <=> DB state converters after Address component is figured out and upgraded with auto complete
  //        once done, combine the two saving functions on FirmDetails and NewFirm
  const handleSave = async (formData: FormData) => {
    if (formData === null) throw new Error("Cannot create service provider firm with null FormData");

    const address: Address = {
      city: formData.city as string,
      streetNumber: formData.streetNumber as string,
      streetName: formData.streetName as string,
      unitNumber: formData.unitNumber as string | null,
      province: formData.province as string,
      postalCode: formData.postalCode as string,
      country: formData.country as string,
    }

    const updatedFirmData = {
      ...formData,
      address,
      firmId: _id,
    }
    
    showLoading();
    const response = await updateSPFirm(updatedFirmData as T.ServiceProviderFirm & { firmId: T.FirmId });
    hideLoading();

    setFormDisabledState(true);
    setReloadOnSave(!reloadOnSave);
    setReloadOnOutletSave(!reloadOnOutletSave)
    handleFormSaveResponse(
      response, 
      showToast, 
      {message: `Firm '${formData.name}' has been succesfully updated`}, 
      {message: `Firm '${formData.name}'could not be updated, please try again. If you see this message again, please contact support.`}
    );
  }

  const handleDelete = async () => {
    showLoading();
    const response = await deleteSPFirm(_id);
    hideLoading();

    if (response.success) {
      navigate("/dashboard/sp-firms")
    } else {
      setIsModalOpen(false);
    }
    handleFormSaveResponse(
      response, 
      showToast, 
      {message: `Service provider firm '${name}' has been succesfully deleted`}, 
      {message: `Service provider firm '${name}' could not be deleted, please check if it is still active.`}
    );
  }

  const modifiedSPFirmFormDefinition: FormDefinition = {...newServiceProviderFirmFormDefinition};
  delete modifiedSPFirmFormDefinition.address;

  // [TODO] refactor out the addressString approach after Address UI component refactor
  const updateServiceProviderFirmFormDefinition: FormDefinition = {
    ...modifiedSPFirmFormDefinition,
    isActive: {
      label: "Is Firm Active?",
      inputType: "boolean",
      validator: boolean_selection_validator,
    },
    streetNumber: {
      label: "Street Number",
      inputType: "text",
      validator: basic_text_validator,
    },
    streetName: {
      label: "Street Name",
      inputType: "text",
      validator: basic_text_validator,
    },
    unitNumber: {
      label: "Unit Number",
      inputType: "text",
      validator: {
        ...basic_text_validator,
        required: false
      }
    },
    postalCode: {
      label: "Postal Code",
      inputType: "text",
      validator: basic_text_validator,
    },
    city: {
      label: "City",
      inputType: "text",
      validator: basic_text_validator,
    },
    province: {
      label: "Province",
      inputType: "text",
      validator: basic_text_validator,
    },
    country: {
      label: "Country",
      inputType: "text",
      validator: basic_text_validator,
    },
    lng: {
      label: "Longitude",
      tooltip: {
        content: "If you updated firm address manually, please make sure lng/lat coordinates matches the new address for more accurate marker display on map view.",
        position: "top-left"
      },
      inputType: "number",
      validator: dash_number_validator,
    },
    lat: {
      label: "Latitude",
      tooltip: {
        content: "If you updated firm address manually, please make sure lng/lat coordinates matches the new address for more accurate marker display on map view.",
        position: "top-left"
      },
      inputType: "number",
      validator: dash_number_validator,
    }
  }

  const primaryButtonProp: ButtonProp = {
    buttonText: "Save",
    actionHandler: handleSave,
  }

  return (
    <div className={detailsContentWrapperStyle}>
      <ContentPageTopButtons 
        hideBackButton
        editCallback={() => setFormDisabledState(false)}
        closeCallback={() => navigate('/dashboard/sp-firms')}
      />
      <DetailsContentHeader label="Firm Details"/>
      <div className={formContainerStyle}> 
        <div className={formWrapperStyle}>
          <Form
            formData={firmDetailsFormData}
            formDefinition={updateServiceProviderFirmFormDefinition}
            primaryButtonProp={primaryButtonProp}
            disabled={formDisabledState}
          />
        </div>
        <div className="flex justify-center mt-5">
          <Button 
            buttonType={"danger"} 
            buttonText={"Delete Firm"} 
            actionHandler={() => {setIsModalOpen(true)}}
          /> 
        </div>
        <Modal 
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          modalHeader="Confirm Service Provider Firm Deletion"
          contentLabel="Confirm if you would like to delete this firm"
          primaryButton={{ buttonText: "Confirm", buttonCallback: () => handleDelete() }}
          modalHTML={
            <div className="text-text">
              <p>Are you sure you want to delete the following Service Provider Firm:</p>
              <p className="font-bold my-5">{name}</p>
            </div>
          }
        />
      </div>
    </div>
  )
}

export default FirmDetails;