import { dropdownArrowClasses } from "../Form/DropdownSingleSelect";
import { disabledInputClasses, inputClasses } from "../Form/InputField";

export default function DropdownSelect(
  {
    placeholder,
    name,
    selections,
    allowCustomValue,
    value,
    setValue,
    disabled,
    readOnly,
  }
  :
  {
    placeholder?: string,
    name: string,
    selections: {value: string, text: string}[],
    allowCustomValue: boolean,
    value: string | boolean,
    setValue: (value: string | boolean) => void,
    disabled?: boolean,
    readOnly?: boolean,
  }
) {

  const selectionsHTML = selections.map( (selection, index) => {
    return <option value={selection.value} key={index}>{selection.text}</option>;
  });

  const datalistOptionsHTML = selections.map( (selection, index) => {
    return <option value={selection.text} key={index}/>
  })

  const processedValue = typeof value === "boolean" ? (value ? "yes" : "no") : value as string; // [TODO] temp solution for handling boolean selection

  return (
    allowCustomValue
    ?
    <>
      <input 
        className={`${inputClasses} ${(disabled || readOnly) ? disabledInputClasses : "" }`}
        list="option-list" 
        id={name}
        value={value as string}
        onChange={e => setValue(e.currentTarget.value)}
      />
      <datalist id="option-list">
        <option value={''} disabled hidden>{placeholder}</option>
        { datalistOptionsHTML }
      </datalist>  
    </>
    :
    <div className="relative">
      <select
        className={`${inputClasses} ${(disabled || readOnly) ? disabledInputClasses : "" }`}
        id={name}
        value={processedValue}
        onChange={e => setValue(e.currentTarget.value)}
      >
        <option value={''} disabled hidden>{placeholder}</option>
        { selectionsHTML }
      </select>
      {
        (!disabled && !readOnly)
        &&
        <span className={dropdownArrowClasses}></span>
      }
    </div>
  )
}