import { useContext, useState } from "react";
import parseStringToHTML from "html-react-parser";
import textData from "../textData.json";
import { LangContext, LangContextType } from "../utilities/customHooks";

function DotIndicator( 
  {
    type,
    hoverText
  }
  : 
  {
    type: "active" | "inactive" | "disabled"
    hoverText?: string
  } 
) {
  const { langState } = useContext(LangContext) as LangContextType
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const defaultOption = () => {
    switch (type) {
			case "active":
				return (
					{
						style: "bg-success/80",
						text: textData.Components.DotIndicator.Active[langState]
					}
				);
			case "inactive":
				return (
					{
						style: "bg-danger/80",
						text: textData.Components.DotIndicator.Inactive[langState]
					}
				);
      case "disabled":
        return (
          {
            style: "bg-terteiry/80",
						text: textData.Components.DotIndicator.Locked[langState]
          }
        );
      default:
        throw new Error(`Unknown dot type: ${type}`);
    }
  };

  return (
    <div
      className="relative ml-2 flex items-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`w-2 h-2 rounded-full ${defaultOption().style}`}></div>
      {showTooltip && (
        <div className="
          font-semibold [&>a]:underline [&>a]:text-text/70 [&>a]:hover:text-text
          absolute bg-backgrounds text-text text-sm p-2 rounded shadow top-[8px] left-[12px] z-10 w-fit w-max-[250px]
          border border-lightGray/50
          "
        >
          {parseStringToHTML(hoverText ? hoverText : defaultOption().text)}
        </div>
      )}
    </div>
  );
};

export default DotIndicator;
