import ReactModal from 'react-modal';
import Button, { ButtonType } from './Button';
import IconButton from './IconButton';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState } from 'react';
import textData from "../textData.json";
import { LangContext, LangContextType } from "../utilities/customHooks";

type ActionButton = {
  buttonText: string,
  buttonCallback: () => void, // the 'any' here is intentional
  buttonType?: ButtonType,
}

export const basicModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '300px',
    width: 'fit-content',
    maxWidth: '800px',
    maxHeight: '80%',
    background: "background",
    text: "text/100"
  },
};

function Modal(
  {
    isOpen,
    closeModal,
    modalHeader,
    modalHTML,
    contentLabel, // To improve accessibility
    primaryButton,
    secondaryButton,
    style,
    nextDisabled,
    onNextClickCallback,
    prevDisabled,
    onPrevClickCallback,
  }
  :
  {
    isOpen: boolean,
    closeModal: () => void,
    modalHeader?: string,
    modalHTML: JSX.Element | JSX.Element[],
    contentLabel: string,
    primaryButton?: ActionButton,
    secondaryButton?: ActionButton,
    style?: ReactModal.Styles,
    nextDisabled?: boolean,
    onNextClickCallback?: () => void,
    prevDisabled?: boolean,
    onPrevClickCallback?: () => void,
  }
) {
  const { langState } = useContext(LangContext) as LangContextType
  const isMultiplePages = Array.isArray(modalHTML);
  const [pageIndex, setPageIndex] = useState(0);
  const handleCloseModal = () => {
    closeModal();
    setPageIndex(0);
  }
  
  const handleNextClick = () => {
    setPageIndex(i => i + 1);
    if (onNextClickCallback) onNextClickCallback();
  }

  const handlePrevClick = () => {
    setPageIndex(i => i - 1);
    if (onPrevClickCallback) onPrevClickCallback();
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      onAfterClose={() => setPageIndex(0)}
      contentLabel={contentLabel}
      style={style}
    >
      <div className='flex flex-col h-full'>
        {/* top right button to close modal */}
        <div className='flex w-fit self-end'>
          <IconButton icon={faXmark} actionCallback={handleCloseModal} />
        </div>
        
        {/* main modal content with optional header */}
        <div className='flex flex-col'>
          {modalHeader && <h1 className="text-2xl font-bold mb-4 text-center">{modalHeader}</h1>}
          { isMultiplePages ? modalHTML[pageIndex] : modalHTML }
        </div>
        {/* button controls */}
        <div className='relative flex justify-center h-[36px]'>
          {/* PREV button to go back to the previous page */}
          { isMultiplePages && pageIndex > 0 && 
            <div className='absolute left-0 bottom-1/2 translate-y-1/2'>
              <Button
                buttonType='tertiary' 
                buttonText={textData.Components.Modal.Prev[langState]}  
                actionHandler={handlePrevClick}
                disabled={prevDisabled}
              />
            </div>
          }
          {primaryButton && <Button buttonType='primary' buttonText={primaryButton.buttonText} actionHandler={() => primaryButton.buttonCallback()} /> }

          {/* secondary button */}
          { secondaryButton && <Button buttonType='secondary' buttonText={secondaryButton.buttonText} actionHandler={() => secondaryButton.buttonCallback()} /> }
          {/* NEXT button to go to the the next page */}
          { isMultiplePages && pageIndex < modalHTML.length - 1 && 
          
            <div className='absolute right-0 bottom-1/2 translate-y-1/2'>
              <Button 
                buttonType='primary' 
                buttonText={textData.Components.Modal.Next[langState]} 
                actionHandler={handleNextClick}
                disabled={nextDisabled} 
              />
            </div>
          }
        </div>
      </div>
    </ReactModal>
  )
}

export default Modal;

Modal.defaultProps = {
  style: basicModalStyles,
};
