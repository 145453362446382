import { useContext, useState } from 'react'
import { contentPageContainerStyle, contentPageWrapperStyle } from '../AdminPages/ProfilePages/PartnerAdminList'
import ContentPageTopButtons from '../../Components/ContentPageTopButtons'
import { DetailsContentHeader } from '../AdminPages/ProfilePages/NewAdmin'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, AccordionItemState } from "react-accessible-accordion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import textData from "../../textData.json";
import { LangContext, LangContextType } from '../../utilities/customHooks';

export const Resources = () => {
  const [childClickedAccordionItemIndex, setChildClickedAccordionItemIndex] = useState(0);
  const { langState } = useContext(LangContext) as LangContextType


  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader
          label={textData.Pages.FounderPages.Resources.Resources[langState]}
        />
          <div className="self-baseline w-full overflow-y-auto">
            <Accordion
            allowZeroExpanded={true}
            className="w-full overflow-y-auto pr-2"
            >    
              <AccordionItem
                uuid={0}
                className="mb-10 pr-1"
                key={`general`}
                onClick={() => setChildClickedAccordionItemIndex(0)}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
                     >
                      <h2 className='font-bold'>
                        {textData.Pages.FounderPages.Resources.General[langState]}
                      </h2>
                      <AccordionItemState>
                      {({ expanded }) => (expanded ? <FontAwesomeIcon className="ml-5" icon={faChevronDown} /> : <FontAwesomeIcon className="ml-5" icon={faChevronRight} />)}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className='overflow-y-auto'>
                    <ul className="mb-8">
                      <li className="mb-4"><p><a className="underline" href="https://entrepreneurs.utoronto.ca/ip-education/" target="_blank">{textData.Pages.FounderPages.Resources.link1[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc1[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://michelsonip.com/resource-hub/" target="_blank">{textData.Pages.FounderPages.Resources.link2[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc2[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.wipo.int/edocs/pubdocs/en/wipo_pub_450_2020.pdf" target="_blank">{textData.Pages.FounderPages.Resources.link3[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc3[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://ised-isde.canada.ca/site/canadian-intellectual-property-office/en/ip-academy/discover-intellectual-property" target="_blank">{textData.Pages.FounderPages.Resources.link4[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc4[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://ised-isde.canada.ca/site/canadian-intellectual-property-office/en/plan-success-build-ip-strategy/plan-success-build-ip-strategy" target="_blank">{textData.Pages.FounderPages.Resources.link5[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc5[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://ised-isde.canada.ca/site/canadian-intellectual-property-office/en/turning-ideas-money-commercialize-your-ip" target="_blank">{textData.Pages.FounderPages.Resources.link6[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc6[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://ised-isde.canada.ca/site/canadian-intellectual-property-office/en/plan-your-ip-strategy-guide-canadian-intellectual-property-office" target="_blank">{textData.Pages.FounderPages.Resources.link7[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc7[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.wipo.int/sme/en/checklist.html" target="_blank">{textData.Pages.FounderPages.Resources.link8[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc8[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://ised-isde.canada.ca/site/canadian-intellectual-property-office/en/ip-toolbox" target="_blank">{textData.Pages.FounderPages.Resources.link9[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc9[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://ised-isde.canada.ca/site/canadian-intellectual-property-office/en/search-intellectual-property-databases" target="_blank">{textData.Pages.FounderPages.Resources.link10[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc10[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.wipo.int/portal/en/index.html#services" target="_blank">{textData.Pages.FounderPages.Resources.link11[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc11[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://michelsonip.com/resource-hub/intellectual-property-lean-canvas/" target="_blank">{textData.Pages.FounderPages.Resources.link12[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc12[langState]}</p></li>
                    </ul>
                  </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem
                uuid={1}
                className="mb-10 pr-1"
                key={`topic`}
                onClick={() => setChildClickedAccordionItemIndex(0)}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
                     >
                      <h2 className='font-bold'>
                        {textData.Pages.FounderPages.Resources.Topic[langState]}
                      </h2>
                      <AccordionItemState>
                      {({ expanded }) => (expanded ? <FontAwesomeIcon className="ml-5" icon={faChevronDown} /> : <FontAwesomeIcon className="ml-5" icon={faChevronRight} />)}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className='overflow-y-auto'>                    
                    <ul className="mb-8">
                      <li className="mb-4"><p><a className="underline" href="https://www.wipo.int/patents/en/" target="_blank">{textData.Pages.FounderPages.Resources.link13[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc13[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.wipo.int/trademarks/en/" target="_blank">{textData.Pages.FounderPages.Resources.link14[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc14[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.wipo.int/copyright/en/" target="_blank">{textData.Pages.FounderPages.Resources.link15[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc15[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.wipo.int/designs/en/" target="_blank">{textData.Pages.FounderPages.Resources.link16[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc16[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.wipo.int/geo_indications/en/" target="_blank">{textData.Pages.FounderPages.Resources.link17[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc17[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.wipo.int/tradesecrets/en/" target="_blank">{textData.Pages.FounderPages.Resources.link18[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc18[langState]}</p></li>
                    </ul>
                  </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem
                uuid={2}
                className="mb-10 pr-1"
                key={`underrepresented`}
                onClick={() => setChildClickedAccordionItemIndex(0)}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
                     >
                      <h2 className='font-bold'>
                        {textData.Pages.FounderPages.Resources.Underrepresented[langState]}
                      </h2>
                      <AccordionItemState>
                      {({ expanded }) => (expanded ? <FontAwesomeIcon className="ml-5" icon={faChevronDown} /> : <FontAwesomeIcon className="ml-5" icon={faChevronRight} />)}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className='overflow-y-auto'> 
                    <ul className="mb-8">
                      <li className="mb-4"><p><a className="underline" href="https://ised-isde.canada.ca/site/intellectual-property-strategy/en/indigenous-peoples-and-intellectual-property" target="_blank">{textData.Pages.FounderPages.Resources.link19[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc19[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.wipo.int/tk/en" target="_blank">{textData.Pages.FounderPages.Resources.link20[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc20[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.wipo.int/women-and-ip/en/" target="_blank">{textData.Pages.FounderPages.Resources.link21[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc21[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.wipo.int/diplomatic-conferences/en/genetic-resources/index.html" target="_blank">{textData.Pages.FounderPages.Resources.link22[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc22[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://ipic.ca/what-is-ip/indigenous-traditional-knowledge" target="_blank">{textData.Pages.FounderPages.Resources.link23[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc23[langState]}</p></li>
                    </ul>
                  </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem
                uuid={3}
                className="mb-10 pr-1"
                key={`paid`}
                onClick={() => setChildClickedAccordionItemIndex(0)}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
                     >
                      <h2 className='font-bold'>
                        {textData.Pages.FounderPages.Resources.Paid[langState]}
                      </h2>
                      <AccordionItemState>
                      {({ expanded }) => (expanded ? <FontAwesomeIcon className="ml-5" icon={faChevronDown} /> : <FontAwesomeIcon className="ml-5" icon={faChevronRight} />)}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className='overflow-y-auto'>
                    <ul className="mb-8">
                      <li className="mb-4"><p><a className="underline" href="https://ipic.ca/courses-events" target="_blank">{textData.Pages.FounderPages.Resources.link24[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc24[langState]}</p></li>
                    </ul>
                  </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem
                uuid={4}
                className="mb-10 pr-1"
                key={`videos`}
                onClick={() => setChildClickedAccordionItemIndex(0)}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
                     >
                      <h2 className='font-bold'>
                        {textData.Pages.FounderPages.Resources.Videos[langState]}
                      </h2>
                      <AccordionItemState>
                      {({ expanded }) => (expanded ? <FontAwesomeIcon className="ml-5" icon={faChevronDown} /> : <FontAwesomeIcon className="ml-5" icon={faChevronRight} />)}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className='overflow-y-auto'>
                    <ul className="mb-8">
                      <li className="mb-4"><p><a className="underline" href="https://youtu.be/M3Uzw57GNXo" target="_blank">{textData.Pages.FounderPages.Resources.link25[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc25[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.youtube.com/playlist?list=PLDE5rhcpiAt_GDTYORzG11FAVhrHzkEjE" target="_blank">{textData.Pages.FounderPages.Resources.link26[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc26[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.youtube.com/playlist?list=PLDE5rhcpiAt_ALuo1G3wK3MeOxJcdRY5f" target="_blank">{textData.Pages.FounderPages.Resources.link27[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc27[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.youtube.com/playlist?list=PLDE5rhcpiAt9sPp4suMpA6ClVQt6BUQuu" target="_blank">{textData.Pages.FounderPages.Resources.link28[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc28[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://drive.google.com/file/d/1aV1-0JUX6BOE6m2y8Nt_IfL-jWZOCnOT/view?ts=657c96ab" target="_blank">{textData.Pages.FounderPages.Resources.link29[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc29[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.youtube.com/watch?v=FQCUOeGJqzA" target="_blank">{textData.Pages.FounderPages.Resources.link30[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc30[langState]}</p></li>
                      <li className="mb-4"><p><a className="underline" href="https://www.youtube.com/watch?v=u3VWWuwlxDo" target="_blank">{textData.Pages.FounderPages.Resources.link31[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc31[langState]}</p></li>
                    </ul>
                  </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem
                uuid={5}
                className="mb-10 pr-1"
                key={`audio`}
                onClick={() => setChildClickedAccordionItemIndex(0)}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className="pb-2 mb-5 border-b-[1px] border-text flex justify-between min-w-[270px] items-center"
                     >
                      <h2 className='font-bold'>
                        {textData.Pages.FounderPages.Resources.Audio[langState]}
                      </h2>
                      <AccordionItemState>
                      {({ expanded }) => (expanded ? <FontAwesomeIcon className="ml-5" icon={faChevronDown} /> : <FontAwesomeIcon className="ml-5" icon={faChevronRight} />)}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className='overflow-y-auto'>
                    <ul className="mb-8">
                      <li className="mb-4"><p><a className="underline" href="https://www.wipo.int/podcasts/en/ip-matters/index.html" target="_blank">{textData.Pages.FounderPages.Resources.link32[langState]}</a><br></br>{textData.Pages.FounderPages.Resources.desc32[langState]}</p></li>
                    </ul>
                  </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
      </div>
    </div>
  )
}
