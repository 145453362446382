import * as T from "../utilities/frontendTypes";
import { Tag } from "./Tag";
import Dot from "./DotIndicator";

function Card(
  {
    program,
    onClickCallback,
    isViewOnly,
  }
  :
  {
    program: T.AdminProgram,
    onClickCallback: (id: string) => void,
    isViewOnly?: boolean,
  }
) {

  const { _id, name, description, status, programData } = program;
  const { reportingCategory } = programData;

  return (
    <button
      onClick={() => onClickCallback(_id)}
      disabled={isViewOnly}
      className={`flex items-start flex-col border border-borders p-5 mb-5 mr-5 transition-colors duration-200 w-[250px] capitalize
        ${!isViewOnly ? "hover:bg-text/5 cursor-pointer" : "cursor-not-allowed"} 
      `}
    >
      <div className="mb-2 flex">
        
        <h3 className="text-xl font-bold text-left">{name}</h3>
        <Dot type={isViewOnly ? "disabled" : status} />
      </div>
      <Tag text={reportingCategory} type="categoryTag" />
      <p className="line-clamp-5 text-left mb-2 text-ellipsis max-w-[210px]">{description}</p>
    </button>
  )
    
}

export default Card;