import { Outlet, useNavigate } from "react-router-dom";
import { OutletContextType, useAsync, useReloadOnOutletSave } from "../../utilities/customHooks";
import LoadingIndicator from "../../Components/LoadingIndicator";
import ErrorIndicator from "../../Components/ErrorIndicator";
import * as T from "../../utilities/frontendTypes";
import ContentPageTopButtons from "../../Components/ContentPageTopButtons";
import { contentPageContainerStyle, contentPageWrapperStyle } from "../AdminPages/ProfilePages/PartnerAdminList";
import { DetailsContentHeader } from "../AdminPages/ProfilePages/NewAdmin";
import List, { KeyValuePair } from "../../Components/List";
import { getFounderHeaders, getInProgressFounderHeaders, getSubmittedFounderHeaders } from "../../services/ipExpertService";
import { useState } from "react";

const Submitted = () => {

  const langState = "eng"
  const navigate = useNavigate();
  const [reloadOnOutletSave, setReloadOnOutletSave] = useState(false);

  const founderAssementHeaders = useAsync(() => getSubmittedFounderHeaders(), [reloadOnOutletSave]);

  if (founderAssementHeaders.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderAssementHeaders.status === "rejected") {
    return <ErrorIndicator />;
  }

  const founderHeaders = [...founderAssementHeaders.value.founders];
  const handleViewFounderDetails = (id: T.UserId) => {
    navigate(`/dashboard/submitted-assesments/${id}`);
  }

  const columnHeaderData = [
    {
      header: "Company",
      key: "companyName"
    },
    {
      header: "Assessment Submitted",
      key: "date"
    }
  ];
  
  return (
    <div className={contentPageContainerStyle}>
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label="Assessments You Have Submitted"
          tooltip="These are Companies you have submitted an Assessment for"
        />
        <List 
          contentArray={founderHeaders as KeyValuePair[]}
          columnHeaderData={columnHeaderData}
          detailsIdName="id"
          returnDetailsId={handleViewFounderDetails}
        />  
      </div>
      {/* Founder details route */}
      <Outlet context={ { reloadOnOutletSave, setReloadOnOutletSave } satisfies OutletContextType} />
    </div>
  );
}

export default Submitted