import { Coordinates } from './Form/AddressFields';
import { useContext, useMemo, useState } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF, InfoWindowF } from '@react-google-maps/api';
import * as T from "../utilities/frontendTypes";
import { addressToString, getRegionNameFromRegion } from '../utilities/helperFunctions';
import LoadingIndicator from './LoadingIndicator';
import { AuthContext, AuthContextType, LangContext, LangContextType } from '../utilities/customHooks';
import textData from "../textData.json";

export default function BasicGoogleMap (
  {
    center,
    zoom,
    markers,
    returnDetailsId,
  }
  :
  {
    center?: Coordinates,
    zoom?: number,
    markers: T.SPFirmHeader[],
    returnDetailsId: (id: string) => void,
  }
) {
  const { langState } = useContext(LangContext) as LangContextType
  const { settings } = useContext(AuthContext) as AuthContextType;

  const mapOptions = {
    center: {
      lat: 43.450896,
      lng: -80.49791979999999,
    },
    zoom: 10,
  };

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY; // protected and restricted google maps API key, should try to remain hidden as much as possible
  if (!googleMapsApiKey) throw new Error ("Maps API Key not found in environment");
  const { isLoaded } = useJsApiLoader({ // make sure 'google' is loaded and ready to use
    id: 'google-map-script',
    googleMapsApiKey,
  })

  const containerStyle = {
    width: '100%',
    height: '100%',
    marginBottom: '1.25rem'
  };

  type MapOptions = google.maps.MapOptions;

  const [hoveringFirmId, setHoveringFirmId] = useState(''); // for hovering over markers
  const memoizedCenter = useMemo<google.maps.LatLngLiteral>(() => (center || mapOptions.center), [center, mapOptions.center]); // prevent accidental centering on unintended rerender
  const options = useMemo<MapOptions>(
    () => ({
      mapId: settings?.themeMode === "dark" ? "6dbb72a7510f6fb9" : "e5bcc833c8ce47fc", // [NOTE] map id, safe to expose
      disableDefaultUI: true,
      clickableIcons: false,
    }),
    [settings?.themeMode]
  );

  return (
    isLoaded 
    ? 
    (
      // The map
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={memoizedCenter}
        zoom={zoom || mapOptions.zoom}
        options={options}
      >
        {
          // Markers on the map
          markers.map( (markerData) => {
            const { position, firmId, name, isActive, address } = markerData;
            return (
              <MarkerF
                position={position} 
                key={firmId}
                onClick={() => returnDetailsId(firmId)}
                onMouseOver={() => setHoveringFirmId(firmId)}
                onMouseOut={() => setHoveringFirmId('')}
              >
                {
                  // Each Marker shows an InfoWindow when hovered
                  hoveringFirmId === firmId &&
                  <InfoWindowF
                    position={position}
                  >
                    <div className='text-black'>
                      <h2 className='font-semibold mb-1'>{name}</h2>
                      <h4 className='mb-1'>{`${isActive ? textData.Components.BasicGoogleMap.Active[langState] : textData.Components.BasicGoogleMap.Inactive[langState]}`}</h4>
                      <p>{addressToString(address)}</p>
                    </div>
                  </InfoWindowF>
                }
              </MarkerF>
            )
          })
        }
      </GoogleMap>
    ) 
    : 
    <LoadingIndicator />
  )
};