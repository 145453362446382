import { useContext, useEffect } from "react";
import textData from "../../../textData.json";
import { AuthContext, AuthContextType, LangContext, LangContextType } from "../../../utilities/customHooks";
import communitechLight from '../../../assets/Communitech-Logo_Inverse-Horiz.png'
import communitechDark from '../../../assets/Communitech-Logo_Primary-Horiz.png'
import nfDark from '../../../assets/North Forge - Primary Logo-CMYK.png'
import nfLight from '../../../assets/North Forge - Alt Logo - White.png'
import ioDark from '../../../assets/Invest-Ottawa-Logo-Colour-RGB-Transparent.png'
import ised from '../../../assets/ISEDLogoTemp.png'
import ioLight from '../../../assets/io-light.png'
import baseTheme from "../../../Theme/base";
import darkTheme from "../../../Theme/dark";
import { getIsOSDarkMode } from "../../../utilities/helperFunctions";

export default function Footer() {
  const { langState } = useContext(LangContext) as LangContextType
  const { isAuthenticated, settings } = useContext(AuthContext) as AuthContextType; // [NOTE] This casting is intentional


  const themeMode = settings?.themeMode;
  let theme = baseTheme;
  switch(themeMode) {
    case "light":
      theme = baseTheme;
      break;
    case "dark":
      theme = darkTheme;
      break;
    default: // undefined
      theme = getIsOSDarkMode() ? darkTheme : baseTheme; // if the Setting object is not set yetm apply theme based on OS setting
  }

  return (
    <div className="bg-background flex flex-col">
      <div className="bg-background text-text/50 w-full mt-5 text-left">
        {textData.Pages.LandingPage.components.Footer[langState]}
      </div>
      <div className="bg-background flex flex-col">
        <div className="bg-background text-text text-2xl font-bold w-full mt-5 text-left">
          {textData.Pages.LandingPage.components.Footer2[langState]}
        </div>
        <div className="mt-5 bg-background flex justify-between sm:max-w-xxl sm:mx-auto items-center">
          <img
            className="w-1/4 h-auto"
            // src={theme === darkTheme ? communitechLight : communitechDark} removed until multiple themes implemented
            src={communitechDark}
            alt="Communitech Logo"
          />
          <img
            className="w-1/4 h-auto"
            // src={theme === darkTheme ? nfLight : nfDark} removed until multiple themes implemented
            src={nfDark}
            alt="North Forge Logo"
          />
          <img
            className="w-1/5 h-auto"
            // src={theme === darkTheme ? ioLight : ioDark} removed until multiple themes implemented
            src={ioDark}
            alt="Invest Ottawa Logo"
          />
        </div>
        <div>
        <img
            className="m-auto mt-4 max-w-[400px]"
            src={ised}
            alt="ISED Logo Text"
          />
        </div>
      </div>
    </div>
  )
}
  