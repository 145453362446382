import { useNavigate } from "react-router-dom";
import { useAsync } from "../../utilities/customHooks";
import LoadingIndicator from "../../Components/LoadingIndicator";
import ErrorIndicator from "../../Components/ErrorIndicator";
import { getExpertAssesmentStats } from "../../services/ipExpertService";

const IpExpertDashboard = () => {
  const navigate = useNavigate();
  const founderDetailsAsync = useAsync(() => getExpertAssesmentStats(), []);

  if (founderDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const stats = founderDetailsAsync.value.stats;

  return (
    <div className="flex flex-col w-full">
      <h2 className="font-semibold text-2xl pb-5 w-full text-center">Your Companies</h2>
      <div className="flex flex-col w-full border-2 border-b-borders p-5">
        <button className="w-dashboardContentWidth pb-3 text-start" onClick={() => navigate('/dashboard/in-dd-assesments')}>You have {stats.dueDiligence} assesment{stats.dueDiligence !== 1 && 's'} awaiting due diligence</button>
        <button className="w-dashboardContentWidth pb-3 text-start" onClick={() => navigate('/dashboard/in-progress-assesments')}>You have {stats.inProgress} in progress assesment{stats.inProgress !== 1 && 's'}</button>
        <button className="w-dashboardContentWidth text-start" onClick={() => navigate('/dashboard/submitted-assesments')}>You have {stats.submitted} submitted assesment{stats.inProgress !== 1 && 's'}</button>
      </div>
    </div>
  )
}

export default IpExpertDashboard