import * as T from '../utilities/frontendTypes';
import { tempAdminSideMenuData, adminSideMenuData, providerSideMenuData, founderSideMenuData, superAdminSideMenuData, ipExpertSideMenuData, tempExpertSideMenuData, tempProviderSideMenuData } from '../utilities/data';
import { faArrowRightFromBracket, faFileText, faGear, faHandsHelping, faQuestion } from '@fortawesome/free-solid-svg-icons';
import SideMenuNavButton from './SideMenuNavButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from "react";
import { AuthContext, AuthContextType, LangContext, LangContextType } from '../utilities/customHooks';
import { useNavigate } from 'react-router-dom';
import textData from "../textData.json";

export default function SideMenu() {
  const { langState } = useContext(LangContext) as LangContextType
  const { userRole, logout, userRegion, memberApplicationStatus } = useContext(AuthContext) as AuthContextType;
  const navigate = useNavigate();

  const getMenuDataSet = (): T.MenuData[] => {
    
    switch (userRole) {
      case "partnerAdmin":
        return adminSideMenuData;
      case "tempAdmin":
        return tempAdminSideMenuData;
      case "tempExpert":
        return tempExpertSideMenuData;
        case "tempProvider":
          return tempProviderSideMenuData;
      case "superAdmin":
        return superAdminSideMenuData;
      case "serviceProvider":
        return providerSideMenuData;
      case "startupFounder":
        return founderSideMenuData;
      case "ipExpert":
        return ipExpertSideMenuData;
      default:
        throw new Error('Unknown user role');
    }
  }

  // [TODO] This is hard coding nested menu; support nested menu for more than two layers if time
  const menuHTML = getMenuDataSet().map( (menuData)  => {
    const disabled = menuData.inaccessibleTo?.includes(memberApplicationStatus);
    const { text, link, icon, tooltipContent, frText, children, frTooltipContent } = menuData;
    const childrenMenuHTML = 
    <div className='ml-[26px]' key={menuData.text}>
      {children?.map( (childMenuData) => {
        const { text, link, icon, tooltipContent, frText } = childMenuData;
        return (
          <SideMenuNavButton key={text} link={link} text={frText && langState === "fr" ? frText : text} icon={icon} disabled={disabled} tooltipContent={tooltipContent}/>
        )
      })}
    </div>

    return (
      <div className='flex flex-col' key={text}>
        <SideMenuNavButton link={link} text={frText && langState === "fr" ? frText : text} icon={icon} disabled={disabled} tooltipContent={frTooltipContent && langState === "fr" ? frTooltipContent : tooltipContent}/>
        {
          children && childrenMenuHTML
        }
      </div>
    )
  })

  return (
    <div
      className='bg-backgroundSecondary text-text h-full flex flex-col w-sideMenuWidth border-r border-r-highlight/20
                justify-between items-center min-w-minSideMenuWidth'
    >
      <div className='flex flex-col w-[calc(100%-20px)]'>
        <div className='mx-[10px] my-[20px]'>
          <button 
            onClick={() => {navigate('/dashboard')}}
          >
            <h1 className='font-semibold text-4xl text-primary'>{textData.Components.SideMenu.Dashboard[langState]}</h1>
          </button>
          {/* {
            (userRole === "tempAdmin" || userRole === "partnerAdmin" || userRole === "superAdmin")
            &&
            <p className='pt-1 text-sm text-highlight'>{userRole} @ {userRegion}</p>
          } */}
        </div>
        <div className='flex flex-col w-full self-center '>
          {menuHTML}
        </div>
      </div>
      <div className='p-iconPadding w-full'>
        {userRole === "startupFounder" && 
          <SideMenuNavButton link='resources'text={textData.Components.SideMenu.Resources[langState]} icon={faFileText} /> 
        }
        {userRole === "startupFounder" && 
          <SideMenuNavButton link='faq'text={"FAQ"} icon={faQuestion} /> 
        } 
        <SideMenuNavButton link='settings' text={textData.Components.SideMenu.Settings[langState]} icon={faGear} />
        {userRole === "startupFounder" && 
          <SideMenuNavButton link='help'text={textData.Components.SideMenu.Help[langState]} icon={faHandsHelping} /> 
        }
        <button 
          className='flex flex-col w-full self-center mb-3'
          onClick={logout}
        >
          <div className='w-full flex hover:bg-text/10 hover:cursor-pointer transition-all rounded-sm p-iconPadding items-center'>
            <FontAwesomeIcon className='w-iconWidth h-iconHeight pr-iconPadding' icon={faArrowRightFromBracket} />
            {textData.Components.SideMenu.Logout[langState]}
          </div>
        </button>
      </div>
    </div>
  )
}