import axios, { AxiosError } from "axios";
import Cookies from "universal-cookie";
import { refreshAccessToken } from "../services/userService";


interface ErrorResponseData {
  error: string;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_ENV_URL, 
  timeout: 5000000,
  withCredentials: true,
  params: {} // enable adding params later in the config
});

instance.interceptors.response.use(
  (res) => {
    // success, return res right a way
    return res;
  }, 
  async (err: AxiosError) => {

    const originalRequest = err.config;

    if (err.response && err.response.status === 302) {

      type RedirectResponseData = {
        message: string,
        redirect: string,
      };
      // [TODO] code below may cause render issue
      window.history.pushState({}, "Login", `${(err.response.data as RedirectResponseData).redirect}`);
      window.location.reload();
    }

    if (err.response && err.response.status === 401 && err.response.data) {
      
      const resError = (err.response.data as ErrorResponseData).error;

      if (resError === "Token Expired") {
        const cookie = new Cookies();
        cookie.remove("authToken", { path: '/dashboard'} );
        cookie.remove('authToken', { path: '/' });
        const newAccessToken = await refreshAccessToken();

        if (originalRequest === undefined) {
          throw new Error ("originalRequest cannot be undefined when retry request");
        }

        // Retry the original request with the new access token
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return instance(originalRequest);
      }

      if (resError === 'Token Blacklisted') {
        const cookie = new Cookies();
        cookie.remove("authToken", { path: '/dashboard'} );
        cookie.remove('authToken', { path: '/' });
        // [TODO] code below may cause render issue
        window.history.pushState({}, "Login", "/login");
        window.location.reload();
      }
    }

    if (err.response && err.response.status === 403 && err.response.data) {
      
      const resError = (err.response.data as ErrorResponseData).error;

      if (resError === "Refresh token expired") {
        const cookie = new Cookies();
        cookie.remove("authToken", { path: '/dashboard'} );
        cookie.remove('authToken', { path: '/' });
        cookie.remove("refreshToken");
        window.history.pushState({}, "Login", "/login");
        window.location.reload();
      }
      
      // [TODO] handle the "Forbidden" case 
    }

    // Handle server errors
    if (err.response && err.response.status === 500 && err.response.data) {
      
      const resError = (err.response.data as ErrorResponseData).error;

      // handle request server timeout 
      if (resError === "Request timed out") {
        alert("The request was timed out. Please try again later or contact support at elevateip@communitech.ca")
      } else {
        alert("An error has occured on the server-side. We are working hard to bring it back online, please stay tuned!")
      } 
    }

    // [TODO] better handle res error
    return Promise.reject(err);
  }
)

export default instance;