import { Link, useLocation, useParams } from "react-router-dom";
import { isObjectEmpty } from "../utilities/helperFunctions";
import textData from "../textData.json";
import { useContext } from "react";
import { LangContext, LangContextType } from "../utilities/customHooks";

type Route = {
  path: string,
  text: string,
}

function Breadcrumb (
  { 
    breadcrumbRoutes 
  }
  :
  {
    breadcrumbRoutes: Route[]
  }
) {
  const { langState } = useContext(LangContext) as LangContextType
  const { pathname } = useLocation();
  const params = useParams();

  const getBreadcrumbName = (path: string, params: any) => {
    let pathWithParamProp = path;
    if (!isObjectEmpty(params)) {
      // Replace actual values with path parameters
      for (const key in params) {
        pathWithParamProp = path.replace(params[key], `:${key}`);
      }
    }

    const matchedRoute = breadcrumbRoutes.find((route) =>
      route.path === pathWithParamProp
    );

    return matchedRoute?.text || "";
  };

  return (
    <nav className="text-sm font-medium text-text/70">
      <ol className="list-none p-0 flex">
        <li className="flex items-center hover:underline hover:text-text/80">
          <Link to="/">{textData.Components.Breadcrumb.Home[langState]}</Link>
          <span className="mx-2">/</span>
        </li>
        {
          // create an array of paths that doesn't include the initial /, then map through each path
          pathname.split('/').filter(path => path).map((_path, index, pathsArray) => {
            const isLast = index === pathsArray.length - 1;
            const routePath = `/${pathsArray.slice(0, index + 1).join('/')}`;
            const breadcrumbName = getBreadcrumbName(routePath, params);

            return (
              <li
                key={routePath}
                className={`flex items-center ${isLast ? "text-text" : "text-text/70 hover:underline hover:text-text/80" }`}
              >
                {!isLast ? (
                  <Link to={routePath}>{breadcrumbName}</Link>
                ) : (
                  <span>{breadcrumbName}</span>
                )}
                {!isLast && <span className="mx-2">/</span>}
              </li>
            );
          })
        }
      </ol>
    </nav>
  );
};

export default Breadcrumb;
