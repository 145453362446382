import { useNavigate } from "react-router-dom";
import Form, { FormDefinition, FormData } from "../Components/Form/Form";
import { email_validator } from "../utilities/formFieldValidators";
import { LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType, } from "../utilities/customHooks";
import { sendPwResetEmail } from "../services/publicService";
import { useContext } from "react";
import { handleFormSaveResponse } from "../utilities/helperFunctions";
import { ButtonProp } from "../Components/Button";
import textData from "../textData.json";

function ForgotPassword() {
  const { langState } = useContext(LangContext) as LangContextType

  const navigate = useNavigate();
  
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  async function passwordResetEmail (updatedForm: FormData) {
    const emailForm = {email: updatedForm['email'] as string} // [TODO] simplify

    showLoading();
    const response = await sendPwResetEmail(emailForm);
    hideLoading();

    if (response.success) navigate("/login");
    handleFormSaveResponse(
      response,
      showToast,
      {message: `${textData.Pages.ForgotPassword.SuccessToast[langState]} ${emailForm.email}`, autoDisappearTimeInSeconds: 5},
      {message: `${textData.Pages.ForgotPassword.FailToast[langState]} ${emailForm.email}`, autoDisappearTimeInSeconds: 5}
    )
  }
   
  const getEmail: FormDefinition = {  
    email: {
      label: textData.Pages.ForgotPassword.Email[langState],
      inputType: "text",
      validator: email_validator,
    }
  }

  const primaryButtonProp: ButtonProp = {
    buttonText: textData.Pages.ForgotPassword.Reset[langState],
    actionHandler: passwordResetEmail,
  }

  return (
    <div className='flex flex-col w-[500px] grow mt-10'>
      <Form 
        formDefinition={getEmail} 
        formData={null}
        primaryButtonProp={primaryButtonProp}
      />
    </div>
  )
}

export default ForgotPassword;