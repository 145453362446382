import { useContext, useEffect } from "react";
import { LangContext, LangContextType, ToastContext, ToastContextType, ToastType } from "../utilities/customHooks";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import textData from "../textData.json";
import { LangState } from "../utilities/frontendTypes";

export default function Toast() {
  const toastContext = useContext(ToastContext) as ToastContextType;

  const { toastState, hideToast } = toastContext;

  const { isShow, toastType, title, message, autoDisappearTimeInSeconds } = toastState;
  const { langState } = useContext(LangContext) as LangContextType

  const defaultToastInfo = getDefaultToastInfoByType(toastType, langState);

  useEffect(() => {
    let autoDisappear: NodeJS.Timeout | null = null;
    if (isShow) {
      autoDisappear = setInterval(() => {
        hideToast();
      }, (autoDisappearTimeInSeconds || defaultToastInfo.autoDisappearTimeInSeconds) * 1000);
    }
    return () => {
      if (autoDisappear) clearInterval(autoDisappear);
    };
  }, [toastState]);

  return (
    <div 
      className={
        `
          ${defaultToastInfo.style}
          ${toastState.isShow ? '-translate-y-5 opacity-1 z-50' : 'opacity-0 -z-50'}
          transition-all duration-300 ease-in-out max-w-[300px]
          border p-5 shadow-md absolute right-5 bottom-0 rounded-md text-text
        `
      }
    >
      <div className="flex justify-between mb-2">
        <h2 className="font-bold capitalize">{title || defaultToastInfo.title}</h2>
        <button
          tabIndex={isShow ? 0 : -1}
          onClick={hideToast}
        >
          <FontAwesomeIcon className="hover:cursor-pointer" icon={faXmark} />
        </button>
      </div>
      <p>{message || defaultToastInfo.message}</p>
    </div>
  )
}

type ToastInfo = {
  title: string,
  message: string,
  style: string,
  autoDisappearTimeInSeconds: number,
}

const getDefaultToastInfoByType = (toastType: ToastType, langState: LangState): ToastInfo => {
  const defaultToastInfo = {title: "", message: "", style: "", autoDisappearTimeInSeconds: -1};
  switch(toastType) {
    case "success":
      defaultToastInfo.title = textData.Components.Toast.Success[langState];
      defaultToastInfo.message = textData.Components.Toast.SuccessMessage[langState];
      defaultToastInfo.style = "bg-success text-text border-terteiry/80";
      defaultToastInfo.autoDisappearTimeInSeconds = 3;
      break;
    case "info":
      defaultToastInfo.title = textData.Components.Toast.Note[langState];
      defaultToastInfo.message = textData.Components.Toast.NoteMessage[langState];
      defaultToastInfo.style = "text-text bg-info border-terteiry/80";
      defaultToastInfo.autoDisappearTimeInSeconds = 5;
      break;

    case "failure":
      defaultToastInfo.title = textData.Components.Toast.Failure[langState];
      defaultToastInfo.message = textData.Components.Toast.FailureMessage[langState];
      defaultToastInfo.style = "text-text bg-danger border-terteiry/80";
      defaultToastInfo.autoDisappearTimeInSeconds = 8;
      break;
    default:
      throw new Error(`Unknown toastType: ${toastType}`);
  }
  return defaultToastInfo;
}