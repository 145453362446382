import Form, { FormData } from "../Components/Form/Form";
import { helpFormDefition } from "../utilities/formDefinitionTemplates";
import { ButtonProp } from "../Components/Button";
import { useContext } from "react";
import { LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType } from "../utilities/customHooks";
import { useNavigate } from "react-router-dom";
import { handleFormSaveResponse, returnLangCheckedFormDef } from "../utilities/helperFunctions";
import { sendHelpEmail } from "../services/founderService";
import textData from "../textData.json";

const Help = () => {
  const { langState } = useContext(LangContext) as LangContextType

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const navigate = useNavigate();
  
  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  async function submitHelpRequest (updatedForm: FormData) {
    const emailDetails = {
      subject: updatedForm['subject'] as string,
      message: updatedForm['message'] as string
      
    }
    showLoading();
    const response = await sendHelpEmail(emailDetails);
    hideLoading();
    
    if (response.success) navigate("/login");
      handleFormSaveResponse(
        response,
        showToast,
        {message: textData.Pages.Help.SuccessToast[langState], autoDisappearTimeInSeconds: 5},
        {message: textData.Pages.Help.FailToast[langState], autoDisappearTimeInSeconds: 5}
      )
    }
  const primaryButtonProp: ButtonProp = {
    buttonText: textData.Pages.Help.Submit[langState],
    actionHandler: submitHelpRequest,
  }
  return (
    <div className="flex flex-col w-[500px] grow items-center self-center justify-center">
      <p className="mb-5 text-text text-center">{textData.Pages.Help.Assistance[langState]}</p>
      <Form 
        formDefinition={returnLangCheckedFormDef(helpFormDefition, langState)} 
        formData={null}
        primaryButtonProp={primaryButtonProp}
      />
    </div>  
  )
}

export default Help