import { useNavigate } from "react-router-dom";
import { contentPageWrapperStyle } from "../Pages/AdminPages/ProfilePages/PartnerAdminList"
import { useAsync } from "../utilities/customHooks";
import LoadingIndicator from "./LoadingIndicator";
import ErrorIndicator from "./ErrorIndicator";
import { getRegionExpertStatsAsync } from "../services/adminService";

const ExpertStats = () => {
  const navigate = useNavigate();
  const founderDetailsAsync = useAsync(() => getRegionExpertStatsAsync(), []);

  if (founderDetailsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderDetailsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const { uninvited, inProgress, preMeeting, awaitingApproval } = founderDetailsAsync.value.regionalExpertDetails;

  return (
    <div className="flex flex-col w-full">
      <h2 className="font-semibold text-2xl pb-5 w-full text-center">IP Expert Summary</h2>
      <div className="flex flex-col w-full border-2 border-b-borders p-5">
        <button className="w-dashboardContentWidth pb-3 text-start hover:underline" onClick={() => navigate('/dashboard/admin-expert-premeeting')}>There {preMeeting !== 1 ? 'are' : "is"} {preMeeting} screened compan{preMeeting !== 1 ? 'ies' : "y"} who {preMeeting !== 1 ? 'have' : 'has'} been invited but {preMeeting !== 1 ? 'have' : 'has'} not yet met with an IP Expert</button>
        <button className="w-dashboardContentWidth pb-3 text-start hover:underline" onClick={() => navigate('/dashboard/admin-expert-in-progress')}>There {inProgress !== 1 ? 'are' : "is"} {inProgress} compan{inProgress !== 1 ? 'ies' : "y"} who have met with an IP Expert at least once but for whom no recommendation has yet been made</button>
        <button className="w-dashboardContentWidth text-start hover:underline" onClick={() => navigate('/dashboard/admin-expert-recommendations')}>There {awaitingApproval !== 1 ? 'are' : "is"} {awaitingApproval} recommendation{awaitingApproval !== 1 ? 's' : ""} from an IP Expert that are awaiting approval</button>          
        </div>
    </div>
  )
}

export default ExpertStats