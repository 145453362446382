import { useFormContext } from "react-hook-form";
import { isStringNumeric } from "../../utilities/helperFunctions";
import { FieldValidator } from "./Form";
import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { checkEmail } from "../../services/publicService";
import { LangContext, LangContextType, LoadingContext, LoadingContextType } from "../../utilities/customHooks";
import textData from "../../textData.json";

export default function InputField(
  {
    name,
    placeholder,
    value,
    setValue,
    disabled,
    readOnly,
    validator,
    validate,
  }
  :
  {
    name: string,
    placeholder?: string,
    value: string | number,
    setValue: (value: string | number) => void,
    disabled?: boolean,
    readOnly?: boolean,
    validator: FieldValidator,
    validate?: (value: string) => string | undefined, 
  }
) {
  const { langState } = useContext(LangContext) as LangContextType

  const { register, clearErrors, setError } = useFormContext();
  
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const fieldType = validator.fieldType;

  const [showPassword, setShowPassword] = useState(false); // State for password visibility; only being used when fieldType is password

  // solution to the issue where react-form-hook always show required error on pre-populated fields
  useEffect(() => {
    register(name, validator);
    if (value) {
      clearErrors(`${name}`);
      register(`${name}`, { required: false });
    }
  }, [value]);

  const isDisplayLowerCase = fieldType === "uniqueEmail" || fieldType === "email";

  const handleInputChange = (e: any) => { // Intentional any type
    const newValue = e.currentTarget.value;
    if (fieldType === "number" && !isStringNumeric(newValue)) return;
    if (fieldType === "uniqueEmail") {
      clearErrors('duplicateEmail')
    }
    setValue(isDisplayLowerCase ? (newValue as string).toLocaleLowerCase() : newValue);

  }

  const checkDuplicateEmail = async (email: string | number) => {
    if (email) {
      showLoading();
      const response = await checkEmail(email as string);
      hideLoading();
      if (!response.success) {
        setError('uniqueEmail', { type: 'custom', message: textData.Components.Form.InputField.EmailInUse[langState] });
      } 
      else if (response.success) {
        clearErrors('duplicateEmail')
      }
    }
  }

  return (
    <div className="relative flex items-center">
      <input
        disabled={disabled || readOnly}
        type={fieldType === "password" ? (showPassword ? "text" : fieldType) : fieldType}
        value={value}
        placeholder={placeholder}
        id={name}
        className={`${inputClasses} w-full ${(disabled || readOnly) ? disabledInputClasses : "" }`}
        readOnly={readOnly}
        {
          ...register(name, {
            onChange: e => handleInputChange(e),
            validate: validate,
            ...(name === 'uniqueEmail' ? { onBlur: () => checkDuplicateEmail(value)} : {}),
          })
        }
      />
      { 
        fieldType === "password" &&
        <button
          className="absolute right-[0.5rem] hover:cursor-pointer"
          onClick={ () => setShowPassword(!showPassword) }
        >
          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className="w-[15px]"/>
        </button>
      }
    </div>
  )
}

export const inputClasses = "border border-terteiry rounded-md p-2 w-full text-text bg-background"
export const disabledInputClasses = "!border-terteiry/20 !cursor-not-allowed"
