import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { getAdminPrograms } from "../../../services/adminService";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { useAsync } from "../../../utilities/customHooks";
import * as T from "../../../utilities/frontendTypes";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { useEffect, useState } from "react";
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList";
import { DetailsContentHeader } from "../ProfilePages/NewAdmin";
import { ProgramsAccordion } from "../../FounderPages/ProgramPages/FounderProgramList";
import Card from "../../../Components/Card";

export const programsContainerStyle = "flex flex-wrap justify-left overflow-y-auto w-full";

function AllPrograms() {

  const location = useLocation();

  useEffect(() => {
    setIsDetailsViewOpen(location.pathname !== "/dashboard/services");
  }, [location])
  
  const [isDetailsViewOpen, setIsDetailsViewOpen] = useState(false);

  const navigate = useNavigate();
  const adminProgramsAsync = useAsync(() => getAdminPrograms(), []);

  if (adminProgramsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (adminProgramsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const adminPrograms = adminProgramsAsync.value.adminPrograms;

  const handleViewProgramDetails = (id: T.ProgramId) => {
    navigate(`/dashboard/services/${id}`);
  }

  return (
    <div className={contentPageContainerStyle}>
      <div className={`${contentPageWrapperStyle} ${isDetailsViewOpen ? "!max-w-[280px]": ""} !items-start`}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label="All Services"
          tooltip="List of all services under your region."
        />
          <div className="flex flex-wrap overflow-y-auto">
            {
            adminPrograms.map(program => (
              <Card
                key={program._id}
                program={program}
                // onClickCallback={(id: string) => navigate(`/dashboard/founder-services/${id}`)}
                onClickCallback={(id: string) => handleViewProgramDetails(id)}
                isViewOnly={false}
              />
            ))
            }
          </div>
      </div>
      {/* Program Details Route */}
      <Outlet />
    </div>
  )
}

export default AllPrograms;