import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { getAllPrograms, getEnrolledPrograms } from "../../../services/founderService";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { LangContext, LangContextType, useAsync } from "../../../utilities/customHooks";
import * as T from "../../../utilities/frontendTypes";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { useContext, useEffect, useState } from "react";
import { contentPageContainerStyle, contentPageWrapperStyle } from "../../AdminPages/ProfilePages/PartnerAdminList";
import { DetailsContentHeader } from "../../AdminPages/ProfilePages/NewAdmin";
import { ProgramsAccordion } from "./FounderProgramList";
import { getUserProfile } from "../../../services/userService";
import textData from "../../../textData.json";
import { programsContainerStyle } from "../../AdminPages/ProgramsPages/AllPrograms";
import Card from "../../../Components/Card";


function FounderServiceList() {
  const { langState } = useContext(LangContext) as LangContextType

  const location = useLocation();
  
  const isMyServices = (location.pathname === "/dashboard/enrolled-services")

  useEffect(() => {
    setIsDetailsViewOpen(location.pathname !== "/dashboard/enrolled-services");
  }, [location]);

  const [isDetailsViewOpen, setIsDetailsViewOpen] = useState(false);

  const navigate = useNavigate();
  
  const founderProfileAsync = useAsync(() => getUserProfile("startupFounder"), []);
  const allProgramsAsync = useAsync(() => getAllPrograms(), [location]);

  if (allProgramsAsync.status === "pending" || founderProfileAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (allProgramsAsync.status === "rejected" || founderProfileAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const allPrograms = allProgramsAsync.value.regionSpecificPrograms;
  const founderProfile = founderProfileAsync.value.profile as T.StartupFounderProfile;


  const handleViewProgramDetails = (id: T.ProgramId) => {
    navigate(`/dashboard/founder-services/${id}`, {state: {from: location.pathname}})
  }

  return (
    <div className={contentPageContainerStyle}>
      <div className={`${contentPageWrapperStyle} !items-start`}>
        <ContentPageTopButtons />
        <DetailsContentHeader
          label={textData.Pages.FounderPages.ProgramPages.FounderProgramList.Header[langState]}
          tooltip={textData.Pages.FounderPages.ProgramPages.FounderProgramList.Tooltip[langState]}
        />
        <div className={programsContainerStyle}>
          {
            allPrograms.map(program => (
              <Card
                key={program._id}
                program={program}
                // onClickCallback={(id: string) => navigate(`/dashboard/founder-services/${id}`)}
                onClickCallback={(id: string) => handleViewProgramDetails(id)}
                isViewOnly={false}
              />
            ))
          }
        </div>
      </div>
      {/* Program Details Route
      <Outlet /> */}
    </div>
  )
}


export default FounderServiceList