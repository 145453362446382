import { useContext } from 'react';
import ApprovedFounderDashboard from './FounderDashboards/ApprovedFounderDashboard';
import PendingFounderDashboard from './FounderDashboards/PendingFounderDashboard';
import RejectedFounderDashboard from './FounderDashboards/RejectedFounderDashboard';
import UnsubmittedFounderDashboard from './FounderDashboards/UnsubmittedFounderDashboard';
import { AuthContext, AuthContextType } from '../../utilities/customHooks';

function FounderDashboard() {
  const { memberApplicationStatus } = useContext(AuthContext) as AuthContextType;

  const getFounderDashboardByApplicationStatus = () => {
    switch (memberApplicationStatus) {
      case "approved":
        return <ApprovedFounderDashboard />;
      case "flagged":  
        return <PendingFounderDashboard />;
      case "pending":
        return <PendingFounderDashboard />;
      case "rejected":
        return <RejectedFounderDashboard />; 
      case "rejectedReapply":
          return <RejectedFounderDashboard />; 
      case "unsubmitted":
        return <UnsubmittedFounderDashboard />;
      default:
        throw new Error('Unknown Application Status');
    }
  }

  return (
    getFounderDashboardByApplicationStatus()
  )
}

export default FounderDashboard