import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortObjectsByKey } from "../utilities/helperFunctions";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";

export type ListRowContentValue = string | Date | number | any; // [TODO] non-breaking, unsafe 'any' time, fix if time.

export type KeyValuePair = {
  [key: string]: ListRowContentValue;
}

export type ColumnHeader = {
  header: string,
  key: string,
  sortOptions?: ListRowContentValue[],
}

function List ( 
  {
    contentArray, 
    columnHeaderData,
    detailsIdName,
    returnDetailsId,
    listHighlightId,
    defaulSortOrderArrayPosition, 
    defaultAscending,
    defaultOptionsOrder,
    defaultOptionPosition,
    deaultSortByKey,
    emailArrayChange,
    personaMin,
    checkbox,
    isReverse
  } 
  : 
  { 
    contentArray: KeyValuePair[],
    columnHeaderData: ColumnHeader[],
    detailsIdName?: string,
    returnDetailsId?: (id: string) => void, 
    listHighlightId?: string,
    defaulSortOrderArrayPosition?: number,
    defaultAscending?: boolean
    defaultOptionsOrder?: {
      sortOptions: any[];
      currentOptionIndex: number;
    },
    defaultOptionPosition?: number,
    deaultSortByKey?: string,
    emailArrayChange?: (array: string[])=> void,
    personaMin?: number,
    checkbox?: boolean
    isReverse?: boolean
  }
) {

  const headerMatchingContentArray = contentArray.map((contentObj) => {
    const filteredObject: KeyValuePair = {};
    columnHeaderData.forEach(columnHeader => {
      const key = columnHeader.key;
      if (contentObj.hasOwnProperty(key)) {
        filteredObject[key] = contentObj[key];
      }
    }) 
    if (detailsIdName) {
      filteredObject[detailsIdName] = contentObj[detailsIdName];
    }
    return filteredObject;
  });
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const defaultAscendingBoolean: boolean = defaultAscending ? defaultAscending : false
  const [isAscending, setIsAscending] = useState(defaultAscendingBoolean);
  const defaultSort = defaulSortOrderArrayPosition ? defaulSortOrderArrayPosition : 0
  // [TODO] pending logic to determine the best initialSortedContentArray
  const initialSotredContentArray = defaultOptionsOrder ? sortObjectsByKey(headerMatchingContentArray, columnHeaderData[defaultSort].key, true, defaultOptionsOrder) : sortObjectsByKey(headerMatchingContentArray, columnHeaderData[defaultSort].key, true);
  const [sortedContentArray, setSortedContentArray] = useState(isReverse ? initialSotredContentArray.reverse() : initialSotredContentArray)
  const [sortByKey, setSortByKey] = useState(deaultSortByKey ? deaultSortByKey : "");
  const [currentOptionIndex, setCurrentOptionIndex] = useState(defaultOptionPosition ? defaultOptionPosition - 1 : 0);
  const [rowsHTML, setRowsHtml] = useState<React.ReactNode | null>(null);
  

  const optionsToggle = (options: ListRowContentValue[]) => {
    if (currentOptionIndex < options.length - 1) {
      setCurrentOptionIndex(i => (i + 1));
    } else {
      setCurrentOptionIndex(0);
    }
  };

  useEffect(() => {
    if (defaultOptionPosition !== undefined && defaultOptionsOrder !== undefined) {
      optionsToggle(defaultOptionsOrder.sortOptions)
    }
  }, []);


  useEffect(() => {
    if (emailArrayChange) {
      emailArrayChange(selectedUsers)
    }
  }, [selectedUsers]);

  const headerHTML = columnHeaderData.map((columnHeader, index) => {
    const headerKey = columnHeader.key;
    // [TODO] Alter sortOptions array to filter out options that no piece of content uses, add indicator when sortOptions is the key
    const sortOptions = columnHeader.sortOptions;
    const option = sortOptions ? {sortOptions, currentOptionIndex} : undefined;
    const isActive = columnHeader.key === sortByKey; // for displaying ascending indicator on the active column
    const isLast = index === columnHeaderData.length - 1; // for UI border right on the last column

    return (
      <div 
        className={`flex relative group ${!isLast && "border-r"} border-b border-borders hover:bg-text/10`}
        key={headerKey}
      >
        <button
          className={`p-4 grow font-bold cursor-pointer self-center flex`}
          onClick={
            () => {
              if (sortOptions) optionsToggle(sortOptions);
              setSortedContentArray(sortObjectsByKey(sortedContentArray, headerKey, isActive ? !isAscending : true, option));
              setIsAscending(isActive ? (b) => !b : true);
              setSortByKey(headerKey);

            }
          }
        >
          <p>{columnHeader.header}</p>
          <FontAwesomeIcon 
            className={`hover:cursor-pointer text-text absolute ${isActive ? 'visible' : 'invisible'} 
              group-hover:visible top-[calc(50%-8px)] right-4`
            }
            icon={
              sortOptions 
              ?
              faCircle
              :
              (
                isActive
                ? 
                (isAscending ? faChevronUp : faChevronDown) 
                : 
                faChevronDown
              )
            }
          />
        </button>
      </div>
    )
  })

  const handleCheckboxChange = (checkedUser: string) => {
    setSelectedUsers(prevSelectedUsers => {
      const isSelected = prevSelectedUsers.includes(checkedUser);
      if (isSelected) {
        return prevSelectedUsers.filter(user => user !== checkedUser);
      } else {
        return [...prevSelectedUsers, checkedUser];
      }
    });
  };

  useEffect(() => {
    let filterArray = [...sortedContentArray]
    if (personaMin) {
      filterArray = filterArray.filter(obj => obj.personaScore >= personaMin);
      setSelectedUsers
    }
    const newRowsHTML = filterArray.map((content, index) => { 
      const headerMatchingContent = {...content};
      if (detailsIdName) delete headerMatchingContent[detailsIdName]
      const isLastRow = index === sortedContentArray.length - 1;
      const rowKey = detailsIdName ? content[detailsIdName] as string : index.toString(); 

      const rowHTML = Object.values(headerMatchingContent).map((v, i) => {
        if (typeof v === "string" && moment(v, moment.ISO_8601, true).isValid()) {
          v = moment(v).format('MMM Do YYYY').toString();
        }
        return (
          checkbox && i === 0
          ?
          <div
            key={i}
            className={
              `
                flex justify-left ${!isLastRow && "border-b"} border-borders
                group-hover:bg-text/10 transition-colors duration-200 p-4`
                // ${listHighlightId === rowKey && "bg-text/10"}
            }
          >        
            <input 
              type="checkbox"
              onChange={() => handleCheckboxChange(v)}
              className="mr-2"
            />
            <button onClick={() => {
              if (returnDetailsId && detailsIdName) {
                returnDetailsId(content[detailsIdName] as string);
              }
            }} className="flex grow font-normal text-text/80 break-all self-center">{v as string}</button>
          
          </div>
          :
          <button
          onClick={() => {
            if (returnDetailsId && detailsIdName) {
              returnDetailsId(content[detailsIdName] as string);
            }
          }}
          key={i}
          className={
            `
              flex justify-left ${!isLastRow && "border-b"} border-borders
              group-hover:bg-text/10 transition-colors duration-200 p-4`
              // ${listHighlightId === rowKey && "bg-text/10"}
          }
        >        
          <p className="flex grow font-normal text-text/80 break-all self-center">{v as string}</p>
        </button>
        )
      })
      
      return (
          <div
            key={rowKey}
            className={`contents group cursor-pointer`}
          >
            {rowHTML}
          </div>
      )
    });
    setRowsHtml(newRowsHTML)
  }, [personaMin, sortByKey, isAscending, currentOptionIndex]);


  let gridClassName = "";
  switch(columnHeaderData.length) {
    case 1:
      gridClassName = "grid-cols-1";
      break;
    case 2:
      gridClassName = "grid-cols-2";
      break;
    case 3:
      gridClassName = "grid-cols-3";
      break;
    case 4:
      gridClassName = "grid-cols-4";
      break;
    case 5:
      gridClassName = "grid-cols-5";
      break;
    case 6:
      gridClassName = "grid-cols-6";
      break;
    case 7:
      gridClassName = "grid-cols-7";
      break;
    case 8:
      gridClassName = "grid-cols-8";
      break;
    case 9:
      gridClassName = "grid-cols-9";
      break;
    case 10:
      gridClassName = "grid-cols-10";
      break;
  }

  return (
    <div className={`grid ${gridClassName} overflow-x-auto border border-borders font-medium bg-backgrounds text-text overflow-y-auto mb-5 w-full`}>
      {headerHTML}
      {rowsHTML}
    </div>
  )
}

export default List;