import smaller from '../../../assets/smaller.png'

const MainHeroImage = () => {
  return (
    <div className="hidden lg:flex lg:w-1/2">
      <img
        className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full object-[50%,28%]"
        src={smaller}
        alt="A preson crouches before a whiteboard illustrating an idea"
      />
    </div>
  );
};

export default MainHeroImage;
