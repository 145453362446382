import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { getMemberHeaders } from "../../../services/adminService";
import { LangContext, LangContextType, OutletContextType, useAsync } from "../../../utilities/customHooks";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import * as T from "../../../utilities/frontendTypes";
import List, { KeyValuePair } from "../../../Components/List";
import { getLastPartOfPath, isFromUrls } from "../../../utilities/helperFunctions";
import { contentPageContainerStyle, contentPageWrapperStyle } from "../ProfilePages/PartnerAdminList";
import { DetailsContentHeader } from "../ProfilePages/NewAdmin";
import { useContext, useState } from "react";
import textData from "../../../textData.json";

export const membershipsColumnHeaderData = [
  {
    header: "Email",
    key: "email"
  },
  {
    header: "Account Created Date",
    key: "accountCreated"
  },
]

function MembersList() {
  const { langState } = useContext(LangContext) as LangContextType
  const navigate = useNavigate();
  const location = useLocation();
  const isFromProgramDetails = isFromUrls(["/dashboard/services/", "/dashboard/my-services/"], location);

  const [reloadOnOutletSave, setReloadOnOutletSave] = useState(false);


  const handleViewMemberDetails = (memberHeaderUserId: T.UserId) => {
    navigate((`/dashboard/memberships/${memberHeaderUserId}`));
  }
  
  const memberHeadersAsync = useAsync(() => getMemberHeaders(), [reloadOnOutletSave]);

  if (memberHeadersAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (memberHeadersAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const memberHeaders = memberHeadersAsync.value.memberHeaders.reverse();
  return (
    <div className={contentPageContainerStyle}>
     {
     !isFromProgramDetails 
     &&
      <div className={contentPageWrapperStyle}>
        <ContentPageTopButtons />
        <DetailsContentHeader 
          label={textData.Pages.AdminPages.MembershipPages.MembersList.MembersList[langState]}
          tooltip={textData.Pages.AdminPages.MembershipPages.MembersList.MembersListTooltip[langState]}
        />
        <List 
          contentArray={memberHeaders as KeyValuePair[]}
          columnHeaderData={membershipsColumnHeaderData}
          returnDetailsId={handleViewMemberDetails}
          detailsIdName="id" // [TODO] check if this is the correct id name or if we should fix what was passed from backend (yes we should)
          listHighlightId={getLastPartOfPath(location.pathname)}
          deaultSortByKey={"accountCreated"}
          defaulSortOrderArrayPosition={1}
          defaultAscending={true}
          isReverse={true}
        />
      </div>}
      {/* Members details route */}
      <Outlet context={ { reloadOnOutletSave, setReloadOnOutletSave } satisfies OutletContextType} />
    </div>  
  )
}

export default MembersList