import { FormDefinition } from "../Components/Form/Form";
import { basic_number_validator, basic_text_validator, boolean_selection_validator, business_number_validator, date_validator, email_validator, multiselect_validator, phone_validator, selection_validator, url_validator } from "./formFieldValidators";

export const profileFormPersonalInfoDefinition: FormDefinition = {
        firstName: {
          label: "First Name",
          inputType: "text",
          frLabel: "Prénom",
          validator: basic_text_validator,
        },
        lastName: {
          label: "Last Name",
          frLabel: "Nom de famille",
          inputType: "text",
          validator: basic_text_validator,
        },
        // [TODO] handle conditional checkboxes and other field feature
        pronouns: {
          label: "Pronouns",
          frLabel: "Pronom",
          inputType: "customMultiSelect",
          validator: multiselect_validator,
          additionalData: {
            selectionData: [
              {
                text: "she/her",
                frText: "elle",
                value: "she"
              },
              {
                text: "he/him",
                frText: "il",
                value: "he"
              },
              {
                text: "they/them",
                frText: "eux",
                value: "they"
              },
              {
                text: "prefer not to say",
                frText: "préfère ne pas répondre",
                value: "notToSay"
              },
            ]
          }
        },
        title: {
          label: "Job Title",
          inputType: "text",
          frLabel: "Titre du poste",
          validator: basic_text_validator,
        },
        phoneNumber: {
          label: "Phone Number",
          inputType: "text",
          frLabel: "Numéro de téléphone",
          validator: phone_validator,
        }
        // isPointOfContact: {
        //   label: "I'm the point of contact",
        //   frLabel: "Je suis la personne ressource à contacter",
        //   inputType: "checkbox",
        //   tooltip: {
        //     content: "If you would prefer email notification and communication from EIP to go to another point of contact, please leave this checkbox unselected and specify the preffered point of contact below",
        //     position: "bottom-right"
        //   },
        //   frTooltip: {
        //     content: "Si vous préférez que les avis par courriel et les communications d’ÉPI soient envoyés à un autre point de contact, ne cochez pas cette case et indiquez le point de contact souhaité ci-dessous.",
        //     position: "bottom-right"
        //   },
        // }
      }
    
    export const profileFormPointOfContactDefinition: FormDefinition = {
        firstName: {
          label: "First Name",
          frLabel: "Prénom",
          inputType: "text",
          validator: basic_text_validator,
        },
        lastName: {
          label: "Last Name",
          frLabel: "Nom de famille",
          inputType: "text",
          validator: basic_text_validator,
        },
        // [TODO] pronouns is waiting on conditional checkboxes feature
        title: {
          label: "Job Title",
          frLabel: "Titre du poste",
          inputType: "text",
          validator: basic_text_validator,
        },
        email: {
          label: "Email",
          frLabel: "Courriel",
          inputType: "text",
          validator: email_validator,
        },
        phoneNumber: {
          label: "Phone Number",
          frLabel: "Numéro de téléphone",
          inputType: "text",
          validator: phone_validator,
        },
      }

    export const profileFormCompanyInfoDefinition: FormDefinition = {
        operatingName: {
          label: "Operating Name",
          frLabel: "Nom opérationnel",
          inputType: "text",
          validator: basic_text_validator,
        },
        isLegalNameSameAsOperatingName: {
          label: "Our company's legal name is the same as its operating name.  Full legal name must be provided.",
          frLabel: "Le nom légal de notre société est le même que son nom opérationnel.  Le nom légal complet doit être fourni.",
          inputType: "checkbox",
        },
        legalName: {
          conditionalRenderDefinition: {
            fieldKey: "isLegalNameSameAsOperatingName",
            renderWhenTrue: false, 
          },
          label: "Legal Name",
          frLabel: "Nom légal",
          inputType: "text",
          validator: basic_text_validator,
        },
        description: {
          label: "Company Description",
          frLabel: "Description de l’entreprise",
          inputType: "textarea",
          validator: basic_text_validator,
        },
        industry: {
          label: "Industry",
          frLabel: "Industrie",
          inputType: "dropdownSelect",
          tooltip: {
            content: "Click on the field and select an option from the list.",
            position: "bottom-right"
          },
          frTooltip: {
            content: "Cliquer sur le champ et sélectionner une option de la liste.",
            position: "bottom-right"
          },
          validator: selection_validator,
          additionalData: {
            selectionData: [
              {
                value: "Agriculture",
                text: "Agriculture",
                frText: "Agriculture",
              },
              {
                value: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
                text: "Advanced Materials & Manufacturing: Excludes specifically forestry, agriculture, mining, clean technologies and life sciences",
                frText: "Matériaux d’avant-garde et fabrication Exclut spécifiquement la sylviculture, l'agriculture, l'exploitation minière, les technologies propres et les sciences de la vie.",
              },
              {
                value: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
                text: "Clean Tech: Technologies that protect and/or increase efficient utilization of land, water and air resources",
                frText: "Tech. propres : technologies qui protègent et/ou augmentent l’utilisation efficace des ressources de la terre, de l'eau et de l’air",
              },
              {
                value: "Consumer Retail",
                text: "Consumer Retail",
                frText: "Consommation de détail",
              },
              {
                value: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
                text: "Digital Media & Telecommunications: Digital applications and content and information and communications technologies. Excludes healthcare, agriculture, forestry, mining, financial services, education, social innovation, culture/recreation and energy-related solutions",
                frText: "Médias numériques et télécommunications Applications numériques et technologies de l’information et de communication Exclut les soins de santé, l'agriculture, la sylviculture, l'exploitation minière, les services financiers, l'éducation, l'innovation sociale, la culture et loisirs, et les solutions liées à l'énergie.",
              },
              {
                value: "Education",
                text: "Education",
                frText: "Éducation",
              },
              {
                value: "Financial Services",
                text: "Financial Services",
                frText: "Services financiers",
              },
              {
                value: "Food & Beverage",
                text: "Food & Beverage",
                frText: "Restauration/production d’aliments et boissons",
              },
              {
                value: "Forestry",
                text: "Forestry",
                frText: "Foresterie",
              },
              {
                value: "Life Sciences & Health",
                text: "Life Sciences & Health",
                frText: "Santé et sciences de la vie",
              },
              {
                value: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
                text: "Mining, Oil & Gas: Includes extractive activities that would not otherwise fall under clean technologies",
                frText: "Exploitation minière, pétrole et gaz : comprend les activités extractives qui ne relèveraient pas autrement des technologies propres.",
              },
              {
                value: "Tourism & Culture: Includes entertainment and sports",
                text: "Tourism & Culture: Includes entertainment and sports",
                frText: "Culture et tourisme : comprend le divertissement et le sport",
              },
              {
                value: "Transportation",
                text: "Transportation",
                frText: "Transport",
              },
              {
                value: "Other",
                text: "Other",
                frText: "Autre",
              }
            ]
          }
        },
        websiteURL: {
          label: "Website URL",
          frLabel: "URL du site Web",
          inputType: "text",
          validator: url_validator,
        },
        number: {
          label:  `Business Number<br>The business number (BN), assigned to all incorporated businesses by the Canada Revenue Agency, is a 9-digit number that is unique to a business or legal entity. (Note: the BN is followed by a two-letter program identifier and a 4-digit reference number, both of which are unnecessary for this application). This number is different from the 7-digit Corporation Number and should not contain a hyphen.  If a valid 9-digit BN is not provided, the application will be rejected as it is a requirement for participation in ElevateIP.  For more details on what a BN is, visit <a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'>this website)</a>`,
          frLabel:  `The business number (BN), assigned to all incorporated businesses by the Canada Revenue Agency, is a 9-digit number that is unique to a business or legal entity. (Note: the BN is followed by a two-letter program identifier and a 4-digit reference number, both of which are unnecessary for this application). This number is different from the 7-digit Corporation Number and should not contain a hyphen.  If a valid 9-digit BN is not provided, the application will be rejected as it is a requirement for participation in ElevateIP.  For more details on what a BN is, visit <a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'>this website)</a>`,
          inputType: "text",
        //   tooltip: {
        //     content: `The business number (BN), assigned to all incorporated businesses by the Canada Revenue Agency, is a 9-digit number that is unique to a business or legal entity. (Note: the BN is followed by a two-letter program identifier and a 4-digit reference number, both of which are unnecessary for this application). This number is different from the 7-digit Corporation Number and should not contain a hyphen.  If a valid 9-digit BN is not provided, the application will be rejected as it is a requirement for participation in ElevateIP.  For more details on what a BN is, visit <a target='_blank' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/corporations/type-corporation.html#ccpc'>this website)</a>`,
        //     customWidth: //MAKE THIS A THING IF THEY WANT
        //     },
          validator: {
            ...business_number_validator,
            minLength: {
              value: 9,
              message: "Registration number must be nine digits",
            },
            maxLength: {
              value: 9,
              message: "Registration number must be nine digits",
            }
          }
        },
        date: {
          label: "Registration Date",
          inputType: "date",
          frLabel: "Date de l’enregistrement",
          validator: date_validator,
        },
        province: {
          label: "Registered Provinces",
          inputType: "multiSelect",
          frLabel: "Provinces d’enregistrement",
          validator: multiselect_validator,
          additionalData: {
            selectionData: [
              {
                value: "AB",
                text: "Alberta",
                frText: "Alberta"
              },
              {
                value: "BC",
                text: "British Columbia",
                frText: "Colombie-Britannique"
              },
              {
                value: "MB",
                text: "Manitoba",
                frText: "Manitoba"
              },
              {
                value: "NB",
                text: "New Brunswick",
                frText: "Nouveau-Brunswick"
              },
              {
                value: "NL",
                text: "Newfoundland and Labrador",
                frText: "Terre-Neuve-et-Labrador"
              },
              {
                value: "NT",
                text: "Northwest Territories",
                frText: "Territoires du Nord-Ouest"
              },
              {
                value: "NS",
                text: "Nova Scotia",
                frText: "Nouvelle-Écosse"
              },
              {
                value: "NU",
                text: "Nunavut",
                frText: "Nunavut"
              },
              {
                value: "ON",
                text: "Ontario",
                frText: "Ontario"
              },
              {
                value: "PE",
                text: "Prince Edward Island",
                frText: "Île-du-Prince-Édouard"
              },
              {
                value: "QC",
                text: "Quebec",
                frText: "Québec"
              },
              {
                value: "SK",
                text: "Saskatchewan",
                frText: "Saskatchewan"
              },
              {
                value: "YT",
                text: "Yukon",
                frText: "Yukon"
              },
            ]
          }
        }
      }

    export const profileFormAddressDefinition: FormDefinition = {
        streetNumber: {
            label: "Street Number",
            frLabel: "Numéro civique",
            inputType: "text",
            validator: basic_text_validator,
          },
          streetName: {
            label: "Street Name",
            frLabel: "Nom de la rue",
            inputType: "text",
            validator: basic_text_validator,
          },
          unitNumber: {
            label: "Unit Number",
            frLabel: "Numéro d’appartement",
            tooltip: {
              content: "Optional Field"
            },
            frTooltip: {
              content: "Optionnel"
            },
            inputType: "text",
            validator: {
              ...basic_text_validator,
              required: false
            }
          },
          postalCode: {
            label: "Postal Code",
            frLabel: "Code postal",
            inputType: "text",
            validator: basic_text_validator,
          },
          city: {
            label: "City",
            frLabel: "Ville",
            inputType: "text",
            validator: basic_text_validator,
          },
          province: {
            label: "Province",
            frLabel: "Province",
            inputType: "text",
            validator: basic_text_validator,
          },
          country: {
            label: "Country",
            frLabel: "Pays",
            inputType: "text",
            validator: basic_text_validator,
          }
    }

    export const profileFormProgramingEvaluationDefinition: FormDefinition = {
        hasSocialOrEnvironmentalGoals: {
          frLabel: "Un des fondements et objectifs de votre entreprise est pour un bénéfice à la société ou à l’environnement?",
          label: "Does your company have social or environmental goals as part of its founding mission?",
          inputType: "boolean",
        },
        numFullTimeEmployees: {
          frLabel: "Nombre d’employés à temps plein (ETP)",
          label: "Number of Full Time Employees",
          inputType: "number",
          validator: basic_number_validator,
        },
        isProductForSaleOnMarket: {
          frLabel: "Le produit que vous cherchez à sécuriser comme PI est-il actuellement en vente sur le marché?",
          label: "Is the product you are looking to protect currently for sale on the market?",
          inputType: "boolean"
        },
        productForSaleInfo: {
          conditionalRenderDefinition: {
            fieldKey: "isProductForSaleOnMarket",
            renderWhenTrue: true, 
          },
          frLabel: "Veuillez sélectionner tous les énoncés qui s’appliquent",
          label: "Please select all that apply",
          inputType: "multiSelect",
          validator: multiselect_validator,
          additionalData: {
            selectionData: [
              {
                value: "singleProduct",
                text: "The product I want to protect is currently for sale",
                frText: "Le produit que je désire sécuriser comme PI est présentement en vente",
              },
              {
                value: "multipleProducts",
                text: "There are multiple products I want to protect that are currently for sale",
                frText: "Plusieurs produits que je désire sécuriser comme PI sont présentement en vente",
              },
            ]
          }
        },
        revenueOverTwelveMonths: {
          frLabel: "Indiquez le revenu total de votre entreprise (en CAD) au cours de votre dernier exercice financier.",
          label: "Indicate your company’s total revenue (in $CAD) during your most recent fiscal year.",
          inputType: "number",
          validator: basic_number_validator,
        },
        underrepresentedFounderGroups: {
          frLabel: "", //FR NEEDED
          label: "Underrepresented Groups: A startup/BAI owned by an underrepresented group refers to those that are majority owned (50.1%) by an underrepresented group which includes: women, Indigenous Peoples (including First Nations, Métis and Inuit), 2SLGBTQI+, visible minorities, persons with disabilities or any community of people that experience significant collective barriers to participating in mainstream society.  We are committed to increasing the diversity of people in our programs and ensuring that all people have equitable and inclusive experiences throughout. In order to monitor our progress on this goal and to comply with Federal Government funding requirements, we are encouraging you to share whether your company is majority owned by any of the following underrepresented groups.  Please select all that apply:",
          inputType: "multiSelect",
          validator: multiselect_validator,
          additionalData: {
            selectionData: [
              {
                value: "women",
                text: "Women",
                frText: "Femmes"
              },
              {
                value: "indigenous",
                text: "Indigenous Peoples (including First Nations, Métis and Inuit)",
                frText: "Autochtones"
              },
              {
                value: "2slgbtqi+",
                text: "2SLGBTQI+",
                frText: "2SLGBTQI+"
              },
              {
                value: "visibleMinorities",
                text: "Visible Minorities",
                frText: "Minorités visibles"
              },
              {
                value: "disabilities",
                text: "Persons with Disabilities",
                frText: "Personnes handicapées"
              },
              {
                value: "other",
                text: "Any community of people that experience significant collective barriers to participating in mainstream society",
                frText: "Autre"
              },
              {
                value: "notToSay",
                text: "Prefer not to say",
                frText: "Préfère ne pas répondre"
              },
              {
                value: "none",
                text: "None of the Above",
                frText: "Aucune de ces réponses"
              }
            ]
          }
        },
        isFromOLMC: {
          label: "Are you from an official language minority community (<a target='_blank' href='https://www.canada.ca/en/treasury-board-secretariat/services/values-ethics/official-languages/public-services/official-language-minority-communities.html'>OLMC</a>)?",
          frLabel:"Êtes-vous issu d'une communauté de langue officielle en situation minoritaire (CLOSM)?",
          inputType: "boolean",
        },
        officialLanguageMinorityCommunity: {
          conditionalRenderDefinition: {
            fieldKey: "isFromOLMC",
            renderWhenTrue: true,
          },
          label: "Which OLMC are you a part of?",
          frLabel: "De quelle CLOSM faites-vous partie?",
          inputType: "customDropdownSelect",
          tooltip: {
            content:  "Click on the field and select an option from the list or Enter your own directly.",
            position: "top-left"
          },
          frTooltip: {
            content:  "Cliquer sur le champ et sélectionner une option de la liste ou saisissez votre propre classification directement.",
            position: "top-left"
          },
          validator: selection_validator,
          additionalData: {
            selectionData: [
              {
                value: "ACF",
                frText: "Assemblée communautaire fransaskoise (ACF)",
                text: "Assemblée communautaire fransaskoise (ACF)",
              },
              {
                value: "AFO",
                frText: "Assemblée de la francophonie de l'Ontario (AFO)",
                text: "Assemblée de la francophonie de l'Ontario (AFO)",
              },
              {
                value: "FCFA",
                frText: "Fédération des communautés francophones et acadienne du Canada (FCFA)",
                text: "Fédération des communautés francophones et acadienne du Canada (FCFA)",
              },
              {
                value: "SFM",
                frText: "Société franco-manitobaine (SFM)",
                text: "Société franco-manitobaine (SFM)",
              },
              {
                value: "SNA",
                frText: "Société nationale de l'Acadie (SNA)",
                text: "Société nationale de l'Acadie (SNA)",
              },
            ]
          }
        },
        // additionalLinks: {
        //   label: "Please Provide Any Additional Supporting Links (Pitch Deck, Business Plan, etc)",
        //   frLabel: "",
        //   inputType: "text",
        //   validator: url_validator,
        // },
      }

    export const profileFormiPStrategyDefinition: FormDefinition = {
        bestMatch: {
          frLabel: "",
          label: "Please indicate which of the following best matches your needs at this time:",
          inputType: "multiSelect",
          validator: multiselect_validator,
          additionalData: {
            selectionData: [
              {
                value: "education",
                text: "I’m seeking general IP Education (e.g. Where do I start? What is a patent?, etc.)",
                frText: "",
              },
              {
                value: "strategy",
                text: "I’m seeking support for IP Strategy Development (e.g, What/how/when/where should I protect? I need help with patentability/novelty searches., etc.)",
                frText: "",
              },
              {
                value: "trademarks",
                text: "I think I’m ready to register trademarks in the next 3-6 months",
                frText: "",
              },
              {
                value: "patents",
                text: "I think I’m ready to file patents in the next 3-6 months or convert PCT / provisional or continuations",
                frText: "",
              },
            ]
          }
        },
        assignmentAgreement: {
          frLabel: "",
          label: 'Have you and your cofounders signed a "Co-founders IP assignment agreement" that assigns IP to the corporation? ',
          inputType: "boolean",
          validator: boolean_selection_validator
        },
        patentsReadyOptions: {
          frLabel: "",
          label: " If you think you are ready to file patents in the next 3-6 months, please indicate which of the following apply to the technology you intend to patent:",
          inputType: "multiSelect",
          validator: multiselect_validator,
          additionalData: {
            selectionData: [
              {
                value: "notApplicable",
                text: "Not applicable, as I am not seeking support to file patents in the next 3-6 months",
                frText: "",
              },
              {
                value: "search",
                text: "My IP service provider has conducted a favorable patentability/prior art/novelty search for the technology I plan to patent.",
                frText: "",
              },
              {
                value: "family",
                text: "I have a granted patent in the same patent family.",
                frText: "",
              },
              {
                value: "written",
                text: "I have received a favorable written opinion from my PCT application.",
                frText: "",
              },
              {
                value: "support",
                text: "I don’t have B or C or D and I am seeking support to file patent",
                frText: "",
              },
            ]
          }
        },
        trademarkClearanceSearch: {
          frLabel: "",
          label: "If you think you are ready to register trademarks in the next 3-6 months, has your IP service provider completed a clearance search?",
          inputType: "boolean",
          validator: boolean_selection_validator
        },
        isWorkingWithLawFirm: {
          frLabel: "Votre entreprise est présentement en contact avec une firme d’avocats et de PI",
          label: "Is your company currently working with an IP / Law firm?",
          inputType: "boolean",
        },
        workingLawFirms: {
          conditionalRenderDefinition: {
            fieldKey: "isWorkingWithLawFirm",
            renderWhenTrue: true,
          },
          frLabel: "Veuillez indiquer le nom des firmes",
          label: "Please list the name of firms",
          inputType: "customList",
        },
        isWorkingWithOrganization: {
          frLabel: "Votre entreprise est présentement en contact avec Invest Ottawa ou North Forge qui collaborent avec Communitech",
          label: "Is your company currently working with Communitech, Invest Ottawa, North Forge, Spark Center or Launch Lab?",
          inputType: "boolean",
        },
        workingOrganizations: {
          conditionalRenderDefinition: {
            fieldKey: "isWorkingWithOrganization",
            renderWhenTrue: true,
          },
          frLabel: "Veuillez indiquer quelle organisation",
          label: "Please specify which organization",
          inputType: "dropdownSelect",
          validator: {
            required: {
              value: true,
              message: "Please select an option"
            }
          },
          additionalData: {
            selectionData: [
              {
                value: "communitech", 
                text: "Communitech"
              },
              {
                value: "northForge", 
                text: "North Forge"
              },
              {
                value: "investOttawa", 
                text: "Invest Ottawa"
              },
              {
                value: "spark", 
                text: "Spark Center"
              },
              {
                value: "launch", 
                text: "Launch Labs"
              }
            ]
          }
        },
        isWorkingWithBai: {
          frLabel: "Votre entreprise travaille-t-elle actuellement avec un autre IAA (Incubateur Accélérateur d’Affaires)?",
          label: "Is your company currently working with a different BAI?",
          inputType: "boolean",
        },
        otherBai: {
          conditionalRenderDefinition: {
            fieldKey: "isWorkingWithBai",
            renderWhenTrue: true,
          },
          frLabel: "Veuillez indiquer quelle organisation",
          label: "Please specify which organization",
          inputType: "text",
          validator: basic_text_validator
        }
      }


    export const profileFormipReadinessDefinition: FormDefinition =  { 
            responsible: {
              label: "Who is responsible for IP within your company?",
              frLabel: "",
              inputType: "text",
              validator: basic_text_validator
            },
            education: {
              label: "Does that person have any formal IP education?",
              frLabel: "",
              inputType: "boolean",
              validator: boolean_selection_validator
            },
            educationType: {
              conditionalRenderDefinition: {
                fieldKey: "education",
                renderWhenTrue: true, 
              },
              label: "What type of formal IP education did that person receive?",
              frLabel: "",
              inputType: "dropdownSelect",
              additionalData: {
                selectionData: [
                  {
                    value: "classes",
                    text: "IP classes",
                  },
                  {
                    value: "webinars",
                    text: "Webinars provided by an accelerator/incubator focussed on IP",
                  },
                  {
                    value: "ipon",
                    text: "IP Assist or IPON education",
                  },
                ]
              }
            },
            jurisdiction: {
              label: "Have you identified which jurisdictions are of particular interest for you and where you would seek patents if provided with sufficient funding?",
              frLabel: "",
              inputType: "boolean",
              validator: boolean_selection_validator
            },
            specificJurisdiction: {
              conditionalRenderDefinition: {
                fieldKey: "jurisdiction",
                renderWhenTrue: true, 
              },
              label: "Please Specify",
              frLabel: "",
              inputType: "text",
              validator: basic_text_validator,
            },
            provider: {
              label: "Do you have an IP service provider/patent agent?",
              frLabel: "",
              inputType: "boolean",
              validator: basic_text_validator,
            },
            providerLastWorked: {
              conditionalRenderDefinition: {
                fieldKey: "provider",
                renderWhenTrue: true, 
              },
              label: "When did you last work with them?",
              frLabel: "",
              inputType: "dropdownSelect",
              validator: {
                required: {
                  value: true,
                  message: "Please select an option"
                }
              },
              additionalData: {
                selectionData: [
                  {
                    value: "under", 
                    text: "Within past 12 months"
                  },
                  {
                    value: "over", 
                    text: "Not within past 12 months"
                  }
                ]
              }
            },  
            ipSection: {
              label: "Do you have a documented IP section in your data room?",
              frLabel: "",
              inputType: "dropdownSelect",
              validator: {
                required: {
                  value: true,
                  message: "Please select an option"
                }
              },
              additionalData: {
                selectionData: [
                  {
                    value: "yes", 
                    text: "Yes"
                  },
                  {
                    value: "no", 
                    text: "No"
                  },
                  {
                    value: "n/a", 
                    text: "Not applicable - We are self-funded"
                  }
                ]
              }
            },
            software: {
              label: "Do you have an open source log where you keep track of all open source software, algorithms and/or datasets used within your product/service, along with the licensing terms and notes on compliance?",
              frLabel: "",
              inputType: "dropdownSelect",
              validator: {
                required: {
                  value: true,
                  message: "Please select an option"
                }
              },
              additionalData: {
                selectionData: [
                  {
                    value: "yes", 
                    text: "Yes"
                  },
                  {
                    value: "no", 
                    text: "No"
                  },
                  {
                    value: "n/a", 
                    text: "Not applicable - Our product has no software"
                  }
                ]
              }
            },
            tradeSecrets: {
              label: "Do you have any trade secret protection measures in place?",
              frLabel: "",
              inputType: "boolean",
              validator: boolean_selection_validator,
            },
            tradeSecretsList: {
              conditionalRenderDefinition: {
                fieldKey: "tradeSecrets",
                renderWhenTrue: true, 
              },
              label: "Please list your protection measures",
              frLabel: "",
              inputType: "textarea",
            },  
            ipBudget: {
              label: "Do you have a documented IP budget?",
              frLabel: "",
              inputType: "boolean",
              validator: boolean_selection_validator,
            },
            patentReady: {
              label: "Do you have any innovations ready to file patents for within the next 3-6 months? ",
              frLabel: "",
              inputType: "boolean",
              validator: boolean_selection_validator,
            },
            patentsNumber: {
              conditionalRenderDefinition: {
                fieldKey: "patentReady",
                renderWhenTrue: true, 
              },
              label: "How many innovations are ready to file within the next 3-6 months?",
              frLabel: "",
              inputType: "number",
              validator: basic_number_validator,
            },  
            searchesList: {
              conditionalRenderDefinition: {
                fieldKey: "patentReady",
                renderWhenTrue: true, 
              },
              label: "Regarding innovations mentioned in the previous question, has your IP service provider completed patentability or novelty searches for at least one of them? Please specify the searches conducted for each innovation",
              frLabel: "",
              inputType: "textarea",
            }, 
          }

