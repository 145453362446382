import { LoadingContext, LoadingContextType, ToastContext, ToastContextType, useAsync } from "../utilities/customHooks";
import LoadingIndicator from "./LoadingIndicator";
import ErrorIndicator from "./ErrorIndicator";
import { getRegionAdminNotes } from "../services/adminService";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Form, { FormDefinition } from "./Form/Form";
import { basic_text_validator } from "../utilities/formFieldValidators";
import { ButtonProp } from "./Button";
import { addNewNote } from "../services/adminService";

const CompanyNotes = ({id}:{id: string}) => {
    const [notesHtml, setNotesHtml] = useState(<></>)
    const [noteCount, setNoteCount] = useState(0)
    // Move useContext outside of conditional blocks
    const toastContext = useContext(ToastContext) as ToastContextType;
    const loadingContext = useContext(LoadingContext) as LoadingContextType;
    const {showToast} = toastContext;
    const {showLoading, hideLoading} = loadingContext;
  
    const notesAsync = useAsync(() => getRegionAdminNotes(id), [id]);
    useEffect(() => {
      if (notesAsync.status === "pending") {
        showLoading();
      } else {
        hideLoading();
      }
    }, [notesAsync.status, showLoading, hideLoading]);
  
    useEffect(() => {
      if (notesAsync.status === "resolved") {
        const notes = notesAsync.value.notes;


        if (notes.length > 0) {
          const parsedNotes = notes.map(note => ({
                ...note,
                date: new Date(note.date)
            }));
          const sortedNotes = parsedNotes.sort((a, b) => b.date.getTime() - a.date.getTime());
          const html = (
            <div>
              {sortedNotes.map((note, index) => (
                <div key={index}>
                  <div className="flex justify-between w-full font-semibold">
                    <p>{note.author}</p>
                    <p>{moment(note.date).format('MMM Do YYYY').toString()}</p>
                  </div>
                  <div>
                    <p>{note.notes}</p>
                  </div>
                </div>
              ))}
            </div>
          )
          setNotesHtml(html);
        } else {
          setNotesHtml(<p>Currently no notes for this company</p>);
        }
      }
    }, [notesAsync.status, id]);
  
    const handleAddDDFlag = async (updatedFormData: FormData) => {
      const tempNotes = updatedFormData as any
      const notes = tempNotes.notes
      showLoading();
      const response = await addNewNote(id, notes)
      hideLoading();
      if (response.success) {
        showToast({
          toastType: "success",
          message: "Note Added!",
        });
        const num = noteCount + 1
        setNoteCount(num)
      }
    }
  
    const newNoteForm: FormDefinition = {
      notes: {
        label: "New Note:",
        inputType: "textarea",
        validator: basic_text_validator,
      }
    }
  
    const primaryButtonProp: ButtonProp = {
      buttonText: "Add",
      actionHandler: handleAddDDFlag,
    }
  
    return (
      <div className="flex flex-col w-full overflow-auto">
        {notesHtml}
        <div className="mt-5">
          <Form 
            formDefinition={newNoteForm}
            formData={null}
            primaryButtonProp={primaryButtonProp}
          />
        </div>
      </div>
    )
  }

export default CompanyNotes

