import { useContext, useState } from 'react';
import config from '../data/index.json';
import { AuthContext, AuthContextType, LangContext, LangContextType } from '../../../utilities/customHooks';
import { useNavigate } from 'react-router-dom';
import Button from '../../../Components/Button';
import Footer from './Footer';
import textData from "../../../textData.json";

const MainHero = () => {
  const { langState } = useContext(LangContext) as LangContextType
  const { isAuthenticated, logout } = useContext(AuthContext) as AuthContextType;
  const navigate = useNavigate();

  const pageOneHTML = 
    <div className="mt-3 text-base text-text sm:mt-5 sm:text-lg sm:max-w-xxl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 flex flex-col">
      {textData.Pages.LandingPage.components.MainHero.description[langState]}
      <br />
      <p className='my-2'>{textData.Pages.LandingPage.components.MainHero.How[langState]}</p>
      <ul className='list-disc ml-8 text-lg font-semibold text-left w-fit self-start sm:self-center lg:self-start'>
        <li>{textData.Pages.LandingPage.components.MainHero.Education[langState]}</li>
        <li>{textData.Pages.LandingPage.components.MainHero.Development[langState]}</li>
        <li>{textData.Pages.LandingPage.components.MainHero.Implementation[langState]}</li>
      </ul>
    </div>

  const pageTwoHTML = (
    <div className=" text-base text-text mt-8 sm:max-w-xxl flex flex-col sm:mx-auto lg:mx-0">
      <h2 className="text-3xl font-bold mb-4">{textData.Pages.LandingPage.components.MainHero.ApplicationPeriod[langState]}</h2>
      <p className="text-lg mb-4">
        {textData.Pages.LandingPage.components.MainHero.Steps[langState]}
      </p>
      <ul className="list-disc ml-6 text-lg font-medium mb-4 w-fit self-start sm:self-center lg:self-start text-left">
        <li>{textData.Pages.LandingPage.components.MainHero.Step1[langState]}</li>
        <li>{textData.Pages.LandingPage.components.MainHero.Step2[langState]}</li>
      </ul>
      <p className="text-lg mb-4">
        {textData.Pages.LandingPage.components.MainHero.Time[langState]}
      </p>
      <p className="text-lg mb-4">
        {textData.Pages.LandingPage.components.MainHero.Time2[langState]}
      </p>
    </div>
  );

  return (
    <main className="mt-2 mx-auto px-4 sm:mt-4 sm:px-6 md:mt-3 lg:mt-6 lg:px-10 xl:mt-10 max-h-[calc(90vh-50px)] overflow-y-auto">
      <div className="sm:text-center lg:text-left">
        <h1 className="text-4xl tracking-tight font-extrabold text-text/90 sm:text-5xl md:text-6xl">
          <div className="block text-primary w-full">{textData.Pages.LandingPage.components.MainHero.Title[langState]}</div>{' '}
          <span className={`block text-secondaryDark/80 xl:inline my-6`}>
            {textData.Pages.LandingPage.components.MainHero.Subtitle[langState]}
          </span>
        </h1>
        {pageOneHTML}
        {pageTwoHTML}
      </div>
      <div className="flex mt-5 sm:mt-8 sm:justify-center lg:justify-start flex-wrap">
        <div className="mt-3 ml-3 sm:mt-0 sm:ml-0">
          <Button 
            buttonText={isAuthenticated ? textData.Pages.LandingPage.components.MainHero.authedPrimaryAction[langState] : textData.Pages.LandingPage.components.MainHero.nonAuthedPrimaryAction[langState]} 
            actionHandler={() => navigate(isAuthenticated ? textData.Pages.LandingPage.components.MainHero.authedPrimaryAction.href : textData.Pages.LandingPage.components.MainHero.nonAuthedPrimaryAction.href)}
            buttonType='primary'
            size='large'    
          />
        </div>
        
        <div className="mt-3 ml-3 sm:mt-0">
          {
            !isAuthenticated
            ?
            <Button 
              buttonText={textData.Pages.LandingPage.components.MainHero.nonAuthedSecondaryAction[langState]} 
              actionHandler={() => navigate(textData.Pages.LandingPage.components.MainHero.nonAuthedSecondaryAction.href)}
              buttonType='secondary'
              size='large'
            />
            :
            <Button 
              buttonText={textData.Pages.LandingPage.components.MainHero.authedSecondaryAction[langState]}
              actionHandler={() => logout()}
              buttonType='secondary'
              size='large'
            />
          }
        </div>

        {/* <div className="mt-3 ml-3 sm:mt-0">
          <Button 
            buttonText={textData.Pages.LandingPage.components.MainHero.Contact[langState]} 
            actionHandler={() => navigate("/contact-us")}
            buttonType='secondary-inverse'
            size='large'
          />
        </div> */}
      </div>
      <Footer />
    </main>
  );
};

export default MainHero;
