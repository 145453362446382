import Form, { FormData } from '../Components/Form/Form';
import { AuthContext, AuthContextType, LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType } from '../utilities/customHooks';
import * as T from '../utilities/frontendTypes';
import { Navigate, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { loginFormDefinition } from '../utilities/formDefinitionTemplates';
import LoadingIndicator from '../Components/LoadingIndicator';
import Button, { ButtonProp } from '../Components/Button';
import textData from "../textData.json";
import { returnLangCheckedFormDef } from '../utilities/helperFunctions';

function Login() {
  const { langState } = useContext(LangContext) as LangContextType
  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;
  const {showToast} = (useContext(ToastContext) as ToastContextType);
  const navigate = useNavigate();
  const { isAuthenticated, userRole, login } = useContext(AuthContext) as AuthContextType; // [NOTE] This casting is intentional

  const handleLogin = async (updatedForm: FormData) => {
    const loginInfo: T.LoginInfo = {
      email: (updatedForm['email'] as string),
      password: updatedForm['password'] as string,
    }
    showLoading();
    const success = await login(loginInfo)
    hideLoading();
    if (success) {
      if (userRole === null) {
        return <LoadingIndicator />;
      }
      if (userRole && userRole === "tempAdmin") {
        navigate("/dashboard/admin-verification");
      }

      if (userRole && userRole === "tempExpert") {
        navigate("/dashboard/expert-verification");
      } 

      if (userRole && userRole === "tempProvider") {
        navigate("/dashboard/provider-verification");
      }
        navigate("/dashboard");
    } else {
      showToast({
        toastType: "failure",
        message: textData.Pages.Login.Invalid[langState],
      });
    };
  }

  if (isAuthenticated) {
    if (userRole === null) {
      return <LoadingIndicator />;
    }
    if (userRole === "tempAdmin") {
      return <Navigate to={'/dashboard/admin-verification'} />
    } else {
      return <Navigate to={'/dashboard'} />
    }
  }

  const primaryButtonProp: ButtonProp = {
    buttonText: textData.Pages.Login.Login[langState],
    actionHandler: handleLogin,
  }

  return (
    <div className='flex flex-col w-[500px] grow'>
      <h1 className='text-4xl font-bold mb-4 text-center'>{textData.Pages.Login.Title[langState]}</h1>
      <Form 
        formDefinition={returnLangCheckedFormDef(loginFormDefinition, langState)} 
        formData={null}
        primaryButtonProp={primaryButtonProp}
        buttonFullWidth={true}
      />
      <div className='w-full text-left mt-5'>
        <button className='underline text-primary'
          onClick={() => {navigate("/forgot-password")}}
      >
        {textData.Pages.Login.Forgot[langState]}
      </button>
      </div>
      <div className='w-full text-left mt-10 text-primary'>
        <span>{textData.Pages.Login.Register1[langState]} <a className="font-bold underline" href='/register'>{textData.Pages.Login.Register2[langState]}</a></span>
      </div>      
    </div>
  )
}

export default Login;