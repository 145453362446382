import { useAsync } from '../../utilities/customHooks';
import LoadingIndicator from '../LoadingIndicator';
import ErrorIndicator from '../ErrorIndicator';
import { getDownloadUrl } from '../../services/adminService';


const PdfDownloader = (
  {docKey} 
  : 
  {docKey: string}
  ) => {
    const getUrl = useAsync(() => getDownloadUrl(docKey), []);

    if (getUrl.status === "pending") {
      return <LoadingIndicator />;
    }
  
    if (getUrl.status === "rejected") {
      return <ErrorIndicator />;
    }
    const response = getUrl.value.url
  
  return (
    <div className='w-full mt-2'>
        <iframe title="pdfViewer" src={response} width="70%" height="500px" className='m-auto'></iframe>
    </div>
  );
};

export default PdfDownloader;