import { faFile, faUser } from '@fortawesome/free-regular-svg-icons';
import * as T from '../utilities/frontendTypes';
import { faBarsProgress, faEnvelope, faFileText, faListCheck, faPenToSquare, faProjectDiagram, faQuestion, faUserCheck, faUserClock, faUserGear, faUserTie, faUserTimes, faUsers } from '@fortawesome/free-solid-svg-icons';

/* 

  Side Menu Data

*/
const userProfile = {
  link: '/dashboard/profile',
  text: 'My Application',
  frText: 'Mon application',
  icon: faUser,
  inaccessibleTo: ['pending', 'rejected'] as T.MemberApplicationStatus[],
  tooltipContent: "Manage your EIP Profile. <br>Profile Locked pending Application Evaluation, if changed are required please reach out to support",
  frTooltipContent: 'Gérer votre profil ÉPI Temporairement verrouillé en raison d’une évaluation imminente de l’application.'
}

export const founderSideMenuData: T.MenuData[] = [
  userProfile,
  {
    link: '/dashboard/founder-services',
    text: 'Free Services and Events',
    frText: 'Services et événements gratuits',
    icon: faProjectDiagram,
    inaccessibleTo: ['unsubmitted'],
    tooltipContent: "View and apply to IP Services available in your region. <br>In order to unlock, please submit an ElevateIP Program Application.",
    frTooltipContent: 'Examiner et appliquer les services de PI offerts dans votre région.  Pour déverrouiller, veuillez soumettre une demande au programme ÉleverlaPI.',
    children: [
      {
        link: '/dashboard/enrolled-services',
        text: 'Enrolled Services',
        tooltipContent: "Manage your enrolled Services. <br>In order to unlock, please submit an ElevateIP Program Application.",
        icon: faBarsProgress,
        frTooltipContent: 'Gérer vos services inscrits.  Pour déverrouiller, veuillez soumettre une demande au programme ÉleverlaPI.',
        frText: 'Mes services',
      },
      // {
      //   link: '/dashboard/founder-applications',
      //   text: 'My Applications',
      //   tooltipContent: "Manage your Service Applications. <br>In order to unlock, please submit an ElevateIP Program Application.",
      //   icon: faListCheck,
      //   frText: 'Mes applications',
      //   frTooltipContent: 'Gérer vos applications de service  Pour déverrouiller, veuillez soumettre une demande au programme ÉleverlaPI.',

      // },
    ]
  },
  {
    link: '/dashboard/founder-sp-firms',
    text: 'IP Services Firms',
    icon: faUserTie,
    tooltipContent: "Information about the firms working with EIP to provide IP Services",
    frTooltipContent: 'Renseignements à propos des firmes collaborant avec ÉPI pour fournir des services de propriété intellectuelle',
    frText: 'Firmes de service de PI',
  }
];

export const tempAdminSideMenuData: T.MenuData[] = [
  {
    link: '/dashboard/admin-verification',
    text: 'Admin Verification',
    icon: faUserGear,
    // frTooltipContent: '',
  }
];

export const tempExpertSideMenuData: T.MenuData[] = [
  {
    link: '/dashboard/expert-verification',
    text: 'Expert Verification',
    icon: faUserGear,
    // frTooltipContent: '',
  }
];

export const tempProviderSideMenuData: T.MenuData[] = [
  {
    link: '/dashboard/provider-verification',
    text: 'Provider Verification',
    icon: faUserGear,
    // frTooltipContent: '',
  }
];

export const adminSideMenuData: T.MenuData[] = [
  {
    link: '/dashboard/profile',
    text: 'Profile',
    icon: faUser,
    tooltipContent: "Manage your EIP Profile.",
    // frTooltipContent: '',
    // frText: '',
  },
  {
    link: '/dashboard/memberships',
    text: 'All Companies',
    icon: faUsers,
    // frText: '',
    children: [
      {
        link: '/dashboard/membership-applications',
        text: 'Company Applications',
        icon: faFile,
        // frText: '',
      },
    ]
  },
  {
    link: '/dashboard/sp-firms',
    text: 'Service Provider Firms',
    icon: faUserTie,
    // frText: '',
  },
];

export const providerSideMenuData: T.MenuData[] = [
  userProfile,
  {
    link: '/dashboard/sow-requests',
    text: 'SOW Requests',
    icon: faUserGear,
  }
];

export const superAdminSideMenuData: T.MenuData[] = [
  ...adminSideMenuData,
  // {
  //   link: '/dashboard/services',
  //   text: 'Services',
  //   icon: faProjectDiagram,
  //   // frText: '',
  //   children: [
  //     {
  //       link: '/dashboard/my-services',
  //       text: 'My Services',
  //       // frText: '',
  //       icon: faBarsProgress,
  //     },
  //     // {
  //     //   link: '/dashboard/my-service-applications',
  //     //   text: 'My Service Applications',
  //     //   icon: faListCheck,
  //     //   // frText: '',
  //     // },
  //   ]
  // },
  {
    link: '/dashboard/new-user',
    text: 'New User',
    icon: faUserGear,
    // frText: '',
  },
];

export const serviceProviderSideMenuData: T.MenuData[] = [
  {
    link: '/dashboard/create-sow',
    text: 'Create Sow',
    icon: faUserGear,
  }
]

export const ipExpertSideMenuData: T.MenuData[] = [
  {
    link: '/dashboard/expert-assesments',
    text: 'Awaiting Assement',
    icon: faUserClock,
    // frTooltipContent: '',
  },
  {
    link: '/dashboard/in-progress-assesments',
    text: 'In Progress',
    icon: faUserGear,
    // frTooltipContent: '',
  },
  {
    link: '/dashboard/submitted-assesments',
    text: 'Submitted',
    icon: faUserCheck,
    // frTooltipContent: '',
  }
];