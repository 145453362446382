import { useContext, useState } from "react";
import { assignTier, growthCoachByapplication, updateApplicationStatus } from "../../services/adminService";
import Button from "../Button";
import { LangContext, LangContextType, LoadingContext, LoadingContextType, ToastContext, ToastContextType } from "../../utilities/customHooks";
import { useNavigate } from "react-router-dom";
import Modal from "../Modal";
import DropdownSelect from "../Input/DropdownSelect";
import TextArea from "../Input/TextArea";
import Tooltip from "../Tooltip";
import textData from "../../textData.json";
import { ApplicationStatus } from "../../utilities/frontendTypes";
import BooleanSelect from "../Form/BooleanSelect";
import { faL } from "@fortawesome/free-solid-svg-icons";

function ApproveReject (
  { 
    applicationId,
    applicationType,
    navigateUrl,
    // applicantName, // currently not in use, for better ux
    // programName,
    requireTierAssignment,
  }
  : 
  {
    applicationId: string,
    applicationType: "membership" | "program",
    navigateUrl: string,
    applicantName?: string,
    programName?: string,
    requireTierAssignment?: boolean
  }
) {
  const { langState, setLang } = useContext(LangContext) as LangContextType
  const navigate = useNavigate();

  const showToast = (useContext(ToastContext) as ToastContextType).showToast;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [selectedTier, setSelectedTier] = useState("1");
  const [reasonNote, setReasonNote] = useState("");
  const [reapply, setReapply] = useState(false)
  console.log(reapply)
  const handleCheckboxChange = () => {
    setReapply(!reapply);
  };

  const {showLoading, hideLoading} = useContext(LoadingContext) as LoadingContextType;

  const handleConfirmation = async () => {
    if (isApproving && requireTierAssignment) {

      showLoading();
      const response = await assignTier(applicationId, parseInt(selectedTier));
      hideLoading();
      if (!response.success) {
        showToast({
          toastType: "failure",
          message: textData.Components.Features.ApproveReject.AssigmentFailure[langState],
          autoDisappearTimeInSeconds: 4,
        });
        return;
      }
    }
    const approvedOrRejectedString = isApproving ? textData.Components.Features.ApproveReject.Approve[langState] as ApplicationStatus : textData.Components.Features.ApproveReject.Reject[langState] as ApplicationStatus;
    showLoading();
    const res = await updateApplicationStatus(applicationId, { reasonNote, status: isApproving ? "approved" : "rejected", reapply }, applicationType);
    hideLoading();
    if (res.success) {
      showToast({
        toastType: "success",
        message: `${textData.Components.Features.ApproveReject.SuccessMessage[langState]} ${approvedOrRejectedString}`,
      });
      navigate(navigateUrl);
    } else {
      showToast({
        toastType: "failure",
        message: `${textData.Components.Features.ApproveReject.FailureMessage[langState]} ${approvedOrRejectedString}`,
      });
    }
  }

  const handleGrowthCoach = async () => {
    showLoading();
    const res = await growthCoachByapplication(applicationId)
    hideLoading();
    if (res.success) {
      showToast({
        toastType: "success",
        message: "Status Updated",
      });
      navigate(navigateUrl);

    } else {
      showToast({
        toastType: "failure",
        message: "Unable to update Statuses, lease try again.  If Error persists please conteact support",
      });
    }
  }

  return (
    <div className="flex mb-5 w-full">
      <div className="flex justify-between w-full">
      <Button         
        buttonType="primary"
        buttonText={textData.Components.Features.ApproveReject.Approve[langState]}
        actionHandler={ () => {
          setIsModalOpen(true);
          setIsApproving(true);
        }}
      />
      <Button         
        buttonType="secondary"
        buttonText="Growth Coach Review"
        actionHandler={handleGrowthCoach}
        style="ml-5 mr-5"

      />
      <Button 
        buttonType="danger"
        buttonText={textData.Components.Features.ApproveReject.Reject[langState]}
        actionHandler={ () => {
          setIsModalOpen(true);
          setIsApproving(false);
        }}
        // style="ml-5"
      />
      </div>
      <Tooltip content={textData.Components.Features.ApproveReject.ConfirmationTooltip[langState]} position={"top-left"} />
      <Modal 
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        modalHeader={`${isApproving ? textData.Components.Features.ApproveReject.ApplicationApprovalConfirmtaion[langState] : textData.Components.Features.ApproveReject.ApplicationRejectionConfirmtaion[langState]}`}
        contentLabel="Approve or reject confirmation" // [TODO]
        primaryButton={{ buttonText: `${textData.Components.Features.ApproveReject.Confirm[langState]}`, buttonCallback: () => handleConfirmation() }}
        modalHTML={
          <div className="flex flex-col text-text">        
            {
              requireTierAssignment === true && isApproving &&
              (
                <>
                  <p className="font-semibold mb-2">{textData.Components.Features.ApproveReject.AssignTier[langState]}</p>
                  <DropdownSelect
                    name={"tier-selection"}
                    allowCustomValue={false}
                    selections={[
                      {
                        value: "1",
                        text: "1"
                      },
                      {
                        value: "2",
                        text: "2"
                      },
                      {
                        value: "3",
                        text: "3"
                      }
                    ]}
                    value={selectedTier} 
                    setValue={v => setSelectedTier(v as string)}
                  />
                </>
              )
            }
            {!isApproving &&
            <div className="flex flex-col">
            <div className="flex mt-5">
              <p className="font-semibold mb-2">{textData.Components.Features.ApproveReject.ProvideNote[langState]}</p>
              <Tooltip content={textData.Components.Features.ApproveReject.VisibleTooltip[langState]} position={"top-left"} />
            </div>
            <TextArea 
              name={"approve-reject-reason-note"} 
              value={reasonNote} 
              setValue={v => setReasonNote(v as string)}
            />
            <label>
                <input
                  className="mt-6 mr-2"
                  type="checkbox"
                  checked={reapply ? true : false}
                  onChange={handleCheckboxChange}
                />
                Allow Reapplication?
            </label>
            </div>
              }
            <p className="font-semibold my-5">{`${isApproving ? textData.Components.Features.ApproveReject.ApplicationApprovalSure[langState] : textData.Components.Features.ApproveReject.ApplicationRejectionSure[langState]}`}</p>
          </div>
        }        
      />
    </div>
  )
}

export default ApproveReject;