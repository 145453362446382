import { useContext } from 'react'
import { AuthContext, AuthContextType } from '../../utilities/customHooks';
import AdminDashboard from './AdminDashboard';
import TempAdminDashboard from './TempAdminDashboard';
import FounderDashboard from './FounderDashboard';
import TempExpertDashboard from './TempExpertDashboard';
import TempProviderDashboard from './TempProviderDashboard';
import IpExpertDashboard from './IpExpertDashboard';

function Console() {
  const { userRole } = useContext(AuthContext) as AuthContextType; // [NOTE] This casting is intentional

  const getDashboard = () => {
    switch (userRole) {
      case "superAdmin":
      case "partnerAdmin":
        return <AdminDashboard />;
      case "tempAdmin":
        return <TempAdminDashboard />;
      case "tempExpert":
        return <TempExpertDashboard />;
      case "tempProvider":
        return <TempProviderDashboard />;
      case "serviceProvider":
        return <></>; // [TODO] Create dashboard page for service provider
      case "ipExpert":
          return <IpExpertDashboard />; // [TODO] Create dashboard page for service provider
      case "startupFounder":
        return <FounderDashboard />;
      default:
        throw new Error('Unknown user role');
    }
  }
  return (
    getDashboard()
  )
}

export default Console