import { useContext, useEffect, useState } from "react";
import Button, { ButtonProp } from "../Button";
import DatePicker from './DatePicker';
import InputField from './InputField';
import DropdownSingleSelect from "./DropdownSingleSelect";
import CheckboxMultiSelect from "./CheckboxMultiSelect";
import Checkbox from "./CheckboxWithText";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { checkInputErrorByKey, isFormInvalid } from "../../utilities/helperFunctions";
import RangeInputFields, { RangeData } from "./RangeInputFields";
import TextArea from "./TextArea";
import { ConditionalRenderContext, ConditionalRenderContextType, LangContext, LangContextType } from "../../utilities/customHooks";
import AddressField, { Address } from "./AddressFields";
import parseStringToHTML from "html-react-parser";
import Tooltip from "../Tooltip";
import BooleanSelect from "./BooleanSelect";
import CustomList from "./CustomList";
import { ToolTipPosition } from "../../utilities/frontendTypes";
import FormDefinition from "./FormDefinitionEditor";
import { HTMLParser } from "./HTMLParser";
import textData from "../../textData.json";

export type FieldValue = null | string | number | Date | [] | string[] | boolean | RangeData | Address; // [TODO] double check the empty array and secure it with a type if time

export type FormData = {
  [fieldKey: string]: FieldValue;
}

export type InputType =  "text" | "undefined" | "duration" | "number" | "dropdownSelect" | "customDropdownSelect" | "date" | "multiSelect" | "customMultiSelect" | "checkbox" 
                      | "range" | "textarea" | "address" | "boolean" | "customList" | "formDefinition" | "htmlParser";
export type InputFieldType = "email" | "uniqueEmail" | "password" | "tel" | "text" | "url" | "number" | "boolean";

type ValidatorRule = {
  value: any, // [TODO] Not sure how to resolve this type error - B
  message: string,
}

export type FieldValidator = {
  required?: boolean | ValidatorRule,
  minLength?: number | ValidatorRule,
  maxLength?: number | ValidatorRule,
  min?: number | ValidatorRule,
  max?: number | ValidatorRule,
  fieldType?: InputFieldType,
  returnValueType?: string, // "boolean"
  pattern?: RegExp | ValidatorRule,
}

export type selectionData = {
  value: string,
  text: string,
  frText?: string
}

export type ConditionalRenderDefinition = {
  fieldKey: string,
  renderWhenTrue?: boolean, 
};

export type FieldDefinition = {
  label: string,
  frLabel?: string,
  inputType: InputType,
  tooltip?: {
    content: string,
    position?: ToolTipPosition
  },
  frTooltip?: {
    content: string,
    position?: ToolTipPosition
  },
  placeholder?: string,
  defaultValue?: FieldValue,
  validator?: FieldValidator,
  additionalData?: {
    selectionData: selectionData[],
  };
  isViewOnly?: boolean,
  conditionalRenderDefinition?: ConditionalRenderDefinition,
  future?: boolean,
  futureDateMin?: Date,
  futureDateMax?: Date,
}

export type FormDefinition = {
  [fieldKey: string]: FieldDefinition;
}

export default function Form (
  {
    formDefinition,
    formData,
    primaryButtonProp,
    secondaryButtonProp,
    readOnly,
    disabled,
    isViewOnly,
    isFormChangedCallback,
    buttonFullWidth
  }
  : 
  {
    formDefinition: FormDefinition,
    formData: FormData | null,
    primaryButtonProp?: ButtonProp, // optional to indicate hide secondary button 
    secondaryButtonProp?: ButtonProp,
    readOnly?: boolean,
    disabled?: boolean,
    isViewOnly?: boolean,
    isFormChangedCallback?: (boolean: boolean) => void,
    buttonFullWidth?: boolean
  }
) {
  const { langState } = useContext(LangContext) as LangContextType
  
  // Create initial form, if no formData passed in, then generate default value based on inputType
  const emptyForm = {...formData};
  for (const key in formDefinition) {
    const { inputType, defaultValue } = formDefinition[key];
    emptyForm[key] = getDefaultStateValue(inputType, defaultValue);
  }

  useEffect(() => {
    if (formData) {
      const initialForm = {...emptyForm}
      for (const key in formDefinition) {
        initialForm[key] = formData[key];
      }
      setState(initialForm);
    }
    // eslint-disable-next-line 
  }, [formData])

  // Create initial form state
  const [state, setState] = useState(emptyForm);

  // Gain access to conditional render state
  const conditionalRenderContext = useContext(ConditionalRenderContext) as ConditionalRenderContextType;

  // State value to prompt user with confirmation when exiting without saving
  const [isAnyFieldChanged, setIsAnyFieldChanged] = useState(false);

  // User react-hook-form's form functionalities and replace some of the default form features
  const methods = useForm();
  const onSubmit = methods.handleSubmit(_data => { // by pass default form data and pass form state to parent
    if (!primaryButtonProp || !primaryButtonProp.actionHandler) {
      throw new Error("primary button is undefined");
    }
    primaryButtonProp.actionHandler(state);
  })

  // Update form state when there is a value change
  const updateState = (fieldKey: string, value: FieldValue) => {
    setState((prevState) => ({ ...prevState, [fieldKey]: value }));
    setIsAnyFieldChanged(true);
    if (isFormChangedCallback) isFormChangedCallback (true);
    if (Object.keys(conditionalRenderContext.conditionalRenderState).includes(fieldKey)) {
      
      conditionalRenderContext.updateConditionalRenderState(fieldKey, value as boolean);

    }
  };

  const tryCancel = () => {
    if (secondaryButtonProp === undefined) {
      throw new Error("secondary button is undefined");
    }
    const handleSecondaryButtonClick = secondaryButtonProp.actionHandler();
    if (isAnyFieldChanged) {
      if(window.confirm(textData.Components.Form.Form.UnsavedChanges[langState])) {
        handleSecondaryButtonClick;
      }
    } else {
      handleSecondaryButtonClick;
    }
  }

  return (
    // FormProvider allowing access the form's context inside our Input UI components
    <FormProvider {...methods}> 
      <form 
        onSubmit={e => e.preventDefault()}
        noValidate
        className="flex flex-col w-full items-center"
      >
        {/* Map through form definition and populate each field with initial state value from formData  */}
        <InputFields 
          formDefinition={formDefinition} 
          state={state} 
          updateState={updateState}
          readOnly={readOnly}
          disabled={disabled}
          isViewOnly={isViewOnly}
        />
        {/* Button controls */}
        {primaryButtonProp &&
          buttonFullWidth ?
          <div className='w-full mt-2'>
            <Button 
            actionHandler={onSubmit}
            buttonText={primaryButtonProp.buttonText}
            buttonType={primaryButtonProp.buttonType}
            disabled={disabled}
            size={"large"}
            />
          </div>
          :
        <div className="flex justify-evenly mt-5 w-full">
          {
            primaryButtonProp && !readOnly
            &&
            <Button 
              actionHandler={onSubmit}
              buttonText={primaryButtonProp.buttonText}
              buttonType={primaryButtonProp.buttonType}
              disabled={disabled}
            />
          }
          {
            secondaryButtonProp && !readOnly
            &&
            <Button 
              actionHandler={tryCancel}
              buttonText={secondaryButtonProp.buttonText}
              buttonType={secondaryButtonProp.buttonType}
            />
          }
        </div>
        }
      </form>
    </FormProvider>
  )
}

function InputFields (
  {
    formDefinition,
    state,
    updateState,
    readOnly,
    disabled,
    isViewOnly,
  }
  :
  {
    formDefinition: FormDefinition,
    state: {[fieldKey: string]: FieldValue},
    updateState: (name: string, value: FieldValue) => void,
    readOnly?: boolean,
    disabled?: boolean,
    isViewOnly?: boolean
  }
) {
  const { langState } = useContext(LangContext) as LangContextType

  const { formState: { errors } } = useFormContext();
  const { watch } = useFormContext();

  const { conditionalRenderState } = useContext(ConditionalRenderContext) as ConditionalRenderContextType;

  const fieldsHTML = Object.keys(formDefinition).map( key => {
    const fieldDefinition = formDefinition[key];
    const { label, placeholder, additionalData, validator, tooltip, conditionalRenderDefinition, future, futureDateMin, futureDateMax } = fieldDefinition;

    const fieldInputType = fieldDefinition.inputType;
    const overrideViewOnly = fieldDefinition.isViewOnly;

    const input = () => {
      switch(fieldInputType) {
        case "text":
        case "number":
          if (validator === undefined) {
            throw new Error("Validator cannot be undefined for input fields");
          }

          // [TODO] find a more modular way to handle cases like this
          const validate = key === "confirmPassword" ? (value: string) => {
            if (watch('password') !== value) {
              return textData.Components.Form.Form.PasswordError[langState];
            }
          } : undefined;

          const value = state[key];

          return (
            <InputField
              name={key}
              value={value === null ? "" : value as string | number}
              setValue={(v) => updateState(key, v)}
              disabled={disabled || isViewOnly || overrideViewOnly}
              readOnly={readOnly || isViewOnly || overrideViewOnly}
              placeholder={placeholder}
              validator={validator}
              validate={validate}
            />
          )
        case "date":
          return (
            <DatePicker
              scrollableYearDropdown={true}
              name={key}
              value={state[key] as Date} 
              setValue={(v) => updateState(key, v)}
              readOnly={readOnly} 
              disabled={disabled || isViewOnly || overrideViewOnly}
              placeholder={placeholder}
              validator={validator}
              future={future}
              futureDateMin={futureDateMin}
              futureDateMax={futureDateMax}
            />
          )
        case "dropdownSelect":
        case "customDropdownSelect":
        
          if (additionalData === undefined) {
            throw new Error("No selection data found");
          }

          return (         
            <DropdownSingleSelect
              name={key}
              allowCustomValue={fieldInputType === "customDropdownSelect"}
              selections={additionalData.selectionData}
              value={state[key] as string} 
              setValue={(v) => updateState(key, v)}
              readOnly={readOnly}  
              disabled={disabled || isViewOnly || overrideViewOnly}
              validator={validator}
            />
          )
        case "checkbox":
          return (
            <Checkbox
              text={label}
              name={key} 
              value={state[key] as boolean} 
              setValue={(key, v) => {
                updateState(key, v)
              }}
              readOnly={readOnly}  
              disabled={disabled || isViewOnly || overrideViewOnly}        
            />
          )
        case "multiSelect":
        case "customMultiSelect":
          if (additionalData === undefined) {
            throw new Error("No selection data found for multi select");
          }
          return (
            <CheckboxMultiSelect
              name={key}
              value={state[key] as string[]}
              setValue={(v) => updateState(key, v)}
              isViewOnly={isViewOnly}
              readOnly={readOnly}
              disabled={disabled || isViewOnly || overrideViewOnly}
              multiSelectData={additionalData.selectionData}
              validator={validator}
              enableCustomSelections={fieldInputType === "customMultiSelect"}
            />
          )
        case "range":
          return (
            <RangeInputFields 
              name={key} 
              value={state[key] as RangeData} // [TODO] could refactor this and make it easier to/automatically import/save down the road 
              setValue={(v) => updateState(key, v)}
              readOnly={readOnly}  
              disabled={disabled || isViewOnly || overrideViewOnly}
              validator={validator}       
            />
          )
        case "textarea":
          return (
            <TextArea 
              name={key} 
              value={state[key] as string} 
              setValue={(v) => updateState(key, v)} 
              placeholder={placeholder}
              validator={validator}
              disabled={disabled || isViewOnly || overrideViewOnly}
              readOnly={readOnly}
            />
          )
        case "address":
          return (
            <AddressField 
              name={key} 
              value={state[key] as Address} 
              setValue={(v) => updateState(key, v)}
              validator={validator}
              disabled={disabled || isViewOnly || overrideViewOnly}
              readOnly={readOnly}
            />
          )
        case "boolean":
          return (
            <BooleanSelect 
              name={key}
              value={state[key] as boolean}
              setValue={(v) => updateState(key, v)}
              isViewOnly={isViewOnly}
              disabled={disabled || isViewOnly || overrideViewOnly}
              readOnly={readOnly}
            />
          )
        case "customList":
          return (
            <CustomList 
              name={key}
              value={state[key] as string[]}
              setValue={(v) => updateState(key, v)}
              isViewOnly={isViewOnly}
              disabled={disabled || isViewOnly || overrideViewOnly}
              readOnly={readOnly}      
            />
          )
        case "formDefinition":
          return (
            <FormDefinition 
              name={key}
              value={state[key] as string}
              setValue={(v) => updateState(key, v)}
              disabled={disabled || isViewOnly || overrideViewOnly}
              readOnly={readOnly}           
            />
          )
        case "htmlParser": {
          return (
            <HTMLParser 
              name={key}
              value={state[key] as string}
              setValue={(v) => updateState(key, v)}     
            />
          )
        }
        // [Note]: temp type for under-construction item 
        case "undefined":
          return <div key={key}></div>;
        default:
          throw new Error("Unknown formData input type.");
      };
    };
    const inputError = checkInputErrorByKey(errors, key);
    const isInvalid = isFormInvalid(inputError);

    let isRenderingComponent = true;

    if (conditionalRenderDefinition && Object.keys(conditionalRenderState).includes(conditionalRenderDefinition.fieldKey)) {
      const conditionalRenderFieldKey = conditionalRenderDefinition.fieldKey;

      isRenderingComponent = conditionalRenderState[conditionalRenderFieldKey] === (conditionalRenderDefinition.renderWhenTrue !== false);
    }

    return (
      isRenderingComponent
      &&
      <div className='flex flex-col mb-5 w-full' key={key}>
        <div className="flex mb-2 text-text">
          {
            fieldInputType !== "checkbox" 
            && 
            <div className="flex">
              <label 
                className="font-semibold [&>a]:underline [&>a]:text-text/70 [&>a]:hover:text-text" 
                htmlFor={key}
              >
                {parseStringToHTML(label)}
              </label>
              {
                tooltip && <Tooltip content={tooltip.content}  position={tooltip.position} />
              }
            </div>
          }
          <AnimatePresence mode="wait" initial={false}>
            {isInvalid && (
              <InputError
                message={inputError.error?.message as string}
                key={key}
              />
            )}
          </AnimatePresence>
        </div>
        {input()}
      </div>
    )
  })

  return (
    <>{fieldsHTML}</>
  );
}

export const InputError = ({ message }: {message: string}) => {
  return (
    <motion.p
      className="flex items-center gap-1 ml-2 px-2 font-semibold bg-red-100 rounded-md text-sm text-danger "
      {...framer_error}
    >
      <FontAwesomeIcon icon={faCircleExclamation}  />
      {message}
    </motion.p>
  )
}

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
}

export const getDefaultStateValue = (inputType: InputType, defaultValue?: FieldValue) => {
  let initialValue: FieldValue;
  switch (inputType) {
    case "number":
    case "undefined":
      initialValue = 0;
      break;
    case "multiSelect":
    case "customMultiSelect":
    case "customList":
      initialValue = [];
      break;
    case "checkbox":
      initialValue = false;
      break;
    case "dropdownSelect":
    case "customDropdownSelect":
    case "text":
    case "textarea":
    case "formDefinition":
    case "htmlParser":
      initialValue = "";
      break;
    case "date":
    case "address":
    case "range":
    case "boolean":
      initialValue = null;
      break;
    default:
      throw new Error(`No default value found for input type: ${inputType}`);
  }
  return defaultValue === undefined ? initialValue: defaultValue;
}